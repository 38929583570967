//Test
// export const url=`http://38.242.135.217:6008/trackingsupervisor/`;
//  export const url=`https://38.242.135.217:6008/trackingsupervisor/`;
// export const url=`https://printlok.jespersoft.com:6008/trackingsupervisor/`;

// Test Current
// export const url=`https://test-printlok.jesperapps.com:6002/trackingsupervisor/`;

    // export const url=`https://test-printlok.jesperapps.com:6002/trackingsupervisor/`;
  // export const url=`http://192.168.0.183:6002/trackingsupervisor/`


// http://localhost:6002/trackingsupervisor/userGeofence/date/2024-11-21/2024-11-21

//production
export const url=`https://printlok.jesperapps.com:6008/trackingsupervisor/`;

//Local
// export const url=`http://192.168.0.199:6002/trackingsupervisor/`;
// export const url=`http://192.168.0.192:6002/trackingsupervisor/`;
//  export const url=`http://192.168.2.55:6008/trackingsupervisor/`;
//  export const url=`http://192.168.2.36:6008/trackingsupervisor/`;
// export const url=`http://localhost:6008/trackingsupervisor/`;
// export const url=`https://192.168.2.36:6008/trackingsupervisor/`;
// export const url=`http://192.168.0.195:6008/trackingsupervisor/`;
  export const globalTimeOut = 20000;
 
