<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport data-color="red"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <!--[ngStyle]="{'background': background1()} " -->
    <div class="img">
      <mat-nav-list>
        <mat-toolbar class="header">
          <!-- <img src="../../../assets/jepericon.png" class="brand" alt=""> -->
          <!--  <Span>Jesper</Span> -->
          <span>{{organizationName}}</span>
        </mat-toolbar>
        <hr style="border-top: 1px solid rgba(255, 250, 250, 0.45);">
        <a mat-list-item [routerLink]="['/loginoutReports']" [routerLinkActive]='["active"]'
        matTooltip="Login Logout Report" *ngIf="enableAtt===true">
        <span class="material-icons ">login</span> <span>Login and Logout Report</span>
       </a>
        <a mat-list-item [routerLink]="['/user']" [routerLinkActive]='["active"]'
        matTooltip="Manage User" *ngIf="userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER'">
         <span class="material-icons ">group</span> <span>Manage User</span>
       </a>

       <!-- <a mat-list-item [routerLink]="['/previous-records']" [routerLinkActive]='["active"]'
       matTooltip="Dashboard" *ngIf="userTypeName != 'SUPER ADMIN'">
        <span class="material-icons ">history</span> <span>Previous Record Report
        </span>
      </a> -->

      <!-- <a mat-list-item [routerLink]="['/overall-login-report']" [routerLinkActive]='["active"]'
      matTooltip="Dashboard" *ngIf="userTypeName != 'SUPER ADMIN'">
       <span class="material-icons ">person</span> <span>Overall User Login/logout Reports

       </span>
     </a> -->

     <a mat-list-item [routerLink]="['/client-location']" [routerLinkActive]='["active"]'
     matTooltip="Client" *ngIf="userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER'">
      <!-- <span class="material-icons">person_outline</span>  -->
      <span class="material-icons">apartment</span>
      <span>Client
      </span>
    </a>

    <a mat-list-item [routerLink]="['/assign-client']" [routerLinkActive]='["active"]'
     matTooltip="Assign Client Location" *ngIf="userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER'">
     <span class="material-icons ">edit_location_alt</span> <span>Assign Client Location
     </span>
    </a>
    <a mat-list-item [routerLink]="['client-assign']" [routerLinkActive]='["active"]' matTooltip="Client Assign"
        *ngIf="userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER'">
       <!-- <span class="material-icons">assignment</span>  -->
       <span class="material-icons">assignment_ind</span>
       <span>Assign Client Employees </span>
    </a>
    <a mat-list-item [routerLink]="['/shift']" [routerLinkActive]='["active"]'
     matTooltip="Shift" *ngIf="(userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER')">
     <span class="material-icons">schedule</span> <span>Shift</span>
    </a>
  <a mat-list-item [routerLink]="['OT-assign-list']" [routerLinkActive]='["active"]' matTooltip="Manage OT" *ngIf="(userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER')">
    <span class="material-icons">work_history</span>
    <!-- <span class="material-icons">share_eta</span> -->
    <span>Manage OT</span>
  </a>
  <a mat-list-item [routerLink]="['manage-geofence']" [routerLinkActive]='["active"]' matTooltip="Manage Geofence"  *ngIf="(userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER')&&track==true">
    <!-- <span class="material-icons ">add_location_alt</span> -->
    <span class="material-icons">person_pin_circle</span> 
    <span>Manage Geofence</span>
  </a>
  <a mat-list-item [routerLink]="['assign-geofence']" [routerLinkActive]='["active"]' matTooltip="Assign Geofence"  *ngIf="(userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER')&&track==true">
    <!-- <span class="material-icons">assignment_ind</span> -->
    <span class="material-icons">fence</span>
    <span>Assign Geofence</span>
  </a>
    
  <!-- <a mat-list-item [routerLink]="['track-list']" [routerLinkActive]='["active"]' matTooltip="Dashboard"
        *ngIf="userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER'">
          <span class="material-icons ">share_location</span> <span>View Track Report</span>
        </a> -->
      <a mat-list-item [routerLink]="['track-user']" [routerLinkActive]='["active"]' matTooltip="View Track Report"  *ngIf="(userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER')&&track==true">
          <span class="material-icons ">share_location</span> <span>View Track Report</span>
      </a>
    <a mat-list-item [routerLink]="['/attendance-report']" [routerLinkActive]='["active"]'
     matTooltip="Attendence Reports" *ngIf="enableAtt===true&&enableAttType==='Shift'">
      <span class="material-icons ">assignment</span> <span>Attendance Report
      </span>
    </a>
    <a mat-list-item [routerLink]="['/geoattendance-report']" [routerLinkActive]='["active"]'
     matTooltip="Geofence Attendence Reports" *ngIf="enableAtt===true&&enableAttType==='Non Shift'">
      <span class="material-icons ">assignment</span> <span>Attendance Report
      </span>
    </a>

  
   


   
    

       <!-- <a mat-list-item [routerLink]="['location-list']" [routerLinkActive]='["active"]' matTooltip="Dashboard" *ngIf="userTypeName != 'SUPER ADMIN'">
        <span class="material-icons ">add_location_alt</span> <span>Manage Location</span>
      </a> -->
        
        <!-- <a mat-list-item [routerLink]="['location']" [routerLinkActive]='["active"]' matTooltip="Dashboard" *ngIf="userTypeName != 'SUPER ADMIN'">
          <span class="material-icons ">add_location_alt</span> <span>Assign Location</span>
        </a> -->
       
        
        
        <!-- <a mat-list-item [routerLink]="['live-track-list']" [routerLinkActive]='["active"]' matTooltip="Dashboard" *ngIf="userTypeName != 'SUPER ADMIN'">
          <span class="material-icons ">location_searching</span> <span>Live Tracking</span>
        </a> -->
      
        <!-- <a mat-list-item [routerLink]="['dont-track']" [routerLinkActive]='["active"]' matTooltip="User Notification" *ngIf="userTypeName != 'SUPER ADMIN'">
          <span class="material-icons ">location_disabled</span> <span>Manage Don't Track</span>
        </a> -->

        <!-- <a mat-list-item [routerLink]="['org-list']" [routerLinkActive]='["active"]' matTooltip="Dashboard"
        *ngIf="userTypeName === 'PRIMARY USER' || userTypeName === 'BUDDY USER'">
          <span class="material-icons ">analytics</span> <span> Manage Organization</span>
        </a> -->
        
        
       
        <!-- <a mat-list-item [routerLink]="['user-induvar']" [routerLinkActive]='["active"]' matTooltip="User Notification" 
         *ngIf="userTypeName != 'PRIMARY USER' && userTypeName != 'BUDDY USER'">
          <span class="material-icons ">account_circle</span> <span>Manage Individual User</span>
        </a> -->
      
      
       <hr style="border: 2px solid rgba(255, 250, 250, 0.45);">
       <!-- <p> -->
        <!-- <a mat-list-item  matTooltip="User Notification"    *ngIf="userTypeName != 'PRIMARY USER' && userTypeName != 'BUDDY USER'">
          <span class="material-icons ">settings</span> 
          <span  >SETTING</span>
          <span class="material-icons" style="margin-left:85px;"
           data-bs-toggle="collapse" href="#collapseExample"
            role="button" aria-expanded="false" aria-controls="collapseExample" (click)="expand()">
            {{isExpand ? 'expand_less':'expand_more'}}
          </span>
        </a> -->
      <!-- </p> -->
      <div class="collapse" id="collapseExample">
        <a mat-list-item [routerLink]="['free-trial']"  [routerLinkActive]='["active"]' matTooltip="User Notification">
          <span class="material-icons ">published_with_changes</span> <span  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Manage Free Trial</span>
        </a>
      </div>
  
      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar>
      <mat-toolbar-row>
        <span class="example-spacer">
          <button type="button" mat-mini-fab color="warn" aria-label="Toggle sidenav" class="primary" mat-icon-button
            (click)="drawer.toggle()">
            <mat-icon aria-label="Side nav toggle icon" color="warn">menu</mat-icon>
          </button>
        </span>
        <!-- brand -->
        <span>
          <h4 style="position: absolute;
              left: 64px;
              right: 0px;
              line-height: 0;">PRINTLOK</h4>
        </span>
        <div class="btn-group ">
        <button mat-mini-fab color="warn"   class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" mat-mini-fab color="warn" aria-label="Toggle sidenav" class="primary" mat-icon-button
         >
          <mat-icon matBadge="{{notificationdata.length}}" matBadgeColor="warn" >notifications</mat-icon>
        </button>
       
        <ul class="dropdown-menu"> 
            <div class="col-md-12"><h5>Notification</h5></div>
          <hr>
        <div *ngFor="let notifi of  notificationdata" class="row"  >
          <div class="col-md-2" id="profile1"><img src="assets/profile.png"></div>
          <div class="col-md-8" id="profile2"  matTooltip=""><h6>{{notifi.notification.title}}<br>{{notifi.notification.body}}</h6></div>
          <div class="col-md-2" id="profile3"><button mat-stroked-button  color="warn" style="width: 100%;" mat-button   (click) = "showtrackdetail(notifi.data.trackId)"
            mat-dialog-close class="material-icons">view</button ></div>
        </div>
        <hr>
        </ul>
       
      </div>
         
    
  



<!-- Split dropstart button -->

      


        <div class="side_setting" style="width: fit-content;"> 
          <h5 class="profile-name">{{localstorage?.name}}</h5>
          <h6 class="user-type">{{ userTypeName }}</h6>
          <mat-menu #beforeMenu="matMenu" xPosition="before">
              <!-- <a disabled mat-menu-item (click)="toggleProfile()">
                <span class="material-icons v">account_box</span> 
                My Profile
              </a> -->
              <!-- <a disabled mat-menu-item [routerLink]="['settings']">
                <span class="material-icons v">settings</span> Account Settings</a> -->
              <a mat-menu-item (click)="logout()">
                <span class="material-icons v">settings_power</span> Logout</a>
          </mat-menu>
        </div>
        <button mat-mini-fab color="warn" [matMenuTriggerFor]="beforeMenu" class="primary">
          <mat-icon class="example-icon" color="warn">more_vert</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- settings -->
    <!-- <div class="settings">
      <span class="material-icons" (click)="background1()">{{theme ? 'toggle_off' : 'toggle_on
        '}}</span>
    </div> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- *ngIf="isHandset$ | async"  -->
