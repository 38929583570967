import { Component, OnInit,ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { LiveTrackComponent } from '../Components/live-track/live-track.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../Components/user/user.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import { AuthService } from '../Authenticaton/auth.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-assigngeofence-attendence',
  templateUrl: './assigngeofence-attendence.component.html',
  styleUrls: ['./assigngeofence-attendence.component.scss']
})
export class AssigngeofenceAttendenceComponent implements OnInit {
  userlist: any = [];
  isLoading: boolean = false;
  fromDate:any;
  toDate:any;
  sMinDate:any;
  nodata: boolean=false;
  presentList: any=[];
  totalpresentList: any=[];
  otList: any=[];
  empotList:any=[];
  absentList: any=[];
  leaveList: any=[];
  empabsentList: any=[];
  empleaveList: any=[];
  attendanceList: any[] = [];
  maxDate: Date;
  emaxDate:Date;
  startDate: boolean=false;
  endDate: boolean=false;
  minDate: Date;
  userId: any;
  empList: any=[];
  OverAllEmpList: any;
  greater: boolean=false;
  endDate1: Date;
  orgId: any;
  monthMin: Date;
  monthMinDate:any;
  dataForExcel: any = [];
  headerForExcel: any =[];
  exportHeader: any=[];
  exportData: any=[];
  employeeData: any[] = [];
  normalDates:any[]=[];
  attendancecounts:any[]=[];
  tableDatas: any[] = [];
  isOrg: boolean = false;
  geofenceattendenceform:FormGroup
  constructor(public dialog: MatDialog, private gs: UserService,private datePipe:DatePipe,
    private Gs: GlobalserviceService, private auth: AuthService,private fb:FormBuilder, 
    private excelservice :  ExcelService) { }

  ngOnInit(): void {
    this.maxDate = new Date();  
     console.log(this.maxDate);
    this.geofenceattendenceform = this.fb.group({
      fromDate: ['', [Validators.required]],
      todate: ['', [Validators.required]]  
    });
     let userdata = this.auth.getLocalStorageData();
     console.log(userdata);
     this.userId=userdata.userId;
     console.log("UserId",this.userId);
  }
    sdate(sdate)
    {
      let startDate = this.datePipe.transform(sdate.target.value, 'YYYY-MM-dd');
      console.log(startDate);
      this.fromDate=startDate;
      // console.log(this.geofenceattendenceform.controls);
      // const fromDateControl = this.geofenceattendenceform.get('fromDate');
      // const toDateControl = this.geofenceattendenceform.get('todate');
      // console.log(fromDateControl?.value);
      // console.log(toDateControl?.value);
      // console.log(this.endDate1)
      // console.log(this.fromDate)
      // console.log(this.toDate)
      if (this.fromDate === null && this.toDate === null) {
        console.log("Both fromDate and toDate are null");
        this.startDate = true;
        this.endDate = true;
      }
      else if (this.fromDate === null && this.toDate !== null) {
        console.log("fromDate is null, but toDate has a value");
        this.startDate = true;
        this.endDate = false;
      }
      else if (this.fromDate !== null && this.toDate === null) {
        console.log("fromDate has a value, but toDate is null");
        this.startDate = false;
        this.endDate = true;
      }
      else {
        console.log("Both fromDate and toDate have values");
        this.startDate = false;
        this.endDate = false;
        const fromDate = new Date(this.fromDate); 
        const toDate = new Date(this.toDate);
        if (fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth()) 
        {
          console.log("Dates are within the same month");
          if (this.fromDate&&this.toDate) 
            {
            console.log(this.fromDate+" "+this.toDate);
            if(this.fromDate==this.toDate)
            {
                console.log("equal valid.");
                this.greater=false;
            }
            else if(this.fromDate<=this.toDate)
            {
              console.log("From Date is less valid.");
              this.greater=false;
            }
            else if(this.fromDate>=this.toDate)
            {
                console.log("From Date Greater invaild.");
                this.greater=true;
            }
          }
        }
        else 
        {
          console.log("Dates are not within the same month");
          this.greater=true;
        }
      }
      const fromDate = new Date(this.fromDate); 
  if (!isNaN(fromDate.getTime())) {  // Only proceed if it's a valid date
    const year = fromDate.getFullYear();
    const month = fromDate.getMonth();

    // Get the last day of the current month
    const endDate = new Date(year, month + 1, 0);  // Last day of the month

    console.log(`Start Date: ${fromDate}`);
    console.log(`End of Month: ${endDate}`);
    this.endDate1=endDate;
    this.minDate=fromDate;
    let pDate = new Date();  
    if(endDate>pDate)
    {
      this.emaxDate=pDate
    }
    else
    {
      this.emaxDate=endDate
    }
    }
  }
  edate(edate)
    {
      let endDate = this.datePipe.transform(edate.target.value, 'YYYY-MM-dd');
      console.log(endDate);
      this.greater=false;
      // this.sMinDate=endDate;
      this.toDate=endDate;
      if (this.fromDate === null && this.toDate === null) {
        console.log("Both fromDate and toDate are null");
        this.startDate = true;
        this.endDate = true;
      }
      else if (this.fromDate === null && this.toDate !== null) {
        console.log("fromDate is null, but toDate has a value");
        this.startDate = true;
        this.endDate = false;
      }
      else if (this.fromDate !== null && this.toDate === null) {
        console.log("fromDate has a value, but toDate is null");
        this.startDate = false;
        this.endDate = true;
      }
      else {
        console.log("Both fromDate and toDate have values");
        this.startDate = false;
        this.endDate = false;
      }
    }
    search()
    {
      console.log("Search");
      this.attendanceList=[];
      this.fromDate = this.datePipe.transform(this.geofenceattendenceform.value.fromDate, 'yyyy-MM-dd')
      console.log("fromdate" + this.fromDate);
      this.toDate =this.datePipe.transform(this.geofenceattendenceform.value.todate, 'yyyy-MM-dd')
      console.log("rodate" + this.toDate);
      console.log(this.geofenceattendenceform);
      if (this.fromDate === null && this.toDate === null) {
        console.log("Both fromDate and toDate are null");
        this.startDate = true;
        this.endDate = true;
      }
      else if (this.fromDate === null && this.toDate !== null) {
        console.log("fromDate is null, but toDate has a value");
        this.startDate = true;
        this.endDate = false;
      }
      else if (this.fromDate !== null && this.toDate === null) {
        console.log("fromDate has a value, but toDate is null");
        this.startDate = false;
        this.endDate = true;
      }
      else {
        console.log("Both fromDate and toDate have values");
        this.startDate = false;
        this.endDate = false;
      }
      if (this.geofenceattendenceform.invalid) {
        console.log("Invaild");
        return
      }
    this.employeeData=[];
    this.totalpresentList=[];
    this.empotList=[];
    this.empabsentList=[];
    this.empleaveList=[];
    this.otList=[];
    let userdata = this.auth.getLocalStorageData();
    console.log("userdata",userdata);
    this.orgId=userdata.organization.organizationId;
    console.log("orgId::",this.orgId);
    // this.Gs.attendanceReports(this.fromDate, this.toDate).subscribe((res: any) => {
      if (userdata.userType[0].userTypeName == "BUDDY USER"){
        console.log("buddy user logged here!!");
      this.Gs.assignGeofenceattendanceReportsUser(this.fromDate, this.toDate,this.userId).subscribe((res: any) => {
      this.employeeData = res;
      this.nodata = false;
      console.log(res)
      if(res.statusCode==204)
      {
        console.log("No Data");
        this.nodata = true;
      }
      else
      {
      console.log(this.employeeData.length);
      this.normalDates=[];
      this.attendanceList=[];
      this.tableDatas=[];
      res.forEach((headers: any) => {
        headers.attendance_status.attendance.forEach((head) => {
          if (head.date && !this.normalDates.some(item => item.date === head.date)) {
            var nDdate = {
              date: head.date,
              status: 'Day' 
            };
            this.normalDates.push(nDdate);
          }
        });
        //  console.log("normalDates",this.normalDates);
      })
      this.attendanceList = [];
      this.employeeData.forEach(element => {
        this.empList=[];
            this.normalDates.forEach((headers:any)=>
              {
                  element.attendance_status.attendance.forEach((empworkStatus:any)=>
                    {
                        if(empworkStatus.date==headers.date)
                        {
                          if(empworkStatus.date)
                          {
                            var empStatus=
                            {
                              date:empworkStatus.date,
                              status:empworkStatus.status
                            }
                            this.empList.push(empStatus);
                          }
                        }
                    })
              })
        // console.log("empList",this.empList);
        this.tableDatas = [];
        var totalCounts = {
          present:element.attendance_status.totalCounts.present,
          absent: element.attendance_status.totalCounts.absent,
          Leave: element.attendance_status.totalCounts.Leave,
        }
        var emp = {
          empid: element.employee_number,
          empname: element.employee_name ? element.employee_name : '-',
          position: element.designation_name,
          attendance: this.tableDatas,
          totalcounts:totalCounts,
          total_worked_hours:element.total_worked_hours,
          empData:this.empList
        }
        element.attendance_status.attendance.filter((head) => {
          if (head.status   && !this.tableDatas.includes(head.date)) {
            var attendance = {
              date: head.date,
              status: head.status,
            }
            this.tableDatas.push(attendance);
          }
          // console.log('tableDatas', this.tableDatas);
        })
        this.attendanceList.push(emp);
      });
      console.log('attendanceList', this.attendanceList);
      res.forEach(element => {
        element.attendance_status.attendance.forEach(date=> {
          this.presentList=[];
          this.absentList=[];
          this.leaveList=[];
          res.forEach(emp => {
            emp.attendance_status.attendance.forEach(empDate => {
            if (date.date == empDate.date && empDate.status =='P') {
                this.presentList.push(empDate);  
            }
            if (date.date == empDate.date && empDate.status =='A') {
                this.absentList.push(empDate); 
            }
            if (date.date == empDate.date && empDate.status =='L') {
                this.leaveList.push(empDate);  
            }
          });
        });
        this.normalDates.forEach((headers:any)=>
        {
            // presentList
            if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date))
              {
                // console.log("Day",date.date);
                if(headers.status=="Day"&&headers.date==date.date)
                {
                var count = {
                  date: date.date,
                  countData: this.presentList.length ? this.presentList.length : '0',
                  // status:date.status
                };
                this.totalpresentList.push(count);
              }
              }
              // Absent List
              if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date)) {
                if(headers.status=="Day"&&headers.date==date.date)
                  {
                    var count = {
                      date: date.date,
                      countData: this.absentList.length ? this.absentList.length : '0'
                    };
                    
                    this.empabsentList.push(count);
                  }
              }
              // LeaveList
              if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date))
                {
                  if(headers.status=="Day"&&headers.date==date.date)
                    {
                      var count = {
                        date: date.date,
                        countData: this.leaveList.length ? this.leaveList.length : '0'
                      };
                      this.empleaveList.push(count);
                    }  
                }
        })
      });
    })
        console.log("PList",this.totalpresentList);
        console.log("AList",this.empabsentList);
        console.log("LList",this.empleaveList);
      }
    },
    (error) => {
      // this.loader= false;
      console.log("error");
      if(error.statusCode == 204){
        this.nodata = true;
        console.log("404")
      }
    }
  );
  }
  else if(userdata.userType[0].userTypeName == "PRIMARY USER"){
  console.log("primary user logged attendence!!");
  this.Gs.assignGeofenceattendanceReportsPrimary(this.fromDate, this.toDate,this.orgId).subscribe((res: any) => {
    this.employeeData = res;
    this.nodata = false;
    console.log(res)
    if(res.statusCode==204)
    {
      console.log("No Data");
      this.nodata = true;
    }
    else
    {
    console.log(this.employeeData.length);
    this.normalDates=[];
    this.attendanceList=[];
    this.tableDatas=[];
    res.forEach((headers: any) => {
      this.attendancecounts.push(headers.attendance_status.attendance);
      headers.attendance_status.attendance.forEach((head) => {
        if (head.date && !this.normalDates.some(item => item.date === head.date)) {
          var nDdate = {
            date: head.date,
            status: 'Day' 
          };
          this.normalDates.push(nDdate);
        }
      });
      //  console.log("normalDates",this.normalDates);
    })
    this.attendanceList = [];
    this.employeeData.forEach(element => {
      this.empList=[];
          this.normalDates.forEach((headers:any)=>
            {
                element.attendance_status.attendance.forEach((empworkStatus:any)=>
                  {
                      if(empworkStatus.date==headers.date)
                      {
                        if(empworkStatus.date)
                        {
                          var empStatus=
                          {
                            date:empworkStatus.date,
                            status:empworkStatus.status
                          }
                          this.empList.push(empStatus);
                        }
                      }
                  })
            })
        // console.log("empList",this.empList);
        this.tableDatas = [];
        var totalCounts = {
        present:element.attendance_status.totalCounts.present,
        absent: element.attendance_status.totalCounts.absent,
        Leave: element.attendance_status.totalCounts.Leave,
      }
      var emp = {
        empid: element.employee_number,
        empname: element.employee_name ? element.employee_name : '-',
        position: element.designation_name,
        attendance: this.tableDatas,
        totalcounts:totalCounts,
        total_worked_hours:element.total_worked_hours,
        empData:this.empList
      }
      element.attendance_status.attendance.filter((head) => {
        if (head.status   && !this.tableDatas.includes(head.date)) {
          var attendance = {
            date: head.date,
            status: head.status,
          }
          this.tableDatas.push(attendance);
        }
        // console.log('tableDatas', this.tableDatas);
      })
      this.attendanceList.push(emp);
    });
    console.log('attendanceList', this.attendanceList);
    res.forEach(element => {
      element.attendance_status.attendance.forEach(date=> {
        this.presentList=[];
        this.absentList=[];
        this.leaveList=[];
        res.forEach(emp => {
          emp.attendance_status.attendance.forEach(empDate => {
          if (date.date == empDate.date && empDate.status =='P') {
              this.presentList.push(empDate);  
          }
          if (date.date == empDate.date && empDate.status =='A') {
              this.absentList.push(empDate);  
          }
          if (date.date == empDate.date && empDate.status =='L') {
              this.leaveList.push(empDate); 
          }
        });
      });
      this.normalDates.forEach((headers:any)=>
      {
          // presentList
          if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date))
            {
              // console.log("Day",date.date);
              if(headers.status=="Day"&&headers.date==date.date)
              {
              var count = {
                date: date.date,
                countData: this.presentList.length ? this.presentList.length : '0',
                // status:date.status
              };
              this.totalpresentList.push(count);
            }
            }
            // Absent List
            if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date)) {
              if(headers.status=="Day"&&headers.date==date.date)
                {
                  var count = {
                    date: date.date,
                    countData: this.absentList.length ? this.absentList.length : '0'
                  };
                  
                  this.empabsentList.push(count);
                }
            }
            // LeaveList
            if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date))
              {
                if(headers.status=="Day"&&headers.date==date.date)
                  {
                    var count = {
                      date: date.date,
                      countData: this.leaveList.length ? this.leaveList.length : '0'
                    };
                    this.empleaveList.push(count);
                  }  
              }
            })
        });
      })
      console.log("PList",this.totalpresentList);
      console.log("AList",this.empabsentList);
      console.log("LList",this.empleaveList);
    }
  },
  (error) => {
    // this.loader= false;
    console.log("error");
    if(error.statusCode == 204){
      this.nodata = true;
      console.log("404")
    }
  }
  );
  }
  else{
  console.log("secondary user logged here!!");
  this.Gs.assignGeofenceattendanceReportsUser(this.fromDate, this.toDate,this.userId).subscribe((res: any) => {
    this.employeeData = res;
    this.nodata = false;
    console.log(res)
    if(res.statusCode==204)
    {
      console.log("No Data");
      this.nodata = true;
    }
    else
    {
    console.log(this.employeeData.length);
    this.normalDates=[];
    this.attendanceList=[];
    this.tableDatas=[];
    res.forEach((headers: any) => {
      this.attendancecounts.push(headers.attendance_status.attendance);
      headers.attendance_status.attendance.forEach((head) => {
        if (head.date && !this.normalDates.some(item => item.date === head.date)) {
          var nDdate = {
            date: head.date,
            status: 'Day'
          };
          this.normalDates.push(nDdate);
        }
      });
      //  console.log("normalDates",this.normalDates);
    })
    this.attendanceList = [];
    this.employeeData.forEach(element => {
      this.empList=[];
          this.normalDates.forEach((headers:any)=>
            {
                element.attendance_status.attendance.forEach((empworkStatus:any)=>
                  {
                      if(empworkStatus.date==headers.date)
                      {
                        if(empworkStatus.date)
                        {
                          var empStatus=
                          {
                            date:empworkStatus.date,
                            status:empworkStatus.status
                          }
                          this.empList.push(empStatus);
                        }
                      }
                  })
            })
      //  console.log("empList",this.empList);
      this.tableDatas = [];
      var totalCounts = {
        present:element.attendance_status.totalCounts.present,
        absent: element.attendance_status.totalCounts.absent,
        Leave: element.attendance_status.totalCounts.Leave,
      }
      var emp = {
        empid: element.employee_number,
        empname: element.employee_name ? element.employee_name : '-',
        position: element.designation_name,
        attendance: this.tableDatas,
        totalcounts:totalCounts,
        total_worked_hours:element.total_worked_hours,
        empData:this.empList
      }
      element.attendance_status.attendance.filter((head) => {
        if (head.status   && !this.tableDatas.includes(head.date)) {
          var attendance = {
            date: head.date,
            status: head.status,
          }
          this.tableDatas.push(attendance);
        }
        // console.log('tableDatas', this.tableDatas);
      })
      this.attendanceList.push(emp);
    });
    console.log('attendanceList', this.attendanceList);
    res.forEach(element => {
      element.attendance_status.attendance.forEach(date=> {
        this.presentList=[];
        this.absentList=[];
        this.leaveList=[];
        this.otList=[];
        res.forEach(emp => {
          emp.attendance_status.attendance.forEach(empDate => {
          if (date.date == empDate.date && empDate.status =='P') {
              this.presentList.push(empDate);  
          }
          if (date.date == empDate.date && empDate.status =='A') {
              this.absentList.push(empDate);  
          }
          if (date.date == empDate.date && empDate.status =='L') {
              this.leaveList.push(empDate);  
          }
        });
      });
      this.normalDates.forEach((headers:any)=>
      {
          // presentList
          if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date))
            {
              if(headers.status=="Day"&&headers.date==date.date)
              {
              var count = {
                date: date.date,
                countData: this.presentList.length ? this.presentList.length : '0',
                // status:date.status
              };
              this.totalpresentList.push(count);
            }
            }
            // Absent List
            if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date)) {
              if(headers.status=="Day"&&headers.date==date.date)
                {
                  var count = {
                    date: date.date,
                    countData: this.absentList.length ? this.absentList.length : '0'
                  };
                  
                  this.empabsentList.push(count);
                }
            }
            // LeaveList
            if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date))
              {
                if(headers.status=="Day"&&headers.date==date.date)
                  {
                    var count = {
                      date: date.date,
                      countData: this.leaveList.length ? this.leaveList.length : '0'
                    };
                    this.empleaveList.push(count);
                  }  
              }
      })
    });
  })
  console.log("PList",this.totalpresentList);
  console.log("AList",this.empabsentList);
  console.log("LList",this.empleaveList);
    }
  },
  (error) => {
    // this.loader= false;
    console.log("error");
    if(error.statusCode == 204){
      this.nodata = true;
      console.log("404")
    }
  }
  );
  }
    }
  clear()
  {
     console.log("Clear");
     this.attendanceList=[];
     this.startDate=false;
     this.endDate=false;
     this.nodata=false;
     this.greater=false;
     this.geofenceattendenceform.reset();
  }
  ExportGeofenceAttendance()
  {
  console.log("Export");
  this.headerForExcel.length = 0;
  console.log(this.attendanceList);
  let summaryCounts = {
    Present: {},
    Absent: {},
    Leave: {}
  };
  this.attendanceList.forEach((element, index) => {
  let rowData: any = {
    SNO: index + 1,
    EmployeeNo: element.empid,
    EmployeeName: element.empname,
    Position: element.position,
  };
  // console.log(this.normalDates);
  this.exportHeader=[];
  this.normalDates.forEach((header) => {
    const alreadyExists = this.exportHeader.some(
      (existingHeader) => existingHeader.date === header.date && existingHeader.status === header.status
    );
    if (!alreadyExists &&header.date && header.status) {
      this.exportHeader.push(header);
    }
    });
    // console.log(this.exportHeader);
    // console.log(element.empData);
    this.exportHeader.forEach(empHeader => {
      // if(empHeader.status=="Day")
        // {
          // console.log("empHeader.status:"+empHeader.status);
          element.empData.forEach(empData1 => {
            // console.log("empData2",empData1);
            if (empHeader.date === empData1.date&&!rowData.hasOwnProperty(empData1.date)) {
              console.log("Day Date:", empHeader.date, "Day Status:", empHeader.status);
              rowData[empData1.date] = empData1.status;
            }
          });
        // }
   });
  // console.log("rowData",rowData);
  rowData.Present = element?.totalcounts?.present || 0;
  // rowData.OT= element?.totalcounts?.otcount || 0;
  rowData.Absent = element?.totalcounts?.absent || 0;
  rowData.TotalHoursWorked = element?.total_worked_hours || 0;
  this.headerForExcel.push(rowData);
   });
  console.log(this.totalpresentList);
  this.totalpresentList.forEach((element1) => {
    const date = element1.date;
    // console.log(typeof element1.countData);
    if (typeof element1.countData === "number") {
      const countData = element1.countData.toString();
      // console.log("number",countData); 
      if (!element1.status) {
          console.log(`Date: ${date}, CountData (Normal): ${countData}`);
          summaryCounts.Present[date] = countData;
      }
    }
    if(typeof element1.countData === "string")
    {
      const countData = element1.countData;
      // console.log("String",countData); 
      // console.log(typeof countData); 
      if (!element1.status) {
          console.log(`Date: ${date}, CountData (Normal): ${countData}`);
          summaryCounts.Present[date] = countData;
      }
    }
});

this.empabsentList.forEach((element2) => {
  const date = element2.date;
  // console.log(typeof element2.countData);
  if (typeof element2.countData === "number") {
    const countData = element2.countData.toString();
    // console.log("number",countData); 
    if (!element2.status) {
        console.log(`Date: ${date}, CountData (Normal): ${countData}`);
        summaryCounts.Absent[date] = countData;
    }
  }
  if(typeof element2.countData === "string")
  {
    const countData = element2.countData;
    // console.log("String",countData); 
    // console.log(typeof countData); 
    if (!element2.status) {
        console.log(`Date: ${date}, CountData (Normal): ${countData}`);
        summaryCounts.Absent[date] = countData;
    }
  }
});

this.empleaveList.forEach((element3) => {
  const date = element3.date;
  // console.log(typeof element3.countData);
  if (typeof element3.countData === "number") {
    const countData = element3.countData.toString();
    // console.log("number",countData); 
    if (!element3.status) {
        console.log(`Date: ${date}, CountData (Normal): ${countData}`);
        summaryCounts.Leave[date] = countData;
    }
  }
  if(typeof element3.countData === "string")
  {
    const countData = element3.countData;
    // console.log("String",countData); 
    // console.log(typeof countData); 
    if (!element3.status) {
        console.log(`Date: ${date}, CountData (Normal): ${countData}`);
        summaryCounts.Leave[date] = countData;
    }
  }
});

//   const date = element4.date;
//   console.log(typeof element4.otcountData);
//   if (typeof element4.otcountData === "number") {
//     const countData = element4.otcountData.toString();
//     console.log("number",countData); 
//     if (!element4.status) {
//         console.log(`Date: ${date}, CountData (Normal): ${countData}`);
//         summaryCounts.OT[date] = countData;
//     }
//     if (element4.status =="OT") {
//         const Otdate = " "+element4.date;
//         const otCountData = countData; 
//         console.log(`OT: ${date}, CountData (OT): ${otCountData}`);
//         summaryCounts.OT[Otdate] = otCountData;
//     }
//   }
//   if(typeof element4.otcountData === "string")
//   {
//     const countData = element4.otcountData;
//     console.log("String",countData); 
//     console.log(typeof countData); 
//     if (!element4.status) {
//         console.log(`Date: ${date}, CountData (Normal): ${countData}`);
//         summaryCounts.OT[date] = countData;
//     }
//     if (element4.status =="OT") {
//         const Otdate = " "+element4.date;
//         const otCountData = countData; 
//         console.log(`OT: ${date}, CountData (OT): ${otCountData}`);
//         summaryCounts.OT[Otdate] = otCountData;
//     }
//   }
// });
//   const date = element2.date;
//   const countData = element2.countData;
//   if (!element2.status) {
//       console.log(`Date: ${date}, CountData (Normal): ${countData}`);
//       summaryCounts.Absent[date] = countData;
//   }
//   if (element2.status === "OT") {
//       const Otdate = " "+element2.date;
//       const otCountData = countData; 
//       console.log(`Date: ${date}, CountData (OT): ${otCountData}`);
//       summaryCounts.Absent[Otdate] = otCountData;
//   }
// });

// this.empleaveList.forEach((element3) => {
//   const date = element3.date;
//   const countData = element3.countData;
//   if (!element3.status) {
//       console.log(`Date: ${date}, CountData (Normal): ${countData}`);
//       summaryCounts.Leave[date] = countData;
//   }
//   if (element3.status === "OT") {
//       const Otdate = " "+element3.date;
//       const otCountData = countData; 
//       console.log(`Date: ${date}, CountData (OT): ${otCountData}`);
//       summaryCounts.Leave[Otdate] = otCountData;
//   }
// });

// this.empotList.forEach((element4) => {
//   const date = element4.date;
//   const countData = element4.otcountData;
//   if (!element4.status) {
//       console.log(`Date: ${date}, CountData (Normal): ${countData}`);
//       summaryCounts.OT[date] = countData;
//   }
//   if (element4.status === "OT") {
//       const Otdate = " "+element4.date;
//       const otCountData = countData; 
//       console.log(`Date: ${date}, CountData (OT): ${otCountData}`);
//       summaryCounts.OT[Otdate] = otCountData;
//   }
// });
 let presentRow = { SNO: '', EmployeeNo: '', EmployeeName: '', Position: 'Present', ...summaryCounts.Present };
 let absentRow = { SNO: '', EmployeeNo: '', EmployeeName: '', Position: 'Absent', ...summaryCounts.Absent };
 let leaveRow = { SNO: '', EmployeeNo: '', EmployeeName: '', Position: 'Leave', ...summaryCounts.Leave };
//  let otRow = { SNO: '', EmployeeNo: '', EmployeeName: '', Position: 'OT', ...summaryCounts.OT };

 this.exportHeader.forEach((header) => {
  const date = header.date;
  presentRow[date] = summaryCounts.Present[date];
  absentRow[date] = summaryCounts.Absent[date];
  leaveRow[date] = summaryCounts.Leave[date];
  // otRow[date] = summaryCounts.OT[date];
 });
 this.headerForExcel.push(presentRow, absentRow, leaveRow);
  console.log("summaryCounts",summaryCounts);
  console.log("headerForExcel", this.headerForExcel);
  this.excelservice.exportAsExcelFile(this.headerForExcel, "Non-Shift Attendance Report");
  }
}
