<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading-wrapper">
    <div class="loading">
      <app-loading></app-loading>
      <br />
      <h1>Please Wait Loading...</h1>
    </div>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <div class="container">
  <div class="mat-elevation-z8">
     <div class="--header_action p-2" >
          <div class="row">
                    <h2 class="pl-5 pt-2">Manage Geofence</h2>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="float-left">
                        <button mat-flat-button (click)="openCreateGeofenceDialog()"  [routerLink]="['/geofence']">
                            <span class="material-icons">
                            add
                            </span>
                            <span>Create Geofence</span>
                        </button>
                </div>
                <div class="float-right">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-icon matPrefix>search</mat-icon>
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Search GeofenaceName" #input>
                        </mat-form-field>
               </div>
               </div>
           </div>    
        </div>
    </div>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource">
              <!-- <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef  class="col-md-2 col-sm-2"> <b>Sl.No</b> </th>
                  <td mat-cell *matCellDef="let element; let i =index"> {{i+1}} </td> 
              </ng-container> -->
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"><b>Sl.No</b></th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ currentPageOffset + i + 1 }}
                </td>
            </ng-container>
              <ng-container matColumnDef="geofencename">
                  <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>GEOFENCE NAME</b> </th>
                  <td mat-cell *matCellDef="let element"> {{element.geofenaceName}} </td>
              </ng-container>

              <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>CLIENT NAME</b> </th>
                <!-- <td mat-cell *matCellDef="let element"> {{element.client.clientName}} </td> -->
                      <td mat-cell *matCellDef="let element"> 
                {{ element.client.clientName || '-' }} 
              </td>      
                <!-- <td>Clicnt name here</td> -->
            </ng-container>
            <ng-container matColumnDef="clientLocation">
              <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>CLIENT LOCATION/ADDRESS</b> </th>
              <td mat-cell *matCellDef="let element"> 
                <button *ngIf="element?.client?.locationDetails.length !=0; else nodata" type="button" class="btn b1" style="font-size: 12px;"
                (click)="viewclientaddress(element.client)">
                  View Location
                </button>
                <ng-template #nodata>--</ng-template>
              </td>               <!-- <td>client location</td> -->
          </ng-container>
              <!-- <ng-container matColumnDef="geofencepoints">
                <th mat-header-cell *matHeaderCellDef> <b>GEOFENCE POINTS</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.geofenacePoints}} </td>
              </ng-container> -->
              <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>STATUS</b> </th>
                  <!-- <td mat-cell *matCellDef="let element"> {{element.geofeanceStatus.statusName}} </td> -->
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.geofeanceStatus.statusName === 'InActive' ? 'red' : 'black'}">  {{element.geofeanceStatus.statusName}} </td>
              </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
                  <td mat-cell *matCellDef="let element"  class="col-md-3 col-sm-3">
                    <button mat-icon-button aria-label="Example icon-button with a menu" [matMenuTriggerFor]="beforeMenu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <div class="side_setting" class="">
                      <mat-menu #beforeMenu="matMenu" xPosition="after">
                        <!-- <a  mat-menu-item  (click)="editLocation(element)" > <span class="material-icons v"
                          style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span>Edit 
                          Geofence</a> -->
                          <!-- <button mat-menu-item (click)="editLocation(element)">Edit</button> -->
                          <button mat-menu-item *ngIf="element.geofeanceStatus.statusId==1" (click)="updateStatus(element.geofenaceId,2)">InActive</button>
                          <button mat-menu-item *ngIf="element.geofeanceStatus.statusId==2" (click)="updateStatus(element.geofenaceId,1)">Active</button>
                      </mat-menu>

                    </div>
                  </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
            <tr class="mat-row" *matNoDataRow>
                <td class="pl-3 pr-3" colspan="6" align="center">
                    <div class="alert alert-secondary" style="height: 60px;">
                      <h4 class="pt-2">No Manage Geofence List Found</h4>
                    </div>
                </td>
            </tr>
        </table>
       </div>
       <div class="p-3" *ngIf="nodata">
          <div class="no-data card text-center">
              <div class="card-body">No Data</div> 
          </div>
        </div> 
        <div class="row mt-5">
            <mat-paginator class="" (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
        </div>
  </div>        
</div>
</ng-template>
  
  