import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray,AbstractControl, ValidationErrors} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-edit-organziation',
  templateUrl: './edit-organziation.component.html',
  styleUrls: ['./edit-organziation.component.scss']
})
export class EditOrganziationComponent implements OnInit {
  id: any;
  updateForm!:FormGroup;
  matcher = new MyErrorStateMatcher();
  hide = true;
  freetriallist : any = [];
  loading = false;
  selectedValue: any;
  attType: any;
  trackType: any;
  startTime: any;
  endTime: any;
  intervalTime: any;
  photoCap: any;
  orgData: any = {};
  updateUser: any;
  constructor(private fb: FormBuilder , private gs : GlobalserviceService , private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log("Edit orgId:"+this.id);
    this.updateForm = this.fb.group({
      organizationName: ['', Validators.required],
      // contactEmail: ['', [Validators.required ,Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      contactEmail: ['', [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      contactPhoneNumber: ['', Validators.required],     
      name: ['', Validators.required],
      // email: ['',[ Validators.required , Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]], 
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      phoneNumber: ['', Validators.required],   
          // password : ['', Validators.required] , 
      // password: [
      //       '', 
      //       [
      //         Validators.required,
      //         Validators.minLength(8),  
      //       Validators.maxLength(12),
      //         Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,12}$')
      //       ]
      //     ],
          // freetrial :  this.fb.group({
            // freetrialId : ['', Validators.required] , 
          // }),
          attendence:['',Validators.required],
          attendenceType:['',Validators.required],
          trackType:['',Validators.required],
          startTime:['',Validators.required],
          endTime:['',Validators.required],
          // intervalTime:['',Validators.required],
          intervalTime: ['', [Validators.required, this.minTimeValidator('00:15')]],
          photoCapture:['',Validators.required]
    });

    this.gs.get_freetrial().subscribe((a: any) => {
      this.freetriallist = a;
     },
     (error)=>
       {
         console.log("Error",error);
         if(error.error.errorCode==204)
           {
             console.log("No data");
            //  this.isLoading =false;
           }
       })

     this.gs.get_organziationUser(this.id).subscribe((a: any) => {
      // this.orgData= a;
      console.log(a);
      this.updateUser=a;
      console.log(this.updateUser);
      this.selectedValue=a.attendanceType;
      this.trackType=a.track;
      this.updateForm.patchValue({
        organizationName:a.organizationName,
        contactEmail:a.contactEmail,
        contactPhoneNumber:a.contactPhoneNumber,     
        name:a.user[0].name,
        email:a.user[0].email,
        phoneNumber:a.user[0].phoneNumber,   
        password:a.user[0].phoneNumber,
        // freetrialId :a.organizationFreeTrial.freeTrial.freeTrialId, 
        // attendence:a.attendance,
        // attendenceType:a.attendanceType,
        // trackType:a.track,
        // startTime:a.fromTime,
        // endTime:a.toTime,
        // intervalTime:a.timeInterval,
        // photoCapture:a.attendanceImage
      })
      if (a.attendance) {
        this.updateForm.patchValue({
          attendence: a.attendance,
          attendenceType: a.attendanceType,
          photoCapture: a.attendanceImage,
        });
      } else {
        this.updateForm.patchValue({
          attendence: a.attendance,
        });
     }
     if (a.track) {
      this.updateForm.patchValue({
        trackType:a.track,
        startTime:a.fromTime,
        endTime:a.toTime,
        intervalTime:a.timeInterval,
      });
    } else {
      this.updateForm.patchValue({
        trackType:a.track,
      });
   }
     (error)=>
      {
        console.log("Error",error);
        if(error.error.errorCode==204)
          {
            console.log("No data");
           //  this.isLoading =false;
          }
      }
  })
     console.log(this.updateForm);
  }
  
  get f() {
    return this.updateForm.controls;
  }
  minTimeValidator(minTime: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // Skip validation if value is empty
      }
      const [minHours, minMinutes] = minTime.split(':').map(Number);
      const [inputHours, inputMinutes] = control.value.split(':').map(Number);

      const minTotalMinutes = minHours * 60 + minMinutes;
      const inputTotalMinutes = inputHours * 60 + inputMinutes;

      return inputTotalMinutes < minTotalMinutes
        ? { minTime: { requiredTime: minTime } }
        : null;
    };
  }
  updateOrg()
  {
    console.log("Update Org",this.updateForm.value);
    let x = this.updateForm.value;
    console.log(this.updateUser.user[0].userId);
    let updateuserdata = {
      userId:this.updateUser.user[0].userId,
      name:x.name,
      email:x.email,
      phoneNumber:x.phoneNumber,
      // password: x.password
    }	;
    let final_updatedata: any = {
        organizationId:this.id,
        organizationName:x.organizationName,
        contactEmail:x.contactEmail,
        contactPhoneNumber: x.contactPhoneNumber,
        // fromTime:x.contactPhoneNumber,
        // toTime:"11:30,
        // timeInterval:"30:00",
        // attendance:x.attendence,
        // track:x.trackType,
      // attendanceType:"Shift",
      //  attendanceImage:x.photoCapture,
        user:updateuserdata,
        // freeTrial: {
        //     freeTrialId: x.freetrialId
        // }
    };
    // if (x.attendence===true) {
    //   final_updatedata.attendance = x.attendence;
    //   final_updatedata.attendanceType = x.attendenceType;
    // } else {
    //   final_updatedata.attendance = x.attendence;
    // }
    
    // if (x.trackType===true) {
    //   final_updatedata.track = x.trackType;
    //   final_updatedata.fromTime = x.startTime;
    //   final_updatedata.toTime = x.endTime;
    //   final_updatedata.timeInterval = x.intervalTime;
    // } else {
    //   final_updatedata.track = x.trackType;
    // }
    if (x.attendence===true) {
      console.log("True");
      this.updateForm.get('attendenceType')?.setValidators([Validators.required]);
      this.updateForm.get('attendenceType')?.updateValueAndValidity();
      this.updateForm.get('photoCapture')?.setValidators([Validators.required]);
      this.updateForm.get('photoCapture')?.updateValueAndValidity();
      final_updatedata.attendance = x.attendence;
      final_updatedata.attendanceType = x.attendenceType;
      final_updatedata.attendanceImage=x.photoCapture
    } else if(x.attendence===false) {
      console.log("false");
      this.updateForm.get('attendenceType')?.clearValidators();
      this.updateForm.get('attendenceType')?.updateValueAndValidity();
      this.updateForm.get('photoCapture')?.clearValidators();
      this.updateForm.get('photoCapture')?.updateValueAndValidity();
      final_updatedata.attendance = x.attendence;
    }
    if (x.trackType===true) {
      console.log("TTrue");
      this.updateForm.get('startTime')?.setValidators([Validators.required]);
      this.updateForm.get('endTime')?.setValidators([Validators.required]);
      this.updateForm.get('intervalTime')?.setValidators([Validators.required]);

      // Update validators after setting
      this.updateForm.get('startTime')?.updateValueAndValidity();
      this.updateForm.get('endTime')?.updateValueAndValidity();
      this.updateForm.get('intervalTime')?.updateValueAndValidity();
      final_updatedata.track = x.trackType;
      final_updatedata.fromTime = x.startTime;
      final_updatedata.toTime = x.endTime;
      final_updatedata.timeInterval = x.intervalTime;
    } else if(x.trackType===false){
      console.log("Tfalse");
    this.updateForm.get('startTime')?.clearValidators();
    this.updateForm.get('endTime')?.clearValidators();
    this.updateForm.get('intervalTime')?.clearValidators();

    // Update validators after clearing
    this.updateForm.get('startTime')?.updateValueAndValidity();
    this.updateForm.get('endTime')?.updateValueAndValidity();
    this.updateForm.get('intervalTime')?.updateValueAndValidity();
    final_updatedata.track = x.trackType;
    }
    console.log(final_updatedata);
    console.log(JSON.stringify(final_updatedata));
    if (this.updateForm.invalid ) {
      console.log("Invaild");
      return
    }
    this.loading = true
    this.gs.updateOrganization(this.id,final_updatedata).subscribe(
      (d: any) => {
        console.log("update",d);
        this.loading = false
        Swal.fire({
          text: d.description,
          icon: 'success',
          timer: 5000,
        })
        this.router.navigate(['/org-list']);
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.message
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.message)
          if (err) {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )

  }
  attendence(event: MatSelectChange): void {
    this.selectedValue = event.value;
   console.log('Selected attendence:', this.selectedValue);
 }

 attendenceType(event: MatSelectChange): void {
   this.attType = event.value;
  console.log('Selected Attendence Type:', this.attType);
 }

 tracking(event: MatSelectChange) :void
 {
   this.trackType = event.value;
   console.log('Selected track Type:', this.trackType);
 }
 onStartTimeChange(event)
 {
   const inputElement = event.target as HTMLInputElement;
   // const selectedTime = inputElement.value;
   // console.log(event);
    this.startTime=inputElement.value;
    console.log(this.startTime);
    if(this.startTime&&this.endTime&&this.endTime<=this.startTime)
      {
        this.f.endTime.setErrors({ invalidTime: true });
        console.log("Invaild Time");
        // this.wrongTime=true;
      }
      else
      {
        this.f.endTime.setErrors(null);
        console.log("Vaild Time");
        // this.wrongTime=false;
      }
 }
 onEndTimeChange(event)
 {
   const endElement = event.target as HTMLInputElement;
   this.endTime=endElement.value;
   console.log(this.endTime);
   if(this.startTime&&this.endTime&&this.endTime<=this.startTime)
    {
      this.f.endTime.setErrors({ invalidTime: true });
      // this.wrongTime=true;
      console.log("Invaild Time");
    }
    else
    {
      // this.wrongTime=false;
      this.f.endTime.setErrors(null);
      console.log("Vaild Time");
    }
 }
 onIntervalTimeChange(event)
 {
   const intervalElement = event.target as HTMLInputElement;
   this.intervalTime=intervalElement.value;
   console.log(this.intervalTime);
 }
 photoCapture(event: MatSelectChange): void {
   this.photoCap = event.value;
  console.log('Selected photoCapture:', this.photoCap);
 }

  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  onPasswordInput(): void {
    const passwordControl = this.updateForm.get('password');
    if (passwordControl) {
      passwordControl.markAsTouched(); // Mark the control as touched to show validation messages immediately
    }
  }
}
