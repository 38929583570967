<div class="d1 pt-5 pb-5">
    <!-- <mat-card class="col-md-8"> -->
    <mat-card class="">
      <ng-container *ngIf="loading;else elseComponent">
        <div class="loader">
          <app-loading></app-loading>
          <br>
          <h2>Please Wait... Registering!</h2>
        </div>
      </ng-container>
  
      
      <ng-template #elseComponent>
  
        <mat-card-title style="font-size: 15px;">
          Update Organization
       </mat-card-title>
       
        <form action="" [formGroup]="updateForm">
          <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Organization Name</mat-label>
              <mat-icon matPrefix style="
                            transform: translateY(-2px);">apartment</mat-icon>
              <input matInput type="text" formControlName="organizationName" placeholder="Organization name"
                [errorStateMatcher]="matcher" (keypress)="omit_special_char($event) && keyPress($event)">
              <mat-error style="color:red;" *ngIf="f.organizationName.hasError('required')">
                Organization is required
              </mat-error>
            </mat-form-field>
            
            <!-- org mail id -->
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Organization Mail-Id</mat-label>
              <mat-icon style="
                            transform: translateY(-2px);" matPrefix>email</mat-icon>
              <input matInput type="text" formControlName="contactEmail" placeholder="ex@jesperapps.com"
                [errorStateMatcher]="matcher" />
              <mat-error style="color:red;"
                *ngIf="f.contactEmail.hasError('pattern') && !f.contactEmail.hasError('required')">
                Please enter a valid mail address
              </mat-error>
              <mat-error style="color:red;" *ngIf="f.contactEmail.hasError('required')">
                Org Mail-Id is required
              </mat-error>
            </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>phone</mat-icon>
                <mat-label>Phone Number</mat-label>
                <input matInput type="text" placeholder="Contact" maxlength="10" (keypress)="noNumber($event)"
                   [disabled]='true' formControlName="contactPhoneNumber"
                  [errorStateMatcher]="matcher" />
                <mat-error style="color:red;" *ngIf="f.contactPhoneNumber.hasError('required')">
                  Phone Number is required
                </mat-error>
                <mat-error style="color:red;" *ngIf="f.contactPhoneNumber.hasError('minlength')"> Enter
                  Valid Phone Number
                </mat-error>
              </mat-form-field>
                
              
            <!-- <div>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>calendar_month</mat-icon>
                <mat-label>Select Trail Period</mat-label>
                <mat-select id="locationId" formControlName="freetrialId" [errorStateMatcher]="matcher">
                  <ng-container *ngIf="freetriallist.length > 0 ; else countryNodata">
                    <mat-option *ngFor="let u of  freetriallist" [value]="u.freeTrialId" >
                      {{u.noOfDays}}
                    </mat-option>
                  </ng-container>
                  <ng-template #countryNodata>
                    <mat-option>
                      {{'No freetriallist List Found'}}
                    </mat-option>
                  </ng-template>
                </mat-select>
                <mat-error style="color:red;" *ngIf="f.freetrialId.invalid">
                  Trail Period is required
                </mat-error>
              </mat-form-field>
            </div> -->
            <div>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>list_alt</mat-icon>
                <mat-label>Enable Attendence</mat-label>
                <mat-select  formControlName="attendence" [errorStateMatcher]="matcher" (selectionChange)="attendence($event)">
                  <mat-option [value]="true">
                      Yes
                  </mat-option>
                  <mat-option [value]="false">
                      No
                  </mat-option>
                </mat-select>
                <mat-error style="color:red;" *ngIf="f.attendence.invalid">
                  <!-- Please select enable attendence -->
                  Attendence is required
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="selectedValue">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>list_alt</mat-icon>
                <mat-label>Attendence Type</mat-label>
                <mat-select  formControlName="attendenceType" [errorStateMatcher]="matcher" (selectionChange)="attendenceType($event)">
                  <mat-option [value]="'Shift'">
                      Shift
                  </mat-option>
                  <mat-option [value]="'Non Shift'">
                      Non Shift
                  </mat-option>
                </mat-select>
                <mat-error style="color:red;" *ngIf="f.attendenceType.invalid">
                  <!-- Please select attendence Type -->
                  Attendence Type is required
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="selectedValue">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>photo</mat-icon>
                <mat-label>Enable Photo Capture</mat-label>
                <mat-select  formControlName="photoCapture" [errorStateMatcher]="matcher" (selectionChange)="photoCapture($event)">
                  <mat-option [value]="true">
                      With Photo
                  </mat-option>
                  <mat-option [value]="false">
                      Without Photo
                  </mat-option>
                </mat-select>
                <mat-error style="color:red;" *ngIf="f.photoCapture.invalid">
                  Photo Capture is required
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>spatial_tracking</mat-icon>
                <mat-label>Select Enable Tracking</mat-label>
                <mat-select  formControlName="trackType" [errorStateMatcher]="matcher" (selectionChange)="tracking($event)">
                  <mat-option [value]="true">
                      Yes
                  </mat-option>
                  <mat-option [value]="false">
                      No
                  </mat-option>
                </mat-select>
                <mat-error style="color:red;" *ngIf="f.trackType.invalid">
                  <!-- Please select enable tracking -->
                  Tracking is required
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="trackType">
              <div class="time-container">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tracking Start Time</mat-label>
                  <input class="s1" matInput type="time" formControlName="startTime" (change)="onStartTimeChange($event)">
                  <mat-error style="color:red;" *ngIf="f.startTime.invalid">
                    <!-- Please select tracking start time -->
                    Tracking Start Time is required
                  </mat-error>
                </mat-form-field>
            
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tracking End Time</mat-label>
                  <input  class="s1" matInput type="time" formControlName="endTime" (change)="onEndTimeChange($event)">
                  <!-- <mat-error style="color:red;" *ngIf="f.endTime.invalid"> -->
                    <!-- Please select tracking end time -->
                    <!-- Tracking End Time is required -->
                  <!-- </mat-error> -->
                  <mat-error *ngIf="f.endTime.errors?.required">
                    Tracking End Time is required.
                  </mat-error>
                  <mat-error *ngIf="f.endTime.errors?.invalidTime">
                       End time must be greater than the start time.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- <div *ngIf="trackType">
              <div class="time-container">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tracking Interval Time</mat-label>
                  <input class="s1" matInput type="time" formControlName="intervalTime" (change)="onIntervalTimeChange($event)">
                  <mat-error style="color:red;" *ngIf="f.intervalTime.invalid">
                    Tracking Interval Time is required
                  </mat-error>
                </mat-form-field>
              </div>
              <span style="color: red;font-size: 12px;">Note:choose a tracking interval time minimum allowed interval is 15 minutes or more..</span>
            </div> -->
            <div *ngIf="trackType">
              <div class="time-container">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Tracking Interval Time</mat-label>
                  <input 
                    class="s1" 
                    matInput 
                    type="time" 
                    formControlName="intervalTime" 
                    (change)="onIntervalTimeChange($event)">
                  <mat-error style="color:red;" *ngIf="f.intervalTime.errors?.required">
                    Tracking Interval Time is required.
                  </mat-error>
                  <mat-error style="color:red;" *ngIf="f.intervalTime.errors?.minTime">
                    Tracking Interval Time must be minimum 15 minutes or more.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- <div>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon style="transform: translateY(-2px);" matPrefix>photo</mat-icon>
                <mat-label>Enable Photo Capture</mat-label>
                <mat-select  formControlName="photoCapture" [errorStateMatcher]="matcher" (selectionChange)="photoCapture($event)">
                  <mat-option [value]="true">
                      With Photo
                  </mat-option>
                  <mat-option [value]="false">
                      Without Photo
                  </mat-option>
                </mat-select>
                <mat-error style="color:red;" *ngIf="f.photoCapture.invalid">
                  Photo Capture is required
                </mat-error>
              </mat-form-field>
            </div> -->
                <br>
              <mat-card-title style="font-size: 15px;">
                User 
              </mat-card-title> 
  
                  <!-- user name -->
                  <mat-form-field appearance="outline" class="example-full-width">
                   <mat-label>Full Name</mat-label>
                   <mat-icon matPrefix style="
                                   transform: translateY(-2px);">person</mat-icon>
                   <input matInput type="text" formControlName="name" placeholder="name" [errorStateMatcher]="matcher"
                     (keypress)="omit_special_char($event) && keyPress($event)">
                   <mat-error style="color:red;" *ngIf="f.name.hasError('required')">
                     Full Name is required
                   </mat-error>
                 </mat-form-field>
       
       
                  <!-- mail id -->
                  <mat-form-field class="example-full-width" appearance="outline">
                   <mat-label>User Mail-Id</mat-label>
                   <mat-icon style="
                                 transform: translateY(-2px);" matPrefix>email</mat-icon>
                   <input matInput type="text" formControlName="email" placeholder="ex@jesperapps.com"
                     [errorStateMatcher]="matcher" />
                   <mat-error style="color:red;"
                     *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">
                     Please enter a valid mail address
                   </mat-error>
                   <mat-error style="color:red;" *ngIf="f.email.hasError('required')">
                    User Mail-Id is required
                   </mat-error>
                 </mat-form-field>
       
                  <!-- mobile number  -->
                  
                   <mat-form-field class="example-full-width" appearance="outline">
                     <mat-icon style="transform: translateY(-2px);" matPrefix>phone</mat-icon>
                     <mat-label>User Phone Number</mat-label>
                     <input matInput type="text" placeholder="Contact" maxlength="10" (keypress)="noNumber($event)"
                      [disabled]='true' formControlName="phoneNumber"
                       [errorStateMatcher]="matcher" />
                     <mat-error style="color:red;" *ngIf="f.phoneNumber.hasError('required')">
                       User Phone Number is required
                     </mat-error>
                     <mat-error style="color:red;" *ngIf="f.phoneNumber.hasError('minlength')"> Enter 
                         Valid
                         User Phone Number</mat-error>
                   </mat-form-field>
                 
       
                       <!-- password -->
                   <!-- <mat-form-field class="example-full-width" appearance="outline">
                       <mat-icon style="transform: translateY(-3px);" matPrefix>lock</mat-icon>
                       <mat-label>Password</mat-label>
                       <input id="password1" type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
                         placeholder="enter password" [ngClass]="{ 'is-invalid':f.password.errors  && (f.password.touched || f.password.dirty) }"
                         minlength="8"
                        maxlength="12"
                        autocomplete="new-password" 
                        (input)="onPasswordInput()"
                        matInput 
                        showHideInput>
                       <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                         [attr.aria-pressed]="hide">
                         <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                       </button>
                       <mat-error style="color:red;"  *ngIf="f.password.hasError('required') && (f.password.touched || f.password.dirty)">
                        Password is required
                      </mat-error>
                       <mat-error  style="color:red;" *ngIf="f.password.hasError('pattern')">
                        Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                      </mat-error>
                      <mat-error style="color:red;"  *ngIf="f.password.hasError('minlength')">
                        Password must be at least 8 characters long.
                      </mat-error>
                      <mat-error style="color:red;"  *ngIf="f.password.hasError('maxlength')">
                        Password cannot be more than 12 characters long.
                      </mat-error>
                   </mat-form-field>   -->
       <!--  <br> -->
        <div class="row">
          <div class="col-sm-6"><button mat-stroked-button  [routerLink]="['/org-list']" color="warn" style="width: 100%; margin-top: 15px;" type="button"
            mat-dialog-close>CANCEL</button></div>
            <div class="col-sm-6">
               <button mat-stroked-button color="warn" (click)="updateOrg()" style="width: 100%; margin-top: 15px;" type="submit" mat-button>
               UPDATE
               </button>
            </div>
        </div>
      </form>
      </ng-template>
     
    </mat-card>
  </div>
  
    