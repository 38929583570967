import { Component, ElementRef, OnInit, ViewChild,ChangeDetectorRef  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {AssignClientserviceService} from '../app-assign-client-location/assign-clientservice.service';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { AliasDialogComponent } from '../alias-dialog/alias-dialog.component';
declare var google: any;

@Component({
  selector: 'app-assign-client-location',
  templateUrl: './assign-client-location.component.html',
  styleUrls: ['./assign-client-location.component.scss']
})
export class AssignClientLocationComponent implements OnInit {

  @ViewChild('search') searchElementRef!: ElementRef;
  myModel = ''
  public mask = [/[0-9]/, /\d/, '-', /\d/, /\d/]
  
  AddLocationForm !: FormGroup
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom:number;
  address: any;
  geocodedaddress :any;
  isLoading :any;
  map:any;
  Marker:any;
  loading:any= false;
  addressList:any[]=[];
  clientsList:any[]=[];
  getLatLong:any;
  markedAddress:any;
  complexName:any;
  getLat:any;
  getLong:any;
  organizationId:any;
  locationDetails:any[]=[];
  listClientName:any;

  // Array to store multiple marker coordinates
  markers: Array<{lat: number, lng: number, draggable: boolean, index:number,aliaName:string,createStatus:boolean}> = [
    { lat:12.7409232, lng:77.8252811, draggable: true,index:0,aliaName:"Default Drag Marker",createStatus:false},
    // { lat: 0, lng: 0, draggable: true },
    // { lat: 0, lng: 0, draggable: true },
  ];
  aliasName: any;
  canReloadMap: boolean=true;
  index: any=Number;
  markLen: number;

  constructor(private fb: FormBuilder , private auth : AuthService , 
    private gs : GlobalserviceService ,private router: Router, private Assignclientservice:AssignClientserviceService,
    private dialog: MatDialog,private cDR:ChangeDetectorRef) { }

  ngOnInit(): void {

    let user = this.auth.getLocalStorageData()
    console.log(user);
    this.organizationId = user.organization.organizationId;
    this.isLoading = false;
    this.AddLocationForm = this.fb.group({
      user :  ['', [Validators.required ]],
      AddressName :  ['', [Validators.required ]],
      // aliasName :['',[Validators.required ]],
      // clientcode:['',Validators.required],
      // clientname:['',Validators.required],
      address: this.fb.group(
        {
          addressName: [''],
          latitude: ['', Validators.required],
          longitude: ['', Validators.required],
        }
      ),
      status: this.fb.group({
        statusId: 1
      })
    })

    this.gs.getClientbyOragnization(this.organizationId).subscribe((a: any) => {
      this.clientsList = a;
      console.log('this.clientsList',a);
    })
    // this.gs.get_user_org(1).subscribe((a: any) => {
    //   this.clientsList = a;
    //   console.log('this.clientsList',this.clientsList);
    // })
    this.setCurrentLocation();
   // this.addLocation();
  }

  get f() {
    return this.AddLocationForm.controls;
  }
  getClient(){
    this.AddLocationForm.value.user;

    console.log('user2', this.AddLocationForm.value);

  }
getClientName(clientName:any){
console.log('clientName',clientName);
this.listClientName = clientName.clientName;
}

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.zoom = 15;
  //     });
  //   }
  // }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 15;
  
          // Update the first marker with the current location
          this.markers[0].lat = this.latitude;
          this.markers[0].lng = this.longitude;
        },
        (error) => {
          console.error('Error getting location: ', error);
        }
      );
    }
  }


  onMapReady(map: any) {
    alert("map ready");
    console.log(map);
    this.map = map;
  }

  // getaddress()
  // {
  //       let address = document.getElementById("address");

  //       const options = {
  //         fields: ["formatted_address", "geometry", "name"],
  //         strictBounds: false,
  //         types: ["establishment"],
  //       };
      
  //      // map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
  //    const autocomplete = new google.maps.places.Autocomplete(address);
  //  //     const autocomplete = autocompleteapi(address, options);
  //    //   console.log(autocomplete);

  //       autocomplete.addListener("place_changed", ()=>{
  //              this.loading = true;
  //           let place = google.maps.places.PlaceResult = autocomplete.getPlace();

  //           if(place.geometry === undefined || place.geometry === null) {
  //             return;
  //           }
  //           let lat = place.geometry.location.lat();
  //         let lng = place.geometry.location.lng();

  //       // const mark = new google.maps.LatLng(lat,lng);

  //         this.latitude = lat
  //         this.longitude = lng
  //         this.loading = false;
  //       console.log(lat,lng);
  //       })
  // }

  getaddress() {
    let address = document.getElementById("address") as HTMLInputElement;
  
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    };
  
    const autocomplete = new google.maps.places.Autocomplete(address, options);
  
    // Add a listener for when the user selects an address
    autocomplete.addListener("place_changed", () => {
      this.loading = true;
  
      const place = autocomplete.getPlace();
      console.log("place",place);
      if (!place.geometry || !place.geometry.location) {
        this.loading = false;
        console.error("No geometry found for the selected place.");
        return;
      }
      
      // Get latitude and longitude from the selected place
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
  
      // Update the marker array to set the new marker at the searched location
      this.latitude = lat;
      this.longitude = lng;
  
      // Example: Update the first marker in the array
      this.markers[0] = { lat: this.latitude, lng: this.longitude, draggable: true,index:-1,aliaName:"Default Drag Marker",createStatus:false};
  
      // Optional: If you want to center the map at the new location
      this.zoom = 15;  // Adjust the zoom level if needed
      this.loading = false;
      console.log(`Lat: ${lat}, Lng: ${lng}`);  // Check in the console
    });
  }
  

  addLocation() {
    console.log(this.AddLocationForm.value)
    let x = this.AddLocationForm.value
       

    if (this.AddLocationForm.invalid) {
      return
    }
   
    // alert("form submitted");
     
     let user = this.auth.getLocalStorageData()
     let locationdata;
        console.log(user);
        // let locationDetails:any[] = [];
console.log(this.getLat);
console.log(this.getLong);
console.log(this.markedAddress);
console.log(this.complexName);
    
console.log("this.locationDetails",this.locationDetails);
    // let locationDetailsObjects= {
    //               latitude: this.getLat,
    //               longitude:  this.getLong,
    //               address:  this.markedAddress,
    //               locationName: this.complexName,
    //               aliasName: this.aliasName,
    //               status:{
    //                   listTypeValueId:1
    //               },
    //                organization:{
    //                   organizationId:this.organizationId
    //               }
    //           }
    //           this.locationDetails.push(locationDetailsObjects);
              console.log("this.locationDetails",this.locationDetails);
              let body={

                clientId: this.AddLocationForm.value.user,
                locationDetails: this.locationDetails
                
                }
              console.log('payloadchecking', body);

          // if(user.organization.organizationId != null 
          //   || user.organization.organizationId != undefined)
          //   {
          //     {
          //     clientId: 1,
          //     locationDetails: [
          //         {
          //             latitude:40.7128,
          //             longitude: -74.0060,
          //             address: "123 Broadway, New York, NY",
          //             locationName: "New York Office",
          //             status:{
          //                 listTypeValueId:1
          //             },
          //              organization:{
          //                 organizationId:1
          //             }
          //         },
          //         // {
          //         //     "latitude": 37.7749,
          //         //     "longitude": -122.4194,
          //         //     "address": "456 Market St, San Francisco, CA",
          //         //     "locationName": "San Francisco Office",
          //         //     "status":{
          //         //         "listTypeValueId":1
          //         //     },
          //         //      "organization":{
          //         //         "organizationId":1
          //         //     }
          //         // }
          //     ]
          // }
          
            // }
         

          console.log("locationdata");
          console.log(locationdata);
          // return

          this.isLoading = true
          this.gs.assignClientLocations( this.AddLocationForm.value.user,body).subscribe((a: any) => { 
            if (a.statusCode == 200) {
              Swal.fire(
                a.message,
                "",
                'success'
              )
              this.isLoading = false
              this.router.navigate(['/client-location']);
            
            }
            
         
   
            console.log(a)
          },
          err => {
            console.log
            this.isLoading = false
         
            Swal.fire(
              err.error.message,
              "",
              'error'
            )
          })

  }

  markerDragEnd(event:any,index) {
   console.log('event',event.coords+"Index"+index);
    this.getLatLong = event.coords;
    const updatedLat = event.coords.lat;
    const updatedLng = event.coords.lng;
    const indexpos=index;
    this.getLong = updatedLng;
    this.getLat = updatedLat;
    // this.markers[index].lat = updatedLat;
    // this.markers[index].lng = updatedLng;
    // this.markers.push({lat:updatedLat,lng:updatedLng,draggable:true});
    // console.log('markers',this.markers);
    this.latitude = event.coords.lat
    this.longitude =event.coords.lng
    this.index=index;
    let geocoder = new google.maps.Geocoder;
    let latlng = {lat: this.latitude, lng: this.longitude};
    let that = this;
  
      geocoder
    .geocode({ location: latlng })
    .then((response :any) => {
      console.log(response.results[0].formatted_address);
      this.markedAddress=response.results[0].formatted_address;
      console.log("this.markedAddress",this.markedAddress);
      this.complexName = this.markedAddress.split(",")[0].trim()
      this.addressList.push(response.results[0].formatted_address)
      this.addressList.forEach((address,index) => {
        console.error('marker:', address);
        if (typeof address.lat !== 'number' || typeof address.lng !== 'number') {
          console.error('Invalid lat or lng:', address);
        }
       this.AddLocationForm.patchValue({
        AddressName : address
       })
      });
      this.AddLocationForm.get('address')?.patchValue({
        latitude: event.coords.lat,
        longitude:event.coords.lng,
      })
    })
    console.log("length"+this.markers.length);
    this.markLen=this.markers.length;
    
    // console.log("Index",index);
    // const markerIndeExists = this.markers.find(marker => marker.index === index);
    // console.log("markerIndeExists",markerIndeExists);
    // if (this.markers[index]) {
    //   console.log('Marker exists at index position:', index, this.markers[index]);
    // } else {
    //   console.log('No marker found at index position:', index);
    // }
    // Edit Marker
    // if(this.markers[index]&&this.markers[index].createStatus==true)
    // {
    //   console.log('Edit Marker exists at index position:', index, this.markers[index]);
    //    // Open dialog to enter alias name when marker is dragged
    //   const dialogRef = this.dialog.open(AliasDialogComponent, {
    //     width: '350px',
    //     disableClose: true,
    //     data: { aliasName: this.markers[index].aliaName, index:index }
    //   });
    //   dialogRef.afterClosed().subscribe((aliasName) => {
    //     if (aliasName) {
    //       this.aliasName = aliasName;
    //     }
    //         console.log(this.AddLocationForm.value)
    //        //alert("address" + this.AddLocationForm.get('address')?.invalid);
    //       //  if(this.markedAddress)
    //       //  {
    //       //   console.log("function",this.markedAddress);
    //         let status=this.Assignclientservice.getStatus();
    //         console.log("AliasStatus",status);
    //         if(status==true)
    //         {
    //           console.log("AliasStatus If",status);
    //           this.markers.forEach((m:any)=>{
    //             console.log(m.index+"i"+index);
    //               if(m.index===index)
    //               { 
    //                 let i=index+1;
    //                 this.markers[i]={
    //                   lat:updatedLat,
    //                   lng:updatedLng,
    //                   draggable:true,
    //                   index:index,
    //                   aliaName:this.aliasName,
    //                   createStatus:true
    //                 };
    //                 // this.cDR.detectChanges();
    //                 // this.markers[index].push({lat:updatedLat,lng:updatedLng,draggable:true,index:index,aliaName:this.aliasName,});
    //                 console.log('markers',this.markers);
    //                 this.canReloadMap = true;  
    //                 this.updateLocationDetails();
    //               }
    //           }) 
    //         }
    //         else
    //         {
    //           console.log("AliasStatus False",status);
    //           this.canReloadMap = false; // Hide the map
    //           this.cDR.detectChanges(); // Trigger change detection
    //           this.canReloadMap = true;  // Show the map again
    //           console.log('Map reloaded',this.markers);
    //           // this.canReloadMap = true;  
    //         }
            
    //       //  }  
    //   })
    // }
    // else
    // {
      console.log("Create AliasName");
      // Open dialog to enter alias name when marker is dragged
      const dialogRef = this.dialog.open(AliasDialogComponent, {
        width: '350px',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((aliasName) => {
        if (aliasName) {
          this.aliasName = aliasName;
        }
            console.log(this.AddLocationForm.value)
           //alert("address" + this.AddLocationForm.get('address')?.invalid);
          //  if(this.markedAddress)
          //  {
          //   console.log("function",this.markedAddress);
            let status=this.Assignclientservice.getStatus();
            console.log("AliasStatus",status);
            if(status==true)
            {
              console.log("AliasStatus If",status);
            
              this.markers.push({
                lat:updatedLat,
                lng:updatedLng,
                draggable:false,
                index:this.markLen,
                aliaName:this.aliasName,
                createStatus:true});
              this.updateLocationDetails();
              console.log('markers',this.markers);
              this.canReloadMap = false;
              this.cDR.detectChanges();
              this.canReloadMap = true;  
            }
            else
            {
              console.log("AliasStatus False",status);
              this.canReloadMap = false; // Hide the map
              this.cDR.detectChanges(); // Trigger change detection
              this.canReloadMap = true;  // Show the map again
              console.log('Map reloaded',this.markers);
              // this.canReloadMap = true;  
            }
          //  }  
      })
    // }   
  }

  updateLocationDetails() {
    console.log("this.markedAddress1",this.markedAddress);
    // Create location object and push it into the locationDetails array
    let locationDetailsObjects = {
        latitude: this.getLat,
        longitude: this.getLong,
        address: this.markedAddress,
        locationName: this.complexName,
        aliasName: this.aliasName,
        status: {
            listTypeValueId: 1
        },
        organization: {
            organizationId: this.organizationId
        }
    };
     console.log(locationDetailsObjects);
    // Assuming locationDetails is defined outside as part of the class
    this.locationDetails.push(locationDetailsObjects);
    console.log("this.locationDetails",this.locationDetails);
}

  mapClicked(c: any) {
    console.log(c)
    let a = c.coords
    this.latitude = a.lat
    this.longitude = a.lng


    let x = this.AddLocationForm.get('address')?.patchValue({
      // latitude: a.lat,
      // longitude: a.lng,


    })
    console.log(x)
  }

//   onMouseOver(infoWindow:any,marker: MouseEvent) {
//     //  this.address=this.data.address;
//      console.log("open address",this.address);
//     infoWindow.open();
// }

// onMouseOut(infoWindow:any,marker: MouseEvent) {
// console.log("close address",this.address);
//     infoWindow.close();
// }
}
