import { Component, OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalserviceService } from '../providers/globalservice.service';
import Swal from 'sweetalert2';
import { Router, RouterLink } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { AuthService } from '../Authenticaton/auth.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-createshift',
  templateUrl: './createshift.component.html',
  styleUrls: ['./createshift.component.scss']
})
export class CreateshiftComponent implements OnInit {
  locationName: any;
  stroreShiftId: any;
locationList: any=[];
  userId: any;
  storeOrgId: any;
  storeClientId: any;
  storeLocationId: any;
  storeEmpId: any;
allSelected: any;
@ViewChild('select') select: MatSelect;
isDisabled: boolean;
  selectedEmpId: any;
user_required: any;
submitdisable: boolean=false;
  userdata: any;
  selectedEmpIds: any[]=[];
  employeeloading: boolean ;
  noemployee: boolean;
  nolocation: boolean;
isselecLocation($event: any) {
throw new Error('Method not implemented.');
}
  endDate_error: boolean=false;
  startDate: any;
  endDate: any;
  staDate_required: boolean=false;
startDate_required: any;
endDate_required: boolean=false;
  clicentName: any;
  enddate1: any;
  startdate1: any;
tableShow: boolean=false;
  p: number=1;
mySelect: number=5;
currentPageOffset: number=0;
dataSource = new MatTableDataSource<any>([]); // Initialize MatTableDataSource with employee data
input: any;
displayedColumns: string[] = ['sino','employeeName',"delete"];
geoList: any=[];
empList: any[]=[];
shifts: any=[];
btndisable: boolean=true;
shiftid: any;
employee: any;
isClientSelected: boolean=false;
employeeNotFound: any;
submitted1: any;
empolyeeapproverexist: any;
  postdatamultiple: never[];
  employeeId: any;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private employeeSubscription: Subscription | null = null;
  employeelistsub: any;
  empolyeereadonly: boolean=false;
  disableFeildforAll: boolean=false;
readonlyDisable: boolean=false;
  selectedEmployeeData: any[]=[];

  constructor(
    private datepipe:DatePipe,
    private gs: GlobalserviceService,
    private router:Router,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    let  userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    this.userdata = this.auth.getLocalStorageData();
    this.userId=userdata.userId;
    this.storeOrgId=userdata.organization.organizationId
    console.log("this.storeOrgId",this.storeOrgId);
    this.isselecteduser(event);
    this.isselecteShifts(event)
    // this.isselectedname(event);
    this.gs.get_client_org( this.storeOrgId).subscribe((a: any) => {
      this.tableShow=false
      console.log(a);
      this.geoList=a;
      this.geoList = Array.isArray(a) ? a : [a];
      // this.geoList.forEach((client:any)=>{
      //   this.storeClientId=client.clientId
      //   console.log(  this.storeClientId);
        
      // })
      
    })
   
  }

  location(clientdId:string){
    
    this.gs.get_location_byClient( this.storeClientId).subscribe((response:any)=>{
console.log(response);
this.nolocation = false;
this.locationList=response;
this.locationList = Array.isArray(response) ? response : [response];


    },
  (err:any)=>{
    console.log("err:",err);
    if(err.status == 404){
      this.nolocation = true;
    }
  })
  }
  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }
  changefirstpage() {
    this.p = 1;
    }
    isselecteShifts(event){

      this.stroreShiftId=event?.value
      console.log( this.stroreShiftId);
      
      this.gs.get_shift_byClient_list().subscribe((d: any) => {
        console.log('shift response:', d);
        // this.shifts=d;
        // this.stroreShiftId=this.shifts.shiftId;
        // console.log("   this.stroreShiftId",   this.stroreShiftId);
        
        this.shifts = Array.isArray(d) ? d : [d];
        // this.dataSource = new MatTableDataSource(this.shifts); // Update data source
        this.tableShow = true; // Show table
    });

    }
    clearEmpArray(){
      this.empList=[]
    }

    empSearch(event){ 
      // this.empolyeereadonly=false;
      this.postdatamultiple = [];
      this.employeeNotFound = false; // Reset the no employee flag
      console.log(event.target.value);
      let employeenumber = event.target.value;
      console.log(employeenumber);
      
      // Employee List API
      if(employeenumber.length >= 2){
        if(this.employeelistsub) {
          this.employeelistsub.unsubscribe();
        }
        this.employeelistsub = this.gs.getEmployeeNameList(event.target.value)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.gs.getEmployeeNameList(event.target.value))
          )
          .subscribe((result: any) => {
            this.empList = [];
            
            if (result.statusCode) {
              console.log(result.statusCode);
              this.empList = [];
              this.employeeNotFound = true; // Set the flag if status code indicates no employees
            } else {
              result.map(
                (e: any) => {
                  if (this.userdata.userType[0].userTypeName == "BUDDY USER") {
                    if (e.userId != this.userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && this.userdata.userType[0].userTypeName == "BUDDY USER")
                      && e.buddyUser?.userId == this.userdata.userId && e.status == "Active") {
                      console.log(e);
                      this.empList.push(e);
                    }
                  }
                  else {
                    if (e.userId != this.userdata.userId && e.status == "Active") {
                      console.log(e)
                      this.empList.push(e);
                    }
                  }
                }
              )

              // this.empList = result;
              if (this.empList.length === 0) {
                this.employeeNotFound = true; // Set the flag if the list is empty
              }
            }
          
    
          })
        }
      }
      // deleteemployee(index) {
      //   this.employee=null;
      //   this.empolyeeapproverexist=false;
      //   this.btndisable=true;
      //   if (index != -1) {
      //     this.selectedEmployeeData.splice(index, 1);
          
      //   }
      // }
      deleteemployee(index: number) {
        if (index !== -1) {
            // Remove the selected employee from the array
            this.selectedEmployeeData.splice(index, 1);
            
            // Update the MatTableDataSource to reflect changes
            this.dataSource.data = [...this.selectedEmployeeData];
    
            // You can add any additional logic for handling empty employee data, etc.
            if (this.selectedEmployeeData.length === 0) {
                this.tableShow = false;
                this.btndisable = true; // Disable buttons if no employees are left
            }
        }
    }
    
      empAllSelect() {
        if (this.employee === "All") {
          // Disable the field immediately after selecting "All"
          this.disableFeildforAll = true;
          this.empolyeereadonly = true;
          this.readonlyDisable = true;
      
          // Fetch the employee list
          this.gs.get_employee_byClient_list(this.storeOrgId).subscribe((response: any) => {
            console.log('Employee response:', response); // Log the response for debugging
      
            // Check if the response is an array and assign it properly
            if (Array.isArray(response)) {
           
              this.empList=response
              this.selectedEmployeeData=this.empList
              // Update the MatTableDataSource
              this.dataSource.data = [...this.selectedEmployeeData]; // Spread to trigger change detection

              console.log("this.datasourceeeeeeeeeee::",this.dataSource);
              
              this.tableShow = true; // Show the table
              this.btndisable = false; // Enable buttons if needed
            } else {
              console.error('Response is not an array', response);
            }
          }, (error) => {
            console.error('Error fetching employee list', error); // Handle error
          });
        }
      }
      
      
    
      empSelect(emp) { 
        // Reset some flags and fields
        this.disableFeildforAll = false;
        this.isClientSelected = false;
        this.empolyeeapproverexist=false;
    
        // Log the selected employee
        console.log(emp);
        this.storeEmpId=emp.employeeId
        console.log("this.storeEmpId",this.storeEmpId);
        
        this.tableShow = true;
    
        // Store selected employee details
        this.employeeId = emp.employeeId;
        this.employee = `${emp.employeeNumber} - ${emp.user.name}`;
        console.log(this.employee);
    
        if (emp) {
            console.log("if emp came here!!!");
    
            // Check if employee already exists in the selectedEmployeeData array
            const exists = this.selectedEmployeeData.find((e) => e.employeeId === emp.employeeId);
    
            if (!exists) {
                console.log("if not existed, came here!!!");
    
                // Add the selected employee to the array only if it does not exist
                this.selectedEmployeeData.push(emp);
            } else {
                console.log("else already exists, came here!!!");
                this.empolyeeapproverexist = true; // Show error message for existing employee
            }
    
            // Update the MatTableDataSource
            this.dataSource.data = [...this.selectedEmployeeData]; // Spread to trigger change detection
        }
    
        // Check if a client is selected
        // if (this.clicentName) {
        //     console.log("Client selected");
        //     this.isClientSelected = true; // Enable fields if a client is selected
        //     this.tableShow = true; // Show the table
    
        //     this.gs.get_employee_byClient_list(this.storeOrgId).subscribe((d: any) => {
        //         console.log('Employee response:', d);
        //         this.empList = d;
        //         this.btndisable = false;
    
        //         // Ensure the selected employee is added only once
        //         if (!this.selectedEmployeeData.find((e) => e.employeeId === emp.employeeId)) {
        //             this.selectedEmployeeData.push(emp);
        //         }
    
        //         // Update the data source
        //         this.dataSource.data = [...this.selectedEmployeeData]; // Spread operator to update MatTableDataSource
        //         console.log("this.datasource::", this.dataSource);
        //     });
        // } else {
        //     console.log("No client selected");
        //     this.employee = null; // Clear employee field if no client is selected
    
        //     // Check if the employee is already selected
        //     const hasEmployee = this.selectedEmployeeData.some(e => e.employeeId === emp.employeeId);
        //     if (!hasEmployee) {
        //         this.selectedEmployeeData.push(emp); // Add employee to the selected list
        //         console.log("Updated selectedEmployeeData", this.selectedEmployeeData);
        //     } else {
        //         console.log("Employee already exists in the list");
        //     }
    
        //     // Ensure the data source for the table is updated
        //     this.dataSource.data = [...this.selectedEmployeeData]; // Update the data source for the table
        // }
    
        // Uncomment if you want to manage validators
        // if (this.selectedEmployeeData.length >= 1) {
        //     this.employee?.clearValidators();
        // } else {
        //     this.employee?.setValidators([Validators.required]);
        // }
        // this.employee?.updateValueAndValidity();
    }
    toggleAllSelection() {
      if (this.allSelected) {
        this.select?.options.forEach((item: MatOption) => item.select());
        this.employee?.setValue( this.selectedEmpId.map(e => e.employeeId));
      } else {
        this.select?.options.forEach((item: MatOption) => item.deselect());
        this.employee?.setValue([]);
      }
    }
   
  //   toggleAllSelection() {
  //     if (this.allSelected) {
  //       console.log("select");
  //       this.select.options.forEach((item: MatOption) => item.select());
  //      //  this.add();
  //     } 
  //     else {
  //       console.log("unselect");
  //       this.isDisabled=true;
  //        } 
  //  }
    noEmployeeFound() {
      this.employeeNotFound = false;
      this.employeeId = undefined;
      this.employee = undefined;
    }
  
    onClientSelection(event: any) {
      this.tableShow=false
      const selectedClientId = event.value;
      
      // Enable employee dropdown only if a valid client is selected
      if (selectedClientId && selectedClientId !== '0') {
        this.isClientSelected = true;
        this.tableShow=false;
        this.empList =[];
        this.employeeloading=true;
        this.noemployee=false;
        this.nolocation = false;
        // Call the API to get the list of employees for the selected client
        this.getEmployeesByClient(selectedClientId);
        this.location(selectedClientId)
      } else {
        this.isClientSelected = false;
        this.empList = []; 
      }
    }
    getEmployeesByClient(clientId: string) {
      this.tableShow=false
      // API call to fetch employees for the selected client
          // Unsubscribe from the previous API call if it exists
    if (this.employeeSubscription) {
      this.employeeSubscription.unsubscribe();
    }

    // Make a new API call and store the subscription
    this.employeeSubscription = this.gs.get_employee_byClient_list( this.storeClientId).subscribe((d: any) => {
        console.log('Employee response:', d);
        this.employeeloading=false;
        // this.employee=d
        // this.btndisable=false;
        this.tableShow=false
        // this.empList=d;
        this.selectedEmpId=d;
        // d = Array.isArray(d) ? d : [d];
        if(d.statusCode==204){
          console.log("204 here!!");
          this.noemployee=true;
          
          this.isClientSelected=true;
          this.empList=[];
          this.locationList=[];
        }
        else{
          this.noemployee=false;

        this.isClientSelected=false;
        d.map(
          (e: any) => {
            if (this.userdata.userType[0].userTypeName == "BUDDY USER") {
              if (e.user?.userId != this.userdata.userId && (e.user?.userType[0].userTypeName != "PRIMARY USER" && this.userdata.userType[0].userTypeName == "BUDDY USER")
                && e.user?.buddyUser?.userId == this.userdata.userId) {
                console.log(e);
                this.empList.push(e);
              }
            }
            else {
              if (e.user?.userId != this.userdata.userId) {
                console.log(e)
                this.empList.push(e);
              }
            }
          }
        )
        
        console.log("emplist",this.empList);
        if(this.empList.length ==0){
          this.noemployee=true;
        }
      }
        // Add selectedShift field for each employee to bind to the shift select element
        // this.empList = Array.isArray(d) ? d.map(emp => ({ ...emp, selectedShift: null })) : [];
        // this.dataSource = new MatTableDataSource(this.empList); // Update data source
        // this.tableShow = true; // Show table
      });
    }
    ngOnDestroy() {
      // Clean up the subscription if the component is destroyed
      if (this.employeeSubscription) {
        this.employeeSubscription.unsubscribe();
      }
    }
    isselectLocation(event){
      this.storeLocationId=event.value

    }
    isselecteEmployee(event){
      // this.storeEmpId=event.value
    }
    
  isselectedname(event){
    // this.user_required=false;
    console.log("user",event.value);
    this.storeClientId=event.value
    // this.submitted=false;
    // this.geoList=event.value;
    // this.tableShow=false
    // console.log(this.geoList);
    // this.gs.get_client_org( this.storeOrgId).subscribe((a: any) => {
    //   this.tableShow=false
    //   console.log(a);
    //   this.geoList=a;
    //   this.geoList = Array.isArray(a) ? a : [a];

    //   this.geoList.forEach((client:any)=>{
    //     this.storeClientId=client.clientId
    //     console.log(  this.storeClientId);
        
    //   })
    // })
  }
  isselectedemployesname(event: any) {
    this.selectedEmpIds = event.value;
    console.log("selectedEmpId::",this.selectedEmpIds);
    
    // const selectedEmp = this.empList.find(emp => emp.employeeId === selectedEmpId);

    // if (selectedEmp) {
      // Access the data array from MatTableDataSource to check for duplicates
      // const dataArray = this.dataSource.data;
      // const alreadyExists = dataArray.some(emp => emp.employeeId === selectedEmpId);

      // if (!alreadyExists) {
      //   dataArray.push(selectedEmp); // Add the employee to the array
      //   // this.dataSource.data = [...dataArray]; // Update the dataSource to trigger change detection
      // } else {
      //   this.empolyeeapproverexist = true; // Show "Approver Already Exist" message
      //   console.log("Approver Already Exists");
      // }
    // }
  }
  isselecteduser(event){
    
  }
  submit(){
    this.submitdisable=true;

    console.log("submir came here!!!");
    console.log("this.locationName" ,this.locationName+ "this.clicentName" ,this.clicentName +"this.startdate1",this.startdate1 +"this.enddate1",this.enddate1+"this.employee"+this.employee);
    
    if (this.locationName==undefined&&this.clicentName==undefined&&this.startdate1==undefined&&this.enddate1==undefined&&this.shiftid==undefined&&this.employee==undefined) {
      
      this.submitdisable=false;
      alert("Please Fill all Fields!!")
      return
      // Swal.fire({
      //   text: 'No employee data to submit!',
      //   icon: 'error',
      //   timer: 2000,
      // });
      // return; // Exit the function if there's no data
    }
    else{
      this.submitdisable=true;

      // const commonPayload = {
      //   startDate: this.startdate1,
      //   endDate: this.enddate1,
      //   client: {
      //     clientId: this.clicentName
      //   },
      //   status: {
      //     listTypeValueId: 1 // Example value, can be dynamic
      //   },
      //   shift: {
      //     shiftId: this.stroreShiftId // Selected shift for each employee
      //   },
      //   locationDetails: {
      //     locationId: this.storeLocationId
      //   },
      //   employee:{
      //   employeeId:this.storeEmpId

      //   }
      // };
      
      

  
      if ((Array.isArray(this.empList))&&this.allSelected) {
        console.log("if came here----multipleee");
        
       const multiplePayload = this.empList.map((employee: any) => ({
          startDate: this.startdate1,
          endDate: this.enddate1,
          client: {
            clientId: this.clicentName
          },
          status: {
            listTypeValueId: 1 
          },
          shift: {
            shiftId: this.stroreShiftId 
          },
          employee: {
            employeeId: employee.employeeId 
          },
          locationDetails: {
            locationId: this.storeLocationId
          },
          organization:{
            organizationId:this.storeOrgId
          }
        }));
        console.log("payload mulitple",multiplePayload);
// return
         this.gs.assign_Employee_shifts(multiplePayload).subscribe((a: any) => {
        console.log(a);
      
        this.submitdisable=true
        if (Array.isArray(a)) {
          this.submitdisable=false;

          // Handle array response
          const errorMessages = a
              .filter((item: any) => item.statusCode === 409) // Filter responses with statusCode 409
              .map((item: any) => item.description); // Get the error description
  
          if (errorMessages.length > 0) {
              Swal.fire({
                  text: errorMessages.join(', '), // Display all error messages
                  icon: 'error',
                  timer: 3000
              });
              this.router.navigateByUrl('/shift');
              this.btndisable = false; // Enable button if there is an error
              return;
          }
      }
  
      if (a.statusCode === 200) {
        this.submitdisable=false;
  
          Swal.fire({
              text: a.description,
              icon: 'success',
              timer: 2000
          });
  
          setTimeout(() => {
              this.empList = []; // Clear employee list
              this.router.navigateByUrl('/shift');
              this.tableShow = false; // Hide the table if needed
          }, 2000);
      }
      })
        
        
        // Call API for multiple employees
       
      
      } else   {

        console.log("else if -----single one");
        this.selectedEmpIds.forEach((empl:any,index)=>{
      const  payload = {
          startDate: this.startdate1,
          endDate: this.enddate1,
          client: {
            clientId: this.clicentName
          },
          status: {
            listTypeValueId: 1 // Example value, can be dynamic
          },
          shift: {
            shiftId: this.stroreShiftId // Selected shift for the employee
          },
          employee: {
            employeeId: empl
          },
          locationDetails: {
            locationId: this.storeLocationId
          },
          organization:{
            organizationId:this.storeOrgId
          }

        };
        console.log("payload single",payload);

        
        
        // Call API for one employee
       
      

      // return
  
      this.gs.assign_OneEmployee_shift(payload).subscribe((a: any) => {
        console.log(a);
        this.submitdisable=true;

        if (a.statusCode == 200) {
          this.submitdisable=false;
          Swal.fire({
            text: a.description,
            icon: 'success',
            timer: 4000,
          });
          setTimeout(() => {
            this.router.navigateByUrl('/shift');
          }, 4000);
        } else if (a.statusCode == 409) {
          this.submitdisable=false;

          Swal.fire({
            text: a.description,
            icon: 'error',
            timer: 2000,
          });
          setTimeout(() => {
            // Any additional action you want after the error message
          }, 4000);
        }
      })
    })

    }
    

    }
   
   
    

  }
  enddate(event:any)
  {
    this.endDate_required=false;
    console.log("endDate",event.value);
    let endDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(endDate);
    this.endDate=endDate;
    this.enddate1=endDate
    console.log(this.endDate);
    if(this.endDate>=this.startDate)
    {
      this.endDate_error=false;
      console.log("vaild Date");
    }
    else
    {
      this.endDate_error=true;
      console.log("Invaild Date");
    }
  }
  startdate(event:any)
  {
    this.staDate_required=false;
    console.log("startDate",event.value);
    let startDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startDate=startDate;
    this.startdate1=startDate;
    console.log(this.startDate);
    console.log(this.endDate);
    if(this.endDate!=undefined)
    {
      console.log("End Date not null");
    if(this.endDate>=this.startDate)
    {
      this.endDate_error=false;
      console.log("vaild Date");
    }
    else
    {
      this.endDate_error=true;
      console.log("Invaild Date");
    }
   }

  }
}
