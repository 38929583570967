import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ViewTrackUserListComponent } from '../Components/view-track-user-list/view-track-user-list.component';
import { DatePipe } from '@angular/common';
import { UserLocationComponent } from '../Components/user-location/user-location.component';
import { ExcelService } from '../excel.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-track-user-report',
  templateUrl: './track-user-report.component.html',
  styleUrls: ['./track-user-report.component.scss'],

})
export class TrackUserReportComponent implements OnInit {
  reportForm: FormGroup;
  displayedColumns: string[] = ['userId', 'employeeId', 'empcontactNo', 'date', 'day', 'time', 'latitude', 'address', 'view'];
  dataSource = new MatTableDataSource;
  selection: any = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userlist: any = [];
  p: number = 5;
  // p:any;
  //  date: Date;
  user_required: boolean = false;
  startDate_required: boolean = false;
  staDate_required: boolean = false;
  endDate_required: boolean = false;
  endDate_error: boolean = false;
  starttime_required: boolean = false;
  endTimeValid: boolean = false;
  startTimeValid: boolean = false;
  endtime_required: boolean = false;
  user: any;
  startDate: any;
  endDate: any;
  stTime: any;
  edTime: any;
  userNameList: any = [];
  userData: any = [];
  filterData: any[] = [];
  nodata = false;
  totalDistance: string;
  distance: number;
  totDis: string;
  repostData: any = [];
  // pageNum: number = 1;
  mySelect: any = 5;
  polygonpoints: any = [];
  currentPageOffset: number = 0;
  // beforesearchpage: any;
  dataExport: any = [];
  exportingdata: any = [];
  exported: any;
  employeeNotFound: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  employeelistsub: any;
  employeeId: any;
  employee: any;
  @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;
  orgId: any;
  noData: boolean = false;

  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private datePipe: DatePipe,
    private es: ExcelService,
    public dialog: MatDialog,
    private datepipe: DatePipe) {
    this.reportForm;
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource = new MatTableDataSource(this.filterData);
    this.dataSource.paginator = this.paginator;
    this.noData = true;
  }


  ngOnInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.p = 1;

    let userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    let id = this.auth.getLocal_Org().organizationId
    this.orgId = this.auth.getLocal_Org().organizationId
    console.log(userdata.userId)
    this.reportForm = this.fb.group({
      user: [''],
      startdate: [''],
      enddate: [''],
      starttime: [''],
      endtime: ['']
    })
    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata.userId)
      this.gs.get_user_org(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (userdata.userType[0].userTypeName == "BUDDY USER") {
              if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                && e.buddyUser?.userId == userdata.userId) {
                console.log(e);
                this.userlist.push(e);
              }
            }
            else {
              if (e.userId != userdata.userId) {
                console.log(e)
                this.userlist.push(e);
              }
            }
          }
        )
      })
    } else {

      let id = this.auth.getLocalStorageData()?.userId;
      this.gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (e.userId != id) {
              //  console.log(e)
              this.userlist.push(e);
            }
          }
        )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    }


    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Ensure all fields (including phoneNumber) are considered in the filter string

      console.log('dataSource', data);

      const phoneNumber = data.user?.phoneNumber ? data.user.phoneNumber : '-';
      const employeeNumber = data.user?.employee?.employeeNumber || '';
      const name = data.user?.name || '';
      const date = data.date ? new Date(data.date).toLocaleDateString() : '';
      const day = data.day || '';
      const time = data.time || '';
      const address = data.address || '';
      const latitude = data.latitude || '';
      const longitude = data.longitude || '';

      const dataStr = `${phoneNumber} ${employeeNumber} ${name} ${date} ${day} ${time} ${address} ${latitude} ${longitude}`
        .toLowerCase()
        .trim();

      return dataStr.includes(filter.trim().toLowerCase());
    };

  }


  viewLocation() {
    console.log("overall Location:", this.filterData);
    this.repostData = this.filterData;
    console.log(this.repostData);
    const dialogRef = this.dialog.open(UserLocationComponent, {
      width: '800px',
      height: '500px',
      // data:this.repostData
      data:
      {
        latLongArray: this.repostData,
      }
    });
  }

  showmap(repData: any) {
    console.log("show client Location:", repData);
    // this.repostData.length = 0;
    this.repostData = [];
    // this.polygonpoints=[];
    //  arr.length = 0
    console.log(this.repostData);
    console.log(this.polygonpoints);
    this.repostData.push(repData);
    console.log(this.repostData);
    const dialogRef = this.dialog.open(UserLocationComponent, {
      width: '800px',
      height: '500px',
      //  data:this.repostData,
      data:
      {
        latLongArray: this.repostData,
        // pointsArray:this.polygonpoints
      }
    });
  }

  search() {
    this.endDate_required = false;
    this.staDate_required = false;
    console.log(this.reportForm);
    console.log(this.employeeId);
    console.log(this.employee);
    console.log("search");
    if ((this.reportForm.value.user == "" || this.reportForm.value.user == 0)) {
      console.log("user Invaild");
      this.user_required = true;
    }
    // else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate!=""&&this.reportForm.value.enddate==""))
    // {
    //   console.log("user Invaild in enddate");
    //   this.endDate_required=true;
    // }
    // else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate==""&&this.reportForm.value.enddate!=""))
    // {
    //   console.log("user Invaild in startDate");
    //   this.staDate_required=true;
    // }
    else if ((this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.startdate != null && (this.reportForm.value.enddate == "" || this.reportForm.value.enddate == null))) {
      console.log("user Invaild in enddate");
      this.endDate_required = true;
    }
    else if ((this.reportForm.value.user != "" && (this.reportForm.value.startdate == "" || this.reportForm.value.startdate == null) && this.reportForm.value.enddate != "" && this.reportForm.value.enddate != null)) {
      console.log("user Invaild in startDate");
      this.staDate_required = true;
    }
    else if ((this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime == "")) {
      console.log("user Invaild ebd Time");
      this.endtime_required = true;
    }
    else if ((this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime == "" && this.reportForm.value.endtime != "")) {
      console.log("user Invaild start Time");
      this.starttime_required = true;
    }
    else if ((this.reportForm.value.user == "" || this.reportForm.value.user == 0) && this.reportForm.value.startdate == "" && this.reportForm.value.enddate == "" && this.reportForm.value.starttime == "" && this.reportForm.value.endtime == "") {
      console.log("Required", this.user);
      this.user_required = true;
    }

    else if (this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime != "") {
      console.log("Both All Api", this.user);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate", date);
      var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate", stardate);
      var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
      console.log("enddate", enddate);
      let st = this.reportForm.value.starttime + ":00";
      console.log(this.stTime);
      let et = this.reportForm.value.endtime + ":59";
      console.log(et);
      this.gs.get_userandTimelist(stardate, enddate, st, et, this.user)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          this.filterData.push(data);
          // this.filterData = data;
          this.noData = true;
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          // user ploygon points Draw Api
          this.gs.get_usergeofencelist(this.user)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }

    else if (this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.startdate != null && this.reportForm.value.enddate != "" && this.reportForm.value.enddate != null) {
      console.log("user and Date Api", this.user);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate", date);
      var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate", stardate);
      var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
      console.log("enddate", enddate);
      this.gs.get_userandDatelist(stardate, enddate, this.user)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          // this.filterData = data;
          this.filterData.push(data);
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          this.noData = true;
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          this.gs.get_usergeofencelist(this.user)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              // this.userlist =0;
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
    else if (this.reportForm.value.user != "") {
      console.log("user Api", this.user);
      // this.polygonpoints=[];
      this.gs.get_userlist(this.user)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          // this.filterData = data;
          this.filterData.push(data);
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          this.gs.get_usergeofencelist(this.user)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            console.log(error);
            if (error.error.errorCode == 204) {
              //this.userlist =0;
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
  }

  exportToExcel() {
    this.dataExport.length = 0;
    this.exportingdata = this.filterData;
    console.log("this.exportingdata::", this.exportingdata);

    for (let element of this.exportingdata) {
      console.log(element);

      this.exported = {
        EmployeeName: element?.user?.name ? element.user.name : '',
        EmployeeNo: element.user?.employee?.employeeNumber ? element.user.employee.employeeNumber : '',
        EmpContactNo: element?.user?.phoneNumber ? element.user.phoneNumber : '',
        Date: element?.date ? this.datePipe.transform(element.date, 'yyyy-MM-dd') : '',
        Day: element?.day ? element.day : '',
        Time: element?.time ? element.time : '',
        Latitude: element?.latitude ? element.latitude : '',
        Longitude: element?.longitude ? element.longitude : '',
        Address: element?.address ? element.address : ''
      };

      this.dataExport.push(this.exported);
    }
    console.log("this.dataExport", this.dataExport);
    this.es.exportAsExcelFile(this.dataExport, 'Export');
  }

  isselected(event: any) {
    this.user_required = false;
    console.log("user", event.value);
    // this.submitted=false;
    this.user = event.value;
    console.log(this.user);
  }

  startdate(event: any) {
    this.staDate_required = false;
    console.log("startDate", event.value);
    let startDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startDate = startDate;
    console.log(this.startDate);
    console.log(this.endDate);
    if (this.startDate != undefined && this.endDate != undefined) {
      console.log("End Date not null");
      if (this.endDate >= this.startDate) {
        this.endDate_error = false;
        console.log("vaild Date");
      }
      else {
        this.endDate_error = true;
        console.log("Invaild Date");
      }
    }
  }

  enddate(event: any) {
    this.endDate_required = false;
    console.log("endDate", event.value);
    let endDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(endDate);
    this.endDate = endDate;
    console.log(this.endDate);
    if (this.startDate != undefined && this.endDate != undefined) {
      if (this.endDate >= this.startDate) {
        this.endDate_error = false;
        console.log("vaild Date");
      }
      else {
        this.endDate_error = true;
        console.log("Invaild Date");
      }
    }
  }


  // onPaginateChange(event:any){
  //   console.log((JSON.stringify("Current page index: " + event.pageIndex)));
  //   this.pageNum=event.pageIndex;
  //   console.log("this.pageNum",this.pageNum);
  // }

  //   startTime(event:any) {
  //   console.log(event);
  //   console.log(event.target.value);
  //  }
  //  viewStartTime(event:any)
  //  {
  //   console.log("click",event);
  //   console.log(event.target.value);
  //  }
  stime(event: any) {
    // this.starttime_required=false;
    console.log("startTime");
    console.log("startTime", event);

    console.log('stime', this.reportForm.controls['starttime'].value);

    this.stTime = event;
    this.starttime_required = false;
    console.log(this.stTime);
    if (this.stTime) {
      // this.endtime_required=false;
      this.starttime_required = false;
    }
    const startTime = this.reportForm.controls['starttime'].value;
    const endTime = this.reportForm.controls['endtime'].value;

    // Validate start and end time
    if (startTime && endTime) {
      const start = new Date(`1970-01-01T${startTime}:00`);
      const end = new Date(`1970-01-01T${endTime}:00`);

      if (start > end) {
        this.endTimeValid = true;
      } else {
        this.endTimeValid = false;
      }
    } else {
      // Handle case where start time is not set
      this.endTimeValid = false;
    }
  }

  endTime(event: any) {
    // this.endtime_required=false;
    console.log("endTime", event.value);
    this.edTime = event.value;
    this.endtime_required = false;
    if (this.edTime) {
      this.endtime_required = false;
    }
    console.log(this.edTime);


    const startTime = this.reportForm.controls['starttime'].value;
    const endTime = this.reportForm.controls['endtime'].value;

    // Validate start and end time
    if (startTime && endTime) {
      const start = new Date(`1970-01-01T${startTime}:00`);
      const end = new Date(`1970-01-01T${endTime}:00`);

      if (start > end) {
        this.endTimeValid = true;
      } else {
        this.endTimeValid = false;
      }
    } else {
      // Handle case where start time is not set
      this.endTimeValid = false;
    }

  }

  applyFilter(event: Event) {
    console.log('event', event);
    const filterValue = (event.target as HTMLInputElement).value;

    console.log('filterValue', filterValue);

    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Ensure all fields (including phoneNumber) are considered in the filter string

      console.log('dataSource', data);
      console.log('dataSource', filter);

      const phoneNumber = data.user?.phoneNumber ? data.user.phoneNumber : '-';
      const employeeNumber = data.user?.employee?.employeeNumber || '';
      const name = data.user?.name || '';
      const date = data.date ? new Date(data.date).toLocaleDateString() : '';
      const day = data.day || '';
      const time = data.time || '';
      const address = data.address || '';
      const latitude = data.latitude || '';
      const longitude = data.longitude || '';

      const dataStr = `${phoneNumber} ${employeeNumber} ${name} ${date} ${day} ${time} ${address} ${latitude} ${longitude}`
        .toLowerCase()
        .trim();

      return dataStr.includes(filter.trim().toLowerCase());
    };

  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }
  selectedUser() {
    console.log(this.selection.selected)
    const dialogRef = this.dialog.open(ViewTrackUserListComponent, {
      width: '750px',
      data: this.selection.selected,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  /* mat select */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: any) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      // console.log(x.selected.length)
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

  empSearch(event) {
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    let employeenumber = event.target.value;
    let userdata = this.auth.getLocalStorageData();
    // Employee List API
    if (employeenumber.length >= 2) {
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.gs.getemployeeseachbyEmpNoKeyword(employeenumber, this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.gs.getemployeeseachbyEmpNoKeyword(employeenumber, this.orgId))
        )
        .subscribe((result: any) => {
          console.log("result", result);
          this.userlist = [];

          if (result.statusCode) {
            console.log(result.statusCode);
            this.userlist = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            result.map(
              (e: any) => {
                if (userdata.userType[0].userTypeName == "BUDDY USER") {
                  if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                    && e.buddyUser?.userId == userdata.userId) {
                    console.log(e);
                    this.userlist.push(e);
                  }
                }
                else {
                  if (e.userId != userdata.userId) {
                    console.log(e)
                    this.userlist.push(e);
                  }
                }
              }
            )

            if (this.userlist.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }


        },
          (error: any) => {
            console.log(error.statusCode);
            this.userlist = [];
            this.employeeNotFound = true;
          }
        )
      // }
    }
    else {
      if (this.auth.getLocal_Org()?.organizationId) {
        let id = this.auth.getLocal_Org().organizationId;
        console.log(userdata.userId)
        if (this.employeelistsub) {
          this.employeelistsub.unsubscribe();
        }
        this.employeelistsub = this.gs.get_user_org(id)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.gs.get_user_org(id))
          )
          .subscribe((a: any) => {

            // this.gs.get_user_org(id).subscribe((a: any) => {
            console.log(a);
            this.userNameList = a;
            console.log(this.userNameList);
            this.userlist = [];
            a.map(
              (e: any) => {
                if (userdata.userType[0].userTypeName == "BUDDY USER") {
                  if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                    && e.buddyUser?.userId == userdata.userId) {
                    console.log(e);
                    this.userlist.push(e);
                  }
                }
                else {
                  if (e.userId != userdata.userId) {
                    console.log(e)
                    this.userlist.push(e);
                  }
                }
              }
            )
          },
            (error: any) => {
              console.log(error.statusCode);
              this.userlist = [];
              this.employeeNotFound = true;
            })
      } else {

        let id = this.auth.getLocalStorageData()?.userId;
        this.gs.get_primary_users_user(id).subscribe((a: any) => {
          a.map(
            (e: any) => {
              if (e.userId != id) {
                this.userlist.push(e);
              }
            }
          )
        })
      }
    }
  }

  disableEnter(event: Event) {
    if ((event as KeyboardEvent).key === 'Enter') {
      event.preventDefault();
    }
  }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.employeeId = undefined;
    this.employee = undefined;
    this.employeeNotFound = false;
  }

  empSelect(emp) {
    console.log(emp);
    this.employeeId = emp.userId;
    this.employee = `${emp.employee.employeeNumber} - ${emp.name}`;
    console.log(this.employee);
    this.reportForm.patchValue({
      user: this.employee
    });
    this.reportForm.get('user')?.clearValidators();
    this.user_required = false;
  }
  clickfunction() {
    console.log("clickfunction", this.userlist);
    this.userlist = this.userlist;
    this.autoTrigger.openPanel();
  }
  clear()
  {
  }
  search1() {

    console.log('All fields check', this.reportForm.value);
    var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
    var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
    this.user_required = false;
    this.endDate_required = false;
    this.staDate_required = false;
    this.endtime_required = false;
    this.starttime_required = false;
    console.log(this.reportForm);
    console.log(this.employeeId);
    console.log(this.employee);
    console.log("search");
    if ((this.reportForm.value.user == "" || this.reportForm.value.user == 0)) {
      console.log("user Invaild");
      this.user_required = true;
    }

    if (this.reportForm.value.user && enddate && stardate) {


      if (stardate > enddate) {
        alert('Please Select Valid  Date');
        return;
      }
    }
    const startTime = this.reportForm.controls['starttime'].value;
    const endTime = this.reportForm.controls['endtime'].value;

    // Time validation
    if (!startTime || !endTime) {
      // alert("Please provide both Start Time and End Time.");
      // return; // Exit the function if the time is not provided
    } else {
      const start = new Date(`1970-01-01T${startTime}:00`);
      const end = new Date(`1970-01-01T${endTime}:00`);

      if (start >= end) {
        alert("Start Time should be greater than End Time.");
        return; // Exit the function if the start time is not greater than end time
      }
    }

    if (this.employeeId == undefined || (this.reportForm.value.user !== this.employee)) {
      alert("Please select user");
    }
    if (this.employeeId == undefined || (this.reportForm.value.user !== this.employee)) {
      alert("Please select user");
    }


    // else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate!=""&&this.reportForm.value.enddate==""))
    // {
    //   console.log("user Invaild in enddate");
    //   this.endDate_required=true;
    // }
    // else if((this.reportForm.value.user!=""&&this.reportForm.value.startdate==""&&this.reportForm.value.enddate!=""))
    // {
    //   console.log("user Invaild in startDate");
    //   this.staDate_required=true;
    // }
    else if ((this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.startdate != null && (this.reportForm.value.enddate == "" || this.reportForm.value.enddate == null))) {
      console.log("user Invaild in enddate");
      this.endDate_required = true;
    }
    else if ((this.reportForm.value.user != "" && (this.reportForm.value.startdate == "" || this.reportForm.value.startdate == null) && this.reportForm.value.enddate != "" && this.reportForm.value.enddate != null)) {
      console.log("user Invaild in startDate");
      this.staDate_required = true;
    }
    else if ((this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime == "")) {
      console.log("user Invaild ebd Time");
      this.endtime_required = true;
    }
    else if ((this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime == "" && this.reportForm.value.endtime != "")) {
      console.log("user Invaild start Time");
      this.starttime_required = true;
    }
    else if ((this.reportForm.value.user == "" || this.reportForm.value.user == 0) && this.reportForm.value.startdate == "" && this.reportForm.value.enddate == "" && this.reportForm.value.starttime == "" && this.reportForm.value.endtime == "") {
      console.log("Required", this.user);
      this.user_required = true;
    }

    else if ((this.reportForm.value.user != "" || this.reportForm.value.user != 0) && this.reportForm.value.startdate == "" && this.reportForm.value.enddate == "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime == "") {
      console.log("Required", this.user);
      this.endtime_required = true;
    }

    else if ((this.reportForm.value.user != "" || this.reportForm.value.user != 0) && this.reportForm.value.startdate == "" && this.reportForm.value.enddate == "" && this.reportForm.value.starttime == "" && this.reportForm.value.endtime != "") {
      console.log("Required", this.user);
      this.starttime_required = true;
    }

    else if (this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime != "") {
      console.log("Both All Api", this.user);
      console.log("this.employeeId", this.employeeId);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate", date);
      var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate", stardate);
      var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
      console.log("enddate", enddate);
      let st = this.reportForm.value.starttime + ":00";
      console.log(this.stTime);
      let et = this.reportForm.value.endtime + ":59";
      console.log(et);

      this.gs.get_userandTimelist(stardate, enddate, st, et, this.employeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          this.filterData = data;
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          // user ploygon points Draw Api
          this.gs.get_usergeofencelist(this.employeeId)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }

    else if (this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.startdate != null && this.reportForm.value.enddate != "" && this.reportForm.value.enddate != null) {
      console.log("user and Date Api", this.user);
      console.log("this.employeeId", this.employeeId);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate", date);
      var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate", stardate);
      var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
      console.log("enddate", enddate);
      this.gs.get_userandDatelist(stardate, enddate, this.employeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          this.filterData = data;
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          this.gs.get_usergeofencelist(this.employeeId)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              // this.userlist =0;
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
    else if (this.reportForm.value.user != "" && this.reportForm.value.startdate == "" && this.reportForm.value.enddate == "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime != "") {
      console.log("user and Date Api", this.user);
      console.log("this.employeeId", this.employeeId);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate", date);
      var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate", stardate);
      var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
      let st = this.reportForm.value.starttime + ":00";
      console.log(this.stTime);
      let et = this.reportForm.value.endtime + ":59";
      console.log("enddate", enddate);
      this.gs.filterTrackReportsbyStartEndTimeanduserId(st, et, this.employeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          this.filterData = data;
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          this.gs.get_usergeofencelist(this.employeeId)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              // this.userlist =0;
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }

    else if (this.reportForm.value.user != "" && this.reportForm.value.startdate != "" && this.reportForm.value.enddate != "" && this.reportForm.value.starttime != "" && this.reportForm.value.endtime != "") {
      console.log("user and Date Api", this.user);
      console.log("this.employeeId", this.employeeId);
      // this.polygonpoints=[];
      var date = new Date(this.reportForm.value.enddate);
      date.setDate(date.getDate() + 1);
      console.log("eDate", date);
      var stardate = this.datePipe.transform(this.reportForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate", stardate);
      var enddate = this.datePipe.transform(this.reportForm.value.enddate, 'yyyy-MM-dd');
      let st = this.reportForm.value.starttime + ":00";
      console.log(this.stTime);
      let et = this.reportForm.value.endtime + ":59";
      console.log("enddate", enddate);
      this.gs.filterTrackReportsByDatesTimeUserId(stardate, enddate, st, et, this.employeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          this.filterData = data;
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          this.gs.get_usergeofencelist(this.employeeId)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              // this.userlist =0;
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
    else if (this.reportForm.value.user != "" && this.reportForm.value.starttime == "" && this.reportForm.value.endtime == ""
      && this.reportForm.value.startdate == "" && this.reportForm.value.enddate == ""
    ) {
      console.log("user Api", this.user);
      console.log("this.employeeId", this.employeeId);
      // this.polygonpoints=[];
      this.gs.get_userlist(this.employeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.distance = data[0].totalDistance;
          console.log(this.distance);
          this.totDis = this.distance.toString().slice(0, 6);
          console.log(this.totDis);
          this.totalDistance = this.totDis;
          console.log(this.totalDistance);
          this.nodata = false;
          this.filterData = data;
          console.log(this.filterData);
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
          this.gs.get_usergeofencelist(this.employeeId)
            .subscribe((data: any) => {
              console.log(data);
              if (data.errorCode == null) {
                console.log(data);
                this.polygonpoints = data;
                console.log(this.polygonpoints);
              }
              if (data.errorCode == 204) {
                console.log("204 No Data", this.filterData);
              }
            },
              (error) => {
                console.log("error");
                if (error.error.errorCode == 204) {
                  console.log("204 No Data", this.filterData);
                }
              })
        },
          (error) => {
            console.log("error");
            console.log(error);
            if (error.error.errorCode == 204) {
              //this.userlist =0;
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
  }
}
