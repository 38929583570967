<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading-wrapper">
    <div class="loading">
      <app-loading></app-loading>
      <br />
      <h1>Please Wait Loading...</h1>
    </div>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <div class="container">
    <div class="mat-elevation-z8">
      <form [formGroup]="detailsFilterForm">
        <div class="mat-elevation-z8" style="padding: 15px">
          <!-- <h2 class="pl-5 pt-3">Attendence Reports</h2> -->
          <div class="row" style="padding: 5px 5px 5px 5px">
            <!-- Adjusting the h2 tag to align properly -->
            <h2 class="pl-5 pt-3">Login and Logout Reports</h2>
          </div>
          <div *ngIf="userTypeId == 3; else secondary">
            <div class="row ml-3" style="padding: 5px 5px 5px 5px">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-label> Date</mat-label>
                  <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker1"
                    formControlName="date"
                    (dateChange)="sdate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <!-- <mat-error *ngIf="attendanceSearchForm.get('fromDate').hasError('required') && attendanceSearchForm.get('fromDate').touched">
                        Start Date is Required
                      </mat-error> -->
                </mat-form-field>
                <!-- <span class="error_msg" *ngIf="startDate">*Please select start date</span>   -->
                <!-- <span class="error_msg" >*Please select startdate</span>
                      <span class="error_msg" >*Please select vaild startdate</span>  -->
              </div>

              <div class="col-lg-1 col-md-1 col-sm-12 ml-3">
                <button
                  type="button"
                  class="btn btn-primary btm-sm"
                  style="position: relative; top: 10px; font-size: 14px"
                  (click)="searchFilters()"
                >
                  Search
                </button>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-12" style="margin-left: -3px;">
                <button
                  type="reset"
                  class="btn btn-primary btm-sm"
                  style="position: relative; top: 10px; font-size: 14px"
                  (click)="clear()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <ng-template #secondary>
            <div class="row ml-3" style="padding: 5px 5px 5px 5px">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-icon matSuffix>search</mat-icon>

                  <mat-label>Employee</mat-label>
                  <input
                    matInput
                    type="text"
                    matInput
                    (input)="validateInput($event)"
                    placeholder="Search Employee"
                    formControlName="employeeId"
                    [matAutocomplete]="auto"
                    (keydown)="handleBackspace($event)"
                    (keyup)="empSearch($event)"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let emp of searchEmployees"
                      [value]="emp?.employeeId"
                      (click)="empSelect(emp)"
                    >
                      <!-- {{ emp?.employeeNumber }} - {{ emp?.user?.name }}  -->
                      {{ emp?.employee?.employeeNumber }} - {{ emp?.name }}
                    </mat-option>
                    <!-- <mat-option *ngIf="employeeNotFound"  (click)="noEmployeeFound()"
                      >No Employee Found</mat-option
                    > -->
                    <mat-option
                      [value]="null"
                      *ngIf="employeeNotFound"
                      (click)="noEmployeeFound()"
                      >No user Found</mat-option
                    >
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <span class="error_msg" *ngIf="endDate">*Please select end date</span>
                    <span class="error_msg" *ngIf="greater">*Select Vaild End date</span>  -->
                <!-- <span class="error_msg" >*Please select enddate</span> 
                      <span class="error_msg" >Please select vaild enddate</span>  -->
                <!-- <span class="error_msg" >Please select end date</span> 
                      <span class="error_msg" >End date should be greater than start date</span>   -->
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-label> Date</mat-label>
                  <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker1"
                    formControlName="date"
                    (dateChange)="sdate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <!-- <mat-error *ngIf="attendanceSearchForm.get('fromDate').hasError('required') && attendanceSearchForm.get('fromDate').touched">
                        Start Date is Required
                      </mat-error> -->
                </mat-form-field>
                <!-- <span class="error_msg" *ngIf="startDate">*Please select start date</span>   -->
                <!-- <span class="error_msg" >*Please select startdate</span>
                      <span class="error_msg" >*Please select vaild startdate</span>  -->
              </div>

              <div class="col-lg-1 col-md-1 col-sm-12 ml-3">
                <button
                  type="button"
                  class="btn btn-primary btm-sm"
                  style="position: relative; top: 10px; font-size: 14px"
                  (click)="searchFilters()"
                >
                  Search
                </button>
              </div>

              <div class="col-lg-1 col-md-1 col-sm-12" style="margin-left: -3px;">
                <button
                  type="reset"
                  class="btn btn-primary btm-sm"
                  style="position: relative; top: 10px; font-size: 14px"
                  (click)="clear()"
                >
                  Clear
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </form>

      <!-- <div class="header_action">
       
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Search</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Search Employee Name" #input>
            </mat-form-field>
          </div> -->

      <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="locationId">
          <th mat-header-cell *matHeaderCellDef><b>S.No</b></th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ currentPageOffset + i + 1 }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef>
            <b>Employee Number/ Name</b>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.employee?.employeeNumber }} -
            {{ element.employee?.user?.name }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef><b>Login Time</b></th>
          <td mat-cell *matCellDef="let element">{{ element.loginTime }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <!-- <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container> -->

        <!-- Symbol Column -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef><b>Action</b></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              aria-label="Example icon-button with a menu"
              [matMenuTriggerFor]="beforeMenu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <div class="side_setting">
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <a mat-menu-item (click)="editLocation(element)">
                  <span
                    class="material-icons v"
                    style="
                      transform: translateY(7px);
                      font-size: 22px;
                      margin-right: 3px;
                    "
                    >edit</span
                  >
                  Edit Location</a
                >
                <a mat-menu-item (click)="viewaddress(element)">
                  <span
                    class="material-icons v"
                    style="
                      transform: translateY(7px);
                      font-size: 22px;
                      margin-right: 3px;
                    "
                    >visibility</span
                  >View Address</a
                >
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="login data">
          <th mat-header-cell *matHeaderCellDef><b>Login Date</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.loginDate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="logout">
          <th mat-header-cell *matHeaderCellDef><b>Logout Time</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.logoutTime ? element.logoutTime : "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="loginAddress">
          <th mat-header-cell *matHeaderCellDef><b>Login Address</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.loginAddress ? element.loginAddress : "--" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="logoutAddress">
          <th mat-header-cell *matHeaderCellDef><b>Logout Address</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.logoutAddress ? element.logoutAddress : "--" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            colspan="9"
            align="center"
            style="height: 20px; vertical-align: middle; padding: 0"
          >
            <div
              class="alert alert-secondary d-flex justify-content-center align-items-center"
              style="height: 100%; margin: 0"
            >
              <h3 style="font-size: 14px; margin: 0">No Data</h3>
            </div>
          </td>
        </tr>
      </table>
      <mat-paginator
        (page)="onPageChange($event)"
        [pageSizeOptions]="[5, 10, 20, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</ng-template>
