import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../Authenticaton/auth.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { ViewemployeeComponent } from '../viewemployee/viewemployee.component';

interface UserGeofence {
  userGeofenceId:number;
  status: {
    statusId: number;
    statusName: string;
  };
  user: {
    userId:number;
    name: string;
    // other user properties
  };
  geofence: {
    geofenaceId:number;
    geofenaceName: string;
    // other geofence properties
  };
}

@Component({
  selector: 'app-clientassign',
  templateUrl: './clientassign.component.html',
  styleUrls: ['./clientassign.component.scss']
})
export class ClientassignComponent implements OnInit {
  AssigngeofenceForm : FormGroup;
  displayedColumns: string[] = ['sno', 'clientCode','clientName','EmployeeName'];
  //  dataSource = new MatTableDataSource<UserGeofence>;
  // dataSource = new MatTableDataSource<any>([]); 
  dataSource = new MatTableDataSource; 
  // dataSource: MatTableDataSource<any>;
  selection: any = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator:MatPaginator;
  userlist :any = [];
  p: number = 1;
    user_required:boolean = false;
    startDate_required:boolean = false;
    staDate_required:boolean =false;
     endDate_required: boolean = false;
    endDate_error:boolean = false;
    starttime_required:boolean = false;
    endtime_required: boolean = false;
    user: any;
    startDate: any;
    endDate: any;
    stTime: any;
    edTime: any;
    userNameList: any=[];
    userData: any=[];
    filterData: any[]=[];
    nodata = false;
    totalDistance: string;
    distance: number;
    totDis: string;
    repostData: any=[];
    // pageNum: number = 1;
    mySelect:any =5;
    // userlist: any=[];
    geoList: any=[];
    success = false;
  val: any;
  currentPageOffset: number = 0;
  userId: number;
selectedEmployees: any;
allSelected:boolean=false;
@ViewChild('select') select: MatSelect;
  isDisabled: true;
  geofence: any;
  activeEmployeesData: any=[];
  clientList: any=[];
  storeClientId: any;
  storeOrgId: any;
  storeEmpNumber: any;
  employeeNotFound: boolean;
  employeelistsub: any;
  postdatamultiple: never[];
  private unsubscribe$: Subject<void> = new Subject<void>();
employee: any;
client: any;
  tableShow: boolean=false;
  disableFeildforAll: boolean;
  isClientSelected: boolean;
  employeeId: any;
  selectedEmployeeData: any[]=[];
  employees: any;
setBoolean: boolean=false;
  storeEmpId: any;
  storeFilterName: any;
  loader: boolean;

  constructor(private gs: GlobalserviceService, 
    private fb:FormBuilder, 
    private auth: AuthService,
     private router: Router, 
     private datePipe: DatePipe,
     public dialog: MatDialog,
     ) { }
     ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }
  ngOnInit(): void {
    let  userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    this.userId=userdata.userId;
console.log(this.userId);
this.storeOrgId=userdata.organization.organizationId
  console.log(this.storeOrgId);
  let id = this.auth.getLocal_Org().organizationId
  console.log(userdata .userId)
  this.AssigngeofenceForm = this.fb.group({
    client:[''],
    employee:['']
  })

  // this.gs.get_User_list().subscribe((data: any) => {
  //   console.log(data);
  //   this.clientList=data;
  //   console.log(this.clientList);
  // })
  if (this.auth.getLocal_Org()?.organizationId) {
    let id = this.auth.getLocal_Org().organizationId;
    console.log(userdata.userId)
    this.gs.get_user_org(id).subscribe((a: any) => {
       console.log(a);
       this.userNameList=a;
       console.log(this.userNameList);
      a.map(
        (e: any) => {
          if (userdata.userType[0].userTypeName == "BUDDY USER") {
            if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
              && e.buddyUser?.userId == userdata.userId) {
            // if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")) {
              console.log(e);
              this.userlist.push(e);
            // }
           }
          }
          else {
            if (e.userId != userdata.userId) {
              console.log(e)
              this.userlist.push(e);
            }
          }
        }
      )
      // this.dataSource = new MatTableDataSource(this.userlist);
      // this.dataSource.paginator = this.paginator;
      // console.log(a)
    })
  }

  if (this.auth.getLocal_Org()?.organizationId) {
    let id = this.auth.getLocal_Org().organizationId
    console.log(userdata .userId)
    this.gs.get_client_org(this.storeOrgId).subscribe((a: any) => {
         console.log(a);
         this.clientList=a;
        //  this.activeEmployeesData=this.clientList
         this.clientList = Array.isArray(a) ? a: [a];
         this.setBoolean=true;
         if(a.statusCode==209){
          this.setBoolean=false;
         }

        //  this.storeClientId=this.clientList.clientId;
        //  console.log(" this.storeClientId::", this.storeClientId);
      //   this.clientList.forEach((client: any) => {
      //     this.storeClientId = client.clientId; // or push to an array if you have multiple clientIds
      //     console.log("this.storeClientId::", this.storeClientId);
      // });
         
        //  this.userNameList=a;
        //  console.log(this.userNameList);
        //  a.map(
        //    (e: any)=>{
        //        if(userdata.userType[0].userTypeName == "BUDDY USER")
        //        {
        //         if(e.userId !=  userdata.userId && (e.userType[0].userTypeName == "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") ){
        //           console.log(e);
        //           this.clientList.push(e);
        //         }
        //        }
        //        else {
        //         if(e.userId !=  userdata.userId){
        //           console.log(e)
        //            this.clientList.push(e);
        //         }
        //        }
        //   }
        // )
      // this.dataSource = new MatTableDataSource(this.clientList);
      // this.dataSource.paginator = this.paginator;
      // console.log(a)
    })
  } else {
      
    let id = this.auth.getLocalStorageData()?.userId;
    this.gs.get_primary_users_user(this.storeOrgId).subscribe((a: any) => {
      a.map(
        (e: any)=>{
         if(e.userId != id){
         //  console.log(e)
            this.clientList.push(e);
         }
       }
     )
      // this.dataSource = new MatTableDataSource(this.clientList);
      // this.dataSource.paginator = this.paginator;
      // console.log(a)
    })
  }


  // this.gs.get_Geofence_list(this.userId).subscribe((d: any) => {
  //   console.log(d);
  //   this.geoList=d;
  //   console.log(this.geoList);
  // })
 
  this.gs.get_employee_byClient_list(this.storeOrgId).subscribe((d: any) => {
    console.log(d);
    this.geoList=d;
    this.geoList = Array.isArray(d) ? d: [d];
    console.log(this.geoList);
   
  })
  
  }

  isselecteduser(event:any)
  {
    this.storeClientId=event.value;
    // this.user_required=false;
    // console.log("user",event.value);
    // // this.submitted=false;
    // this.user=event.value;
    // console.log(this.user);
    // this.gs.get_client_org(this.storeOrgId).subscribe((a: any) => {
    //   console.log(a);
    //   this.clientList=a;
    //   this.clientList = Array.isArray(a) ? a : [a];

    // })
  }
  isselectedname(event:any)
  {

    //this.storeEmpId=event.value;
    console.log("this.storeEmp",this.storeEmpNumber);
    

  }
  EditGeofence(){

    

  }
  openDialog(clients: any[], type: string): void {
    this.dialog.open(AddressDialogComponent, {
      data: {
        clients: clients,
        type: type  // Pass the type (either 'address' or 'clientName')
      }
    });
  }
  individualCheckboxChanged() {
    const geofenceControl = this.AssigngeofenceForm.get('geofenceName');
  
    if (geofenceControl && geofenceControl.value) { // Adding null checks
      const selectedEmployeeIds = geofenceControl.value;
  
      if (selectedEmployeeIds.length === this.activeEmployeesData?.length && selectedEmployeeIds?.length !== 0) {
        this.allSelected = true;
        console.log("if entering inside option");
      } else {
        this.allSelected = false;
        console.log("else condition");
      }
    } else {
      console.log("Form control is null or undefined");
    }
  }
  search()
  {
    this.loader = true;

    // this.filterData=[]

    // this.geoList.forEach((employee: any) => {
    //  this.storeEmpId=employee.employeeNumber
    //   console.log(this.storeEmpNumber); // or do something with employeeNumber
    // });
    console.log("submit");
    console.log("client",this.AssigngeofenceForm.value.client);
    console.log("employee::",this.AssigngeofenceForm.value.employee);
    // if((this.AssigngeofenceForm.value.user==" "&&this.AssigngeofenceForm.value.geofenceName==" ")||(this.AssigngeofenceForm.value.user==0&&this.AssigngeofenceForm.value.geofenceName==0))
    // {
    //   console.log("user Invaild");
    //   alert("Please Select Any Field to Search!");
    //   return
    // }
    // console.log("client",this.client);
    // console.log("employee::",this.employee);
    
    
    if((this.AssigngeofenceForm.value.client==undefined || this.AssigngeofenceForm.value.client==''||this.AssigngeofenceForm.value.client==0)&& (this.AssigngeofenceForm.value.employee==undefined || this.AssigngeofenceForm.value.employee=='')){
      alert("Please choose any field to search!!");
      this.loader = false;
      return;
    }
     if((this.AssigngeofenceForm.value.client!==undefined || this.AssigngeofenceForm.value.client!==''||this.AssigngeofenceForm.value.client!==0) && (this.AssigngeofenceForm.value.employee==undefined || this.AssigngeofenceForm.value.employee==''))
    {
      this.filterData=[]
      console.log("Client only",);
      this.gs.get_Client_only(this.storeClientId)
  .subscribe((data: any) =>{
    console.log(data);
    // console.log(data.statusCode);
    this.filterData.push(data)
    this.tableShow=true;
        this.dataSource = new MatTableDataSource(this.filterData);
        this.dataSource.paginator = this.paginator;

        console.log("this.datasource:",this.dataSource);
        
        this.loader = false;
    // if (data.errorCode == null) {
    //   this.nodata = false;
    //   this.filterData = Array.isArray(data) ? data : []; 
    //   console.log(this.filterData);

    //   console.log(this.dataSource);
    //   this.dataSource.paginator = this.paginator;
    //   console.log(this.dataSource);
    // }

    if (data.statusCode == 204) {
      console.log("204 No Data", this.filterData);
      this.filterData = []; // Set filterData to an empty array
      console.log(this.filterData);
      this.nodata = true;
      // Uncomment if you want to refresh the table even when there's no data
      this.dataSource = new MatTableDataSource(this.filterData);
      console.log(this.dataSource);
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource);
    }
  },
  (error) =>{
    this.loader = false;
    console.log("error");
    if(error.error.errorCode==204){
      console.log("204 No Data",this.filterData);
      this.filterData.length=0;
      console.log(this.filterData);
      this.nodata = true;
      this.dataSource = new MatTableDataSource(this.filterData);
      console.log(this.dataSource);
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource);
    }
  })
}
    else if((this.AssigngeofenceForm.value.client==undefined || this.AssigngeofenceForm.value.client=='' ||this.AssigngeofenceForm.value.client==0) && (this.AssigngeofenceForm.value.employee!==undefined || this.AssigngeofenceForm.value.employee!==''))
    {
      this.filterData=[]

      console.log("employee only");
      this.gs.get_EmployeeOnly(this.storeEmpId)
  .subscribe((data: any) =>{
    console.log(data);
    console.log(data);
    this.filterData.push(data)
    this.tableShow=true;
    console.log("this.filtredata::",this.filterData);
    console.log('dataSource',this.dataSource.filteredData);
    // this.storeFilterName=this.filterData.clientName

console.log(" this.storeFilterName::",this.storeFilterName );
   
    
    this.dataSource = new MatTableDataSource( this.filterData);
    this.dataSource.paginator = this.paginator;
    // this.nodata=false;
    // this.filterData=data;
    // console.log(this.filterData);
    // this.dataSource = new MatTableDataSource(data);
    // console.log(this.dataSource);
    // this.dataSource.paginator = this.paginator;
    // console.log(this.dataSource);
    // if(data.errorCode==null)
    // {
    // this.nodata=false;
    // this.filterData=data;
    // console.log(this.filterData);
    // this.dataSource = new MatTableDataSource(data);
    // console.log(this.dataSource);
    // this.dataSource.paginator = this.paginator;
    // console.log(this.dataSource);
    // }
    if(data.errorCode==204){
      console.log("204 No Data",this.filterData);
      this.filterData.length=0;
      console.log(this.filterData);
      this.nodata = true;
      this.dataSource = new MatTableDataSource(this.filterData);
      console.log(this.dataSource);
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource);
    }
  },
  (error) =>{
    console.log("error");
    if(error.error.errorCode==204){
      console.log("204 No Data",this.filterData);
      this.filterData.length=0;
      console.log(this.filterData);
      this.nodata = true;
      this.dataSource = new MatTableDataSource(this.filterData);
      console.log(this.dataSource);
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource);
    }
  })
    }
    else if((this.AssigngeofenceForm.value.client!==undefined || this.AssigngeofenceForm.value.client!=='' ||this.AssigngeofenceForm.value.client!==0) && (this.AssigngeofenceForm.value.employee!==undefined || this.AssigngeofenceForm.value.employee!==''))
    {
      this.filterData=[]

      console.log("client and employe both");
      this.gs.get_ClientEmployee(this.storeClientId,this.storeEmpId)
      .subscribe((data: any) =>{
        console.log(data);
        this.filterData.push(data)
        this.tableShow=true;
        console.log("this.filtredata::",this.filterData);
        console.log('dataSource',this.dataSource.filteredData);
        // this.storeFilterName=this.filterData.clientName

   console.log(" this.storeFilterName::",this.storeFilterName );
       
        
        this.dataSource = new MatTableDataSource( this.filterData);
        this.dataSource.paginator = this.paginator;
console.log('dataSource',this.dataSource.filteredData);
// this.dataSource = new MatTableDataSource();
// this.dataSource = new MatTableDataSource(this.dataSource.filteredData);

        // if(data.errorCode==null)
        // {
        // this.nodata=false;
        // this.filterData=data;
        // console.log(this.filterData);
        // this.dataSource = new MatTableDataSource(this.filterData);
        // console.log(this.dataSource);
        // // this.dataSource.paginator = this.paginator;
        // console.log(this.dataSource);
        // }
        if(data.errorCode==204){
          console.log("204 No Data",this.filterData);
          this.filterData.length=0;
          console.log(this.filterData);
          this.nodata = true;
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
        }
      },
      (error) =>{
        console.log("error");
        if(error.error.errorCode==204){
          console.log("204 No Data",this.filterData);
          this.filterData.length=0;
          console.log(this.filterData);
          this.nodata = true;
          this.dataSource = new MatTableDataSource(this.filterData);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource);
        }
      })
    }
  }
  viewEmployees(element:any)
  {
    console.log('element',element);
    
    const dialogRef = this.dialog.open(ViewemployeeComponent, {
      disableClose: false,
      width: '700px',
      data : element
    });
  }
  // applyFilter(event: any) {
  //   console.log("search",event.target.value);
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   console.log("filterValue",filterValue);
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   console.log(this.dataSource);
  //   console.log("dataSource Filter",this.dataSource.filter);
  //   console.log(this.dataSource.filter.length);
  // }
//   applyFilter(searchTerm: string) 
//   {
//       searchTerm = searchTerm.toLowerCase();
//       this.dataSource.filter = searchTerm;
//     //  console.log(UserGeofence);
//     // this.filterData.forEach(element => {
//     //   console.log(element);
//     //   this.val=element;
//       // this.model(this.val);
//       this.dataSource.filterPredicate = (data: UserGeofence, filter: string) => {
//         const username = data.user.username.toLowerCase();
//         const geofenceName = data.geofence.geofenceName.toLowerCase();
//         return username.includes(filter) || geofenceName.includes(filter);
//       };
//   //  });
// }

applyFilter(event: any) {
  console.log("SEarch");
  const value= event.target.value.toLowerCase().trim();
  console.log(value);
 this.dataSource.filter = value;
 console.log(this.dataSource.filter);
//  console.log(filter);
//  this.dataSource.filterPredicate = (data: UserGeofence, filter: string) => {
//   const username = data.user.name.toLowerCase();
//   const geofenceName = data.geofence.geofenaceName.toLowerCase();
//   return username.includes(filter) || geofenceName.includes(filter);
// }

}
// model(val)
// {
//   interface UserGeofence {
//     userGeofenceId: number;
//     status: {
//       statusId: number;
//       statusName: string;
//     };
//     user: {
//       username: string;
//       // other user properties
//     };
//     geofence: {
//       geofenceName: string;
//       // other geofence properties
//     };
//   }
//   return UserGeofence;
// }
empSearch(event){ 
  // this.empolyeereadonly=false;
  this.postdatamultiple = [];
  this.employeeNotFound = false; // Reset the no employee flag
  console.log(event.target.value);
  let employeenumber = event.target.value;
  console.log(employeenumber);
  
  // Employee List API
  if(employeenumber.length >= 2){
    if(this.employeelistsub) {
      this.employeelistsub.unsubscribe();
    }
    this.employeelistsub = this.gs.getEmployeeNameList(event.target.value)
      .pipe(
        takeUntil(this.unsubscribe$), // Cancel previous request
        switchMap(() => this.gs.getEmployeeNameList(event.target.value))
      )
      .subscribe((result: any) => {
        this.geoList = [];
        
        if (result.statusCode) {
          console.log(result.statusCode);
          this.geoList = [];
          this.employeeNotFound = true; // Set the flag if status code indicates no employees
        } else {
          this.geoList = result;
          if (this.geoList.length === 0) {
            this.employeeNotFound = true; // Set the flag if the list is empty
          }
        }
      

      })
    }
  }
  empSelect(emp) { 
    this.disableFeildforAll = false;
    this.isClientSelected = false;

    console.log("emp",emp);
    this.tableShow = true;
    this.storeEmpId=emp.employee.employeeId;
    console.log("this.storeEmpNumber::",this.storeEmpId);
    

    this.employeeId = emp.employee.employeeId;
    this.employees= `${emp.employee.employeeNumber} - ${emp.name}`;
    console.log(this.employees);
    this.employee=this.employees
    console.log("this.employee::",this.employee);
    

    if (emp) {
        console.log("if emp came here!!!");

        const exists = this.selectedEmployeeData.find((e) => e.employee.employeeId === emp.employee.employeeId);

        if (!exists) {
            console.log("if not existed, came here!!!");

            // Add the selected employee to the array only if it does not exist
            this.selectedEmployeeData.push(emp);
        } 
        else {
            console.log("else already exists, came here!!!");
            // this.empolyeeapproverexist = true; // Show error message for existing employee
        }

        // Update the MatTableDataSource
        this.dataSource.data = [...this.selectedEmployeeData]; // Spread to trigger change detection
    }

    // Check if a client is selected
    if (this.client) {
        console.log("Client selected");
        this.isClientSelected = true; // Enable fields if a client is selected
        this.tableShow = true; // Show the table

        this.gs.get_employee_byClient_list(this.storeOrgId).subscribe((d: any) => {
            console.log('Employee response:', d);
            this.geoList = d;
            // this.btndisable = false;

            // Ensure the selected employee is added only once
            if (!this.selectedEmployeeData.find((e) => e.employee.employeeId === emp.employee.employeeId)) {
                this.selectedEmployeeData.push(emp);
            }

            // Update the data source
            this.dataSource.data = [...this.selectedEmployeeData]; // Spread operator to update MatTableDataSource
            console.log("this.datasource::", this.dataSource);
        });
    } else {
        console.log("No client selected");
        this.employee = null; // Clear employee field if no client is selected

        // Check if the employee is already selected
        const hasEmployee = this.selectedEmployeeData.some(e => e.employee.employeeId === emp.employee.employeeId);
        if (!hasEmployee) {
            this.selectedEmployeeData.push(emp); // Add employee to the selected list
            console.log("Updated selectedEmployeeData", this.selectedEmployeeData);
        } else {
            console.log("Employee already exists in the list");
        }

        // Ensure the data source for the table is updated
        this.dataSource.data = [...this.selectedEmployeeData]; // Update the data source for the table
    }

    // Uncomment if you want to manage validators
    // if (this.selectedEmployeeData.length >= 1) {
    //     this.employee?.clearValidators();
    // } else {
    //     this.employee?.setValidators([Validators.required]);
    // }
    // this.employee?.updateValueAndValidity();
}
noEmployeeFound() {
  this.employeeNotFound = false;
  this.employeeId = undefined;
  this.employee = undefined;
}
clearEmpArray(){
  this.geoList=[]
}
updateStatus(id:any,status:any)
{
console.log("Status",id,status);
 this.gs.updateAssignGeofenceStatus(id,status).subscribe( (result:any) =>
{
   if(result.statusCode == 200)
   {
    // this.submitted=false;
    Swal.fire({
      text:result.description,
      icon:'success',
      timer: 2000,
    })
    setTimeout(() => {
       this.router.navigateByUrl('/assign-geofence');
    }, 2000)
   this.search();
   }
   if(result.statusCode == 409){
    Swal.fire({
      text:result.description,
      icon:'error',
      timer: 2000
    })
    setTimeout(() => {
    }, 2000)
  }
  },
   err=>{
    console.log("Error");
    Swal.fire({
      text:err.error.description,
      icon:'error',
      timer: 2000
    })
    setTimeout(() => {
    }, 2000)
  }
 )
}

onPageChange(event: any) {
console.log("index",event.pageIndex);
console.log("size",event.pageSize);
this.currentPageOffset = event.pageIndex * event.pageSize;
}
selectgeofence(event:any)
  {
    console.log("geofence",event.value);
    this.geofence=event.value;
    console.log(this.geofence);
  }
// toggleAllSelection() {
//   if (this.allSelected) {
//     console.log("select");
//     this.select.options.forEach((item: MatOption) => item.select());
//    //  this.add();
//   } 
//   else {
//     console.log("unselect");
//     this.isDisabled=true;
//      } 
// }
toggleAllSelection() {
  if (this.allSelected) {
    this.select?.options.forEach((item: MatOption) => item.select());
    this.AssigngeofenceForm.controls.geofence?.setValue(this.geoList.map(e => e.employeeId));
  } else {
    this.select?.options.forEach((item: MatOption) => item.deselect());
    this.AssigngeofenceForm.controls.geofence?.setValue([]);
  }
}
onEmployeeSelectionChange(event,id){

}
}
