<div class="container">
    <div class="mat-elevation-z8">
        <form  [formGroup]="assignForm" (ngSubmit)="onSubmit()"> 
            <h2 class="pl-5 pt-3">Assign Geofence</h2>
            
            <div class="row pl-5" style="">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                            <!-- <mat-label>select assign type</mat-label> -->
                            <mat-select placeholder="Select Assign Type" (selectionChange)="assigntype($event)">
                                <mat-option value="1">Assign By User</mat-option>
                                <mat-option value="2">Assign By Geofence</mat-option>
                            </mat-select>
                        </mat-form-field>
                   </div>
            </div>
        <div *ngIf="type!=undefined">
            <div class="row pl-5 pr-5" *ngIf="type==1; else elseBlock">
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Start Date</mat-label> 
                        <input matInput  formControlName="startdate"  (dateChange)="startdate($event)" 
                          placeholder="Choose a Date"    
                           [matDatepicker]="picker1">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                      <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.startdate.invalid && validate.startdate.touched) || validate.startdate.dirty || submitted">
                        <span *ngIf="validate.startdate.errors?.required" class="text-danger">Please select start date</span>
                        <!-- <span *ngIf="" class="text-danger">Please select vaild start date</span> -->
                      </div>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>End Date</mat-label> 
                        <input matInput  formControlName="enddate"   (dateChange)="enddate($event)" 
                          placeholder ="Choose a Date"  [min]="startDate" 
                         [matDatepicker]="picker2">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.enddate.invalid && validate.enddate.touched) || validate.enddate.dirty || submitted">
                        <span *ngIf="validate.enddate.errors?.required" class="text-danger">Please select end date</span>
                        <span *ngIf="endDate_error" class="text-danger">Please select vaild end date</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label class="">User Name<span class="text-danger"></span></mat-label> 
                            <mat-select formControlName="user"
                            placeholder="Select Employee Name" #select multiple (selectionChange)="selectuser($event)">
                                <div class="select-all">
                                    <mat-checkbox
                                    [(ngModel)]="allSelected"
                                    [ngModelOptions]="{standalone: true}"
                                    type="checkbox"
                                    (change)="toggleAllSelection()">
                                    Select All</mat-checkbox>
                                </div>
                                <mat-option *ngFor="let user of userlist"  [value]="user.userId">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                    </mat-form-field> 
                    <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.user.invalid && validate.user.touched) || validate.user.dirty || submitted">
                        <span *ngIf="validate.user.errors?.required" class="text-danger">Please select user</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label class="">Choose GeofenceName<span class="text-danger"></span></mat-label> 
                            <mat-select formControlName="geofence"
                            placeholder="Select Employee Name"  (selectionChange)="geofenceselect($event)">
                             <mat-option *ngFor="let geofence of geoList"  [value]="geofence.geofenaceId">
                                {{geofence.geofenaceName}}
                            </mat-option>
                            </mat-select>
                    </mat-form-field> 
                    <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.geofence.invalid && validate.geofence.touched) || validate.geofence.dirty || submitted">
                        <span *ngIf="validate.geofence.errors?.required" class="text-danger">Please select geofencename</span>
                    </div>
                </div>
            </div>
            <ng-template #elseBlock>
            <div class="row pl-5 pr-5">
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Start Date</mat-label> 
                        <input matInput  formControlName="startdate" (dateChange)="startdate($event)"
                          placeholder="Choose a Date"    
                           [matDatepicker]="picker1">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                      <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.startdate.invalid && validate.startdate.touched) || validate.startdate.dirty || submitted">
                        <span *ngIf="validate.startdate.errors?.required" class="text-danger">Please select start date</span>
                        <!-- <span *ngIf="" class="text-danger">Please select vaild start date</span> -->
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>End Date</mat-label> 
                        <input matInput  formControlName="enddate" (dateChange)="enddate($event)" 
                          placeholder ="Choose a Date"  [min]="startDate"
                         [matDatepicker]="picker2">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.enddate.invalid && validate.enddate.touched) || validate.enddate.dirty || submitted">
                        <span *ngIf="validate.enddate.errors?.required" class="text-danger">Please select end date</span>
                        <span *ngIf="endDate_error" class="text-danger">Please select vaild end date</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label class="">User Name<span class="text-danger"></span></mat-label> 
                            <mat-select formControlName="user"
                            placeholder="Select Employee Name" (selectionChange)="userselect($event)">
                                <!-- <div class="select-all">
                                    <mat-checkbox
                                    [(ngModel)]="allSelected"
                                    [ngModelOptions]="{standalone: true}"
                                    type="checkbox"
                                    (change)="toggleAllSelection()">
                                    Select All</mat-checkbox>
                                </div> -->
                                <mat-option *ngFor="let user of userlist"  [value]="user.userId">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                    </mat-form-field> 
                    <div class="valid-msg"  style="position: relative; top: -15px;" *ngIf="(validate.user.invalid && validate.user.touched) || validate.user.dirty || submitted">
                        <span *ngIf="validate.user.errors?.required" class="text-danger">Please select user</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label class="">Choose GeofenceName<span class="text-danger"></span></mat-label> 
                            <mat-select formControlName="geofence"
                            placeholder="Select Employee Name" #select multiple (selectionChange)="selectgeofence($event)">
                                <div class="select-all">
                                    <mat-checkbox
                                    [(ngModel)]="allSelected"
                                    [ngModelOptions]="{standalone: true}"
                                    type="checkbox"
                                    (change)="toggleAllSelection()">
                                    Select All</mat-checkbox>
                                </div>
                                    <mat-option *ngFor="let geofence of geoList"  [value]="geofence.geofenaceId">
                                        {{geofence.geofenaceName}}
                                    </mat-option>
                            </mat-select>
                    </mat-form-field> 
                    <div class="valid-msg"  style="position: relative; top: -15px;" *ngIf="(validate.geofence.invalid && validate.geofence.touched) || validate.geofence.dirty || submitted">
                        <span *ngIf="validate.geofence.errors?.required" class="text-danger">Please select geofencename</span>
                    </div>
                </div>
            </div>
            </ng-template>
            <div class="row pb-3" style="display: flex; justify-content: flex-end;">
                <div class="col-md-2 float-right">
                    <button mat-stroked-button  [routerLink]="['/assign-geofence']" color="warn" style="width: 100%;" type="button"
                  mat-dialog-close>Cancel</button>
               </div>
                <div class="col-md-2 pl-5  float-right">
                    <button  mat-stroked-button color="warn" style="width: 100%;" type="submit" mat-button>
                    Create</button>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>
  