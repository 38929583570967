import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LiveTrackComponent } from '../Components/live-track/live-track.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../Components/user/user.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import { AuthService } from '../Authenticaton/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { element } from 'protractor';
import { log } from 'console';
// import * as moment from 'moment'; 
@Component({
  selector: 'app-attendance-reports',
  templateUrl: './attendance-reports.component.html',
  styleUrls: ['./attendance-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceReportsComponent implements OnInit {

  displayedColumns: string[] = ['userId', 'name', 'email', 'phoneNumber', 'userType', 'Action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  userlist: any = [];
  @ViewChild(MatPaginator) paginator: any;
  isLoading: boolean = false;
  currentPageOffset: number = 0;

  currentMonthDates: any[] = [];
  employeeData: any[] = [];
  // fromDate: any = '2024-09-30';
  // toDate: any = '2024-10-30';
  // fromDate: any;
  // toDate: any;
  otDates: any[] = [];
  otDatesDate: any[] = [];
  tableHeaders: any[] = [];
  tableDatas: any[] = [];

  normalDates:any[]=[];
  otStatusDates:any[]=[];
  attendanceList: any[] = [];

  normalstatuses:any[]=[];
  otnormalStatus:any[]=[];

  presentCount:number =0;
  attendancecounts:any[]=[];
  columnCounts: number[] = [];
  employees = [
    { id: 101, name: 'John Doe' },
    { id: 102, name: 'Jane Smith' },
    { id: 103, name: 'Bob Johnson' },
    { id: 104, name: 'Alice Brown' }
  ];
  fromDate:any;
  toDate:any;
  sMinDate:any;
  nodata: boolean=false;
  presentList: any=[];
  totalpresentList: any=[];
  otList: any=[];
  empotList:any=[];
  absentList: any=[];
  leaveList: any=[];
  empabsentList: any=[];
  empleaveList: any=[];
  maxDate: Date;
  emaxDate:Date;
  startDate: boolean=false;
  endDate: boolean=false;
  minDate: Date;
  userId: any;
  empList: any=[];
  OverAllEmpList: any;
  greater: boolean=false;
  endDate1: Date;
  orgId: any;
  monthMin: Date;
  monthMinDate:any;
  // toDate: string | null;
  constructor(public dialog: MatDialog, private gs: UserService,private datePipe:DatePipe,
    private Gs: GlobalserviceService, private auth: AuthService,private fb:FormBuilder) { }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  attendenceSearchform:FormGroup
  ngOnInit(): void {
    console.log(this.gs.userList);
     this.maxDate = new Date();  
     console.log(this.maxDate);
    //  this.maxDate.setMonth(this.maxDate.getMonth() - 6);
    //  const formattedMaxDate = this.maxDate.toISOString().split('T')[0];
    //  this.monthMin=new Date(formattedMaxDate);
    //  console.log(this.monthMin);
    //  this.monthMinDate= this.datePipe.transform(this.monthMin, 'YYYY-MM-dd');
    // //  this.monthMinDate=mon;
    //  console.log(this.monthMinDate);
     
    // this.attendenceSearchform = new FormGroup(
    //   {
    //     fromDate : new FormControl('',[Validators.required]),
    //     todate : new FormControl('',[Validators.required]),
    //   });
    this.attendenceSearchform = this.fb.group({
      fromDate: ['', [Validators.required]],
      todate: ['', [Validators.required]]  
    });
    
    // this.dataSource = this.gs.userList
    let userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    this.userId=userdata.userId;
    console.log("UserId",this.userId);
    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true
      let id = this.auth.getLocal_Org().organizationId
      console.log(id)
      this.Gs.get_user_org(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (userdata.userType[0].userTypeName == "BUDDY USER") {

              if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                && e.buddyUser?.userId == userdata.userId) {
                console.log(e)
                this.userlist.push(e);
              }
            } else {
              if (e.userId != userdata.userId) {
                console.log(e)
                this.userlist.push(e);
              }
            }

          }
        )
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a);
      })
    } else {
      let id = this.auth.getLocalStorageData()?.userId;
      this.Gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (e.userId != id) {
              //  console.log(e)
              this.userlist.push(e);
            }
          }
        )

        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a)
      }
        ,
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.description,
            "",
            'error'
          )
        })
    }
    // this.generateCurrentMonthDates();
    this.generateEmployeeData();
    // this.calculateColumnWiseCount();
  }
  sdate(sdate)
  {
    let startDate = this.datePipe.transform(sdate.target.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.fromDate=startDate;
    // this.attendenceSearchform.patchValue({
    //   toDate:null
    // })
    console.log(this.attendenceSearchform.controls);
    const fromDateControl = this.attendenceSearchform.get('fromDate');
    const toDateControl = this.attendenceSearchform.get('todate');
    console.log(fromDateControl?.value);
    console.log(toDateControl?.value);
    console.log(this.endDate1)
    if (fromDateControl?.value && toDateControl?.value) {
      // console.log("From Date is valid, but To Date is invalid.");
      if(this.endDate1>fromDateControl?.value)
      {
        console.log("From Date is valid, but To Date is invalid.");
        this.greater=true;
      }
    }
    if (this.fromDate === null && this.toDate === null) {
      console.log("Both fromDate and toDate are null");
      this.startDate = true;
      this.endDate = true;
    }
    else if (this.fromDate === null && this.toDate !== null) {
      console.log("fromDate is null, but toDate has a value");
      this.startDate = true;
      this.endDate = false;
    }
    else if (this.fromDate !== null && this.toDate === null) {
      console.log("fromDate has a value, but toDate is null");
      this.startDate = false;
      this.endDate = true;
    }
    else {
      console.log("Both fromDate and toDate have values");
      this.startDate = false;
      this.endDate = false;
    }
    const fromDate = new Date(this.fromDate); 
if (!isNaN(fromDate.getTime())) {  // Only proceed if it's a valid date
  const year = fromDate.getFullYear();
  const month = fromDate.getMonth();

  // Get the last day of the current month
  const endDate = new Date(year, month + 1, 0);  // Last day of the month

  console.log(`Start Date: ${fromDate}`);
  console.log(`End of Month: ${endDate}`);
  this.endDate1=endDate;
  this.minDate=fromDate;
  let pDate = new Date();  
   if(endDate>pDate)
   {
    this.emaxDate=pDate
   }
  else
  {
    this.emaxDate=endDate
   }
  }
 }

 clear()
 {
    console.log("Clear");
    this.attendanceList=[];
    this.startDate=false;
    this.endDate=false;
    this.nodata=false;
    this.greater=false;
    this.attendenceSearchform.reset();
 }
  edate(edate)
  {
    let endDate = this.datePipe.transform(edate.target.value, 'YYYY-MM-dd');
    console.log(endDate);
    this.greater=false;
    // this.sMinDate=endDate;
    this.toDate=endDate;
    if (this.fromDate === null && this.toDate === null) {
      console.log("Both fromDate and toDate are null");
      this.startDate = true;
      this.endDate = true;
    }
    else if (this.fromDate === null && this.toDate !== null) {
      console.log("fromDate is null, but toDate has a value");
      this.startDate = true;
      this.endDate = false;
    }
    else if (this.fromDate !== null && this.toDate === null) {
      console.log("fromDate has a value, but toDate is null");
      this.startDate = false;
      this.endDate = true;
    }
    else {
      console.log("Both fromDate and toDate have values");
      this.startDate = false;
      this.endDate = false;
    }
  }

  // calculateColumnWiseCount(): void {
    // const columnCount = this.tableDatas[0].length; // Assuming all rows have the same number of columns
    // console.log('columnCount',this.tableDatas);

    // Initialize columnCounts array with zeros
    // this.columnCounts = Array(columnCount).fill(0);

    // this.tableDatas.forEach((row) => {
    //   row.forEach((cell, colIndex) => {
    //     if (cell.status === 'P') {
    //       this.columnCounts[colIndex]++;
    //     }
    //   });
    // });

    // console.log('Column counts:', this.columnCounts); // Check the result in the console
  // }
  generateCurrentMonthDates() {
      console.log("Search");
      this.attendanceList=[];
      this.fromDate = this.datePipe.transform(this.attendenceSearchform.value.fromDate, 'yyyy-MM-dd')
      console.log("fromdate" + this.fromDate);
      this.toDate =this.datePipe.transform(this.attendenceSearchform.value.todate, 'yyyy-MM-dd')
      console.log("rodate" + this.toDate);
      console.log(this.attendenceSearchform);
      if (this.fromDate === null && this.toDate === null) {
        console.log("Both fromDate and toDate are null");
        this.startDate = true;
        this.endDate = true;
      }
      else if (this.fromDate === null && this.toDate !== null) {
        console.log("fromDate is null, but toDate has a value");
        this.startDate = true;
        this.endDate = false;
      }
      else if (this.fromDate !== null && this.toDate === null) {
        console.log("fromDate has a value, but toDate is null");
        this.startDate = false;
        this.endDate = true;
      }
      else {
        console.log("Both fromDate and toDate have values");
        this.startDate = false;
        this.endDate = false;
      }
      if (this.attendenceSearchform.invalid) {
        console.log("Invaild");
        return
      }
    this.employeeData=[];
    this.totalpresentList=[];
    this.empotList=[];
    this.empabsentList=[];
    this.empleaveList=[];
    this.otList=[];
    let userdata = this.auth.getLocalStorageData();
    console.log("userdata",userdata);
    this.orgId=userdata.organization.organizationId;
    console.log("orgId::",this.orgId);
    
    


    // this.Gs.attendanceReports(this.fromDate, this.toDate).subscribe((res: any) => {
      if (userdata.userType[0].userTypeName == "BUDDY USER"){
        console.log("buddy user logged here!!");
      this.Gs.attendanceReportsUser(this.fromDate, this.toDate,this.userId).subscribe((res: any) => {
      this.employeeData = res;
      this.nodata = false;
      console.log(res)
      if(res.statusCode==204)
      {
         console.log("No Data");
         this.nodata = true;
      }
      else
      {
      console.log(this.employeeData.length);
      this.normalDates=[];
      this.attendanceList=[];
      this.tableDatas=[];
      this.otStatusDates=[];
      res.forEach((headers: any) => {
        this.attendancecounts.push(headers.attendance_status.attendance);
        headers.attendance_status.attendance.forEach((head) => {
          if (head.date && !this.normalDates.some(item => item.date === head.date)) {
            var nDdate = {
              date: head.date,
              status: 'Day'  // Make sure 'status' is spelled correctly if it's used elsewhere
            };
            // Push only if the date doesn't already exist in normalDates
            this.normalDates.push(nDdate);
          }
        });
         console.log("normalDates",this.normalDates);
        // OtStatus
        headers.ot_status.OT_status.forEach((head) => {
    
          if (head.date&&head.status=="OT"&& !this.otStatusDates.some(item => item.date === head.date)) {
            this.otStatusDates.push(head);
          }
        });
      })
      console.log("otStatusDates",this.otStatusDates);
      this.otStatusDates.forEach((dates) => {
        if (dates && !this.normalDates.some(item => item.date === dates.date && item.status === "Day")
        ||dates && !this.normalDates.some(item => item.date === dates.date && item.status === "OT")) {
          this.normalDates.push(dates);
        }
      });
      console.log("normalDates Push",this.normalDates);
        const sortedArray = this.normalDates.sort((a, b) => {
          // Convert date strings to Date objects for comparison
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        
      console.log('SoRT normalDates',this.normalDates);
      this.employeeData.forEach((headers: any) => {

        headers.attendance_status.attendance.forEach((head) => {
          // Push `head.date` to `normalDates` if it exists and is not already in `normalDates`
          if (head.date && !this.normalDates.includes(head.date)) {

            var statuses = {
              date:head.date,
              status:head.status
            }
            this.normalstatuses.push(statuses);
          }
          // Push `head.ot_date` to `otStatusDates` if it exists, is not 'NULL', and is not already in `otStatusDates`
          if (head.ot_date && head.ot_status != "NULL" && !this.otStatusDates.includes(head.ot_date)) {
            this.otStatusDates.push(head.ot_date);
          }
        });
      })
    


      this.attendanceList = [];
      this.employeeData.forEach(element => {
        this.empList=[];
            this.normalDates.forEach((headers:any)=>
              {
                 if(headers.status=="Day")
                 {
                  element.attendance_status.attendance.forEach((empworkStatus:any)=>
                    {
                         if(empworkStatus.date==headers.date)
                         {
                          if(empworkStatus.date)
                          {
                            var empStatus=
                            {
                              status:empworkStatus.status
                            }
                            this.empList.push(empStatus);
                          }
                         }
                    })
                 }
                 if(headers.status=="OT")
                  {
                    element.ot_status.OT_status.forEach((otStatus:any)=>
                     {
                          if(otStatus.date==headers.date)
                          {
                           if(otStatus.status=="OT")
                           {
                             var otempStatus=
                             {
                               status:otStatus.status
                             }
                             this.empList.push(otempStatus);
                           }
                           else{
                            var otempStatus1=
                            {
                              status:"0"
                            }
                            this.empList.push(otempStatus1);
                           }
                          }
                     })
                  }
              })
              console.log("empList",this.empList);
        this.tableDatas = [];
        var totalCounts = {
          present:element.attendance_status.totalCounts.present,
          absent: element.attendance_status.totalCounts.absent,
          Leave: element.attendance_status.totalCounts.Leave,
          otcount: element.attendance_status.totalCounts.otcount
        }
        var emp = {
          empid: element.employee_number,
          empname: element.employee_name ? element.employee_name : '-',
          position: element.designation_name,
          attendance: this.tableDatas,
          totalcounts:totalCounts,
          total_worked_hours:element.total_worked_hours,
          empData:this.empList
        }
        // this.attendanceList.push(emp);
        element.attendance_status.attendance.filter((head) => {
          if (head.status   && !this.tableDatas.includes(head.date)) {
            var attendance = {
              date: head.date,
              status: head.status,
             
            }
            this.tableDatas.push(attendance);
          }

          if (head.ot_status   && !this.tableDatas.includes(head.ot_date)) {
            var attendance3 = {
              otdate: head.ot_date ,
              otstatus:  head.ot_status === "NULL" ? '-' : head.ot_status
            }

            this.tableDatas.push(attendance3);
          }
          console.log('tableDatas', this.tableDatas);
        })
        this.attendanceList.push(emp);
      });
      console.log('attendanceList', this.attendanceList);
       
      res.forEach(element => {
        element.attendance_status.attendance.forEach(date=> {
          // console.log(date);
          // this.totalpresentList=[];
          this.presentList=[];
          this.absentList=[];
          this.leaveList=[];
          this.otList=[];
          res.forEach(emp => {
            emp.attendance_status.attendance.forEach(empDate => {
            if (date.date == empDate.date && empDate.status =='P') {
                this.presentList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
            }
            if (date.date == empDate.date && empDate.status =='A') {
                this.absentList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
            }
            if (date.date == empDate.date && empDate.status =='L') {
                this.leaveList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
            }
            if (date.ot_date == empDate.ot_date && empDate.ot_status=='OT') {
                this.otList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
            }
          });
          // console.log(this.presentList);
        });
        this.normalDates.forEach((headers:any)=>
        {
          if(headers.status=="Day")
          {
            // presentList
            if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date))
              {
                // console.log("Day",date.date);
                if(headers.status=="Day"&&headers.date==date.date)
                {
                var count = {
                  date: date.date,
                  countData: this.presentList.length ? this.presentList.length : '0',
                  // status:date.status
                };
                this.totalpresentList.push(count);
              }
              }
              // Absent List
              if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date)) {
                if(headers.status=="Day"&&headers.date==date.date)
                  {
                    var count = {
                      date: date.date,
                      countData: this.absentList.length ? this.absentList.length : '0'
                    };
                    
                    this.empabsentList.push(count);
                  }
              }
              // LeaveList
              if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date))
                {
                  if(headers.status=="Day"&&headers.date==date.date)
                    {
                      var count = {
                        date: date.date,
                        countData: this.leaveList.length ? this.leaveList.length : '0'
                      };
                      this.empleaveList.push(count);
                    }  
                }
                // OT List
                if (this.otList.length >= 0 && !this.empotList.some(item => item.otdate === date.ot_date)) {
                  if(headers.status=="Day"&&headers.date==date.ot_date)
                  {
                    var otcount = {
                      date: date.ot_date,
                      otcountData:'0'
                      // otcountData: this.otList.length
                    };
                    const isDuplicate = this.empotList.some(item => item.date === date.ot_date);
                    if (!isDuplicate) {
                      this.empotList.push(otcount);
                    }
                  }
                  
                }
          }
          if(headers.status=="OT")
          {
            // Present Ot
              if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date&&item.status==="OT"))
                {
                  // console.log("Day",date.date);
                  if(headers.status=="OT"&&headers.date==date.date)
                  {
                  var count1 = {
                    date: date.date,
                    countData:'0',
                    status:'OT'
                  };
                  this.totalpresentList.push(count1);
                  }
                }
            // Absent Ot
            if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date&&item.status==="OT")) {
              if(headers.status=="OT"&&headers.date==date.date)
                {
                  var count1 = {
                    date: date.date,
                    countData:'0',
                    status:'OT'
                  };
                  this.empabsentList.push(count1);
                }
            }
            // Leave Ot
            if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date&&item.status==="OT"))
              {
                if(headers.status=="OT"&&headers.date==date.date)
                  {
                    var count1 = {
                      date: date.date,
                      countData:'0',
                      status:'OT'
                    };
                    this.empleaveList.push(count1);
                  }
              }
              //Ot List
              if (this.otList.length >= 0 && !this.empotList.some(item => item.otdate === date.ot_date&&item.status==="OT")) {
                if(headers.status=="OT"&&headers.date==date.ot_date)
                {
                  var otcount1 = {
                    // date: date.ot_date,
                    // countData:'0',
                    date: date.ot_date,
                    otcountData: this.otList.length,
                    status:'OT'
                  };
                  // console.log("otcount1",otcount1)
                  const isDuplicate = this.empotList.some(item => item.date === date.ot_date&&item.status==="OT");
                  if (!isDuplicate) {
                    this.empotList.push(otcount1);
                  }
                }
              } 
          }
        })
        console.log("PList",this.totalpresentList);
        console.log("AList",this.empabsentList);
        console.log("LList",this.empleaveList);
        console.log("OTList",this.empotList);
      });
    })
      }
    },
     (error) => {
      // this.loader= false;
      console.log("error");
      if(error.statusCode == 204){
        this.nodata = true;
        console.log("404")
      }
    }
  );
}
else if(userdata.userType[0].userTypeName == "PRIMARY USER"){
  console.log("primary user logged attendence!!");
  
  this.Gs.attendanceReportsPrimary(this.fromDate, this.toDate,this.orgId).subscribe((res: any) => {
    this.employeeData = res;
    this.nodata = false;
    console.log(res)
    if(res.statusCode==204)
    {
       console.log("No Data");
       this.nodata = true;
    }
    else
    {
    console.log(this.employeeData.length);
    this.normalDates=[];
    this.attendanceList=[];
    this.tableDatas=[];
    this.otStatusDates=[];
    res.forEach((headers: any) => {
      this.attendancecounts.push(headers.attendance_status.attendance);
      headers.attendance_status.attendance.forEach((head) => {
        if (head.date && !this.normalDates.some(item => item.date === head.date)) {
          var nDdate = {
            date: head.date,
            status: 'Day'  // Make sure 'status' is spelled correctly if it's used elsewhere
          };
          // Push only if the date doesn't already exist in normalDates
          this.normalDates.push(nDdate);
        }
      });
       console.log("normalDates",this.normalDates);
      // OtStatus
      headers.ot_status.OT_status.forEach((head) => {
  
        if (head.date&&head.status=="OT"&& !this.otStatusDates.some(item => item.date === head.date)) {
          this.otStatusDates.push(head);
        }
      });
    })
    console.log("otStatusDates",this.otStatusDates);
    this.otStatusDates.forEach((dates) => {
      if (dates && !this.normalDates.some(item => item.date === dates.date && item.status === "Day")
      ||dates && !this.normalDates.some(item => item.date === dates.date && item.status === "OT")) {
        this.normalDates.push(dates);
      }
    });
    console.log("normalDates Push",this.normalDates);
      const sortedArray = this.normalDates.sort((a, b) => {
        // Convert date strings to Date objects for comparison
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      
    console.log('SoRT normalDates',this.normalDates);
    this.employeeData.forEach((headers: any) => {

      headers.attendance_status.attendance.forEach((head) => {
        // Push `head.date` to `normalDates` if it exists and is not already in `normalDates`
        if (head.date && !this.normalDates.includes(head.date)) {

          var statuses = {
            date:head.date,
            status:head.status
          }
          this.normalstatuses.push(statuses);
        }
        // Push `head.ot_date` to `otStatusDates` if it exists, is not 'NULL', and is not already in `otStatusDates`
        if (head.ot_date && head.ot_status != "NULL" && !this.otStatusDates.includes(head.ot_date)) {
          this.otStatusDates.push(head.ot_date);
        }
      });
    })
  


    this.attendanceList = [];
    this.employeeData.forEach(element => {
      this.empList=[];
          this.normalDates.forEach((headers:any)=>
            {
               if(headers.status=="Day")
               {
                element.attendance_status.attendance.forEach((empworkStatus:any)=>
                  {
                       if(empworkStatus.date==headers.date)
                       {
                        if(empworkStatus.date)
                        {
                          var empStatus=
                          {
                            status:empworkStatus.status
                          }
                          this.empList.push(empStatus);
                        }
                       }
                  })
               }
               if(headers.status=="OT")
                {
                  element.ot_status.OT_status.forEach((otStatus:any)=>
                   {
                        if(otStatus.date==headers.date)
                        {
                         if(otStatus.status=="OT")
                         {
                           var otempStatus=
                           {
                             status:otStatus.status
                           }
                           this.empList.push(otempStatus);
                         }
                         else{
                          var otempStatus1=
                          {
                            status:"0"
                          }
                          this.empList.push(otempStatus1);
                         }
                        }
                   })
                }
            })
            console.log("empList",this.empList);
      this.tableDatas = [];
      var totalCounts = {
        present:element.attendance_status.totalCounts.present,
        absent: element.attendance_status.totalCounts.absent,
        Leave: element.attendance_status.totalCounts.Leave,
        otcount: element.attendance_status.totalCounts.otcount
      }
      var emp = {
        empid: element.employee_number,
        empname: element.employee_name ? element.employee_name : '-',
        position: element.designation_name,
        attendance: this.tableDatas,
        totalcounts:totalCounts,
        total_worked_hours:element.total_worked_hours,
        empData:this.empList
      }
      // this.attendanceList.push(emp);
      element.attendance_status.attendance.filter((head) => {
        if (head.status   && !this.tableDatas.includes(head.date)) {
          var attendance = {
            date: head.date,
            status: head.status,
           
          }
          this.tableDatas.push(attendance);
        }

        if (head.ot_status   && !this.tableDatas.includes(head.ot_date)) {
          var attendance3 = {
            otdate: head.ot_date ,
            otstatus:  head.ot_status === "NULL" ? '-' : head.ot_status
          }

          this.tableDatas.push(attendance3);
        }
        console.log('tableDatas', this.tableDatas);
      })
      this.attendanceList.push(emp);
    });
    console.log('attendanceList', this.attendanceList);
     
    res.forEach(element => {
      element.attendance_status.attendance.forEach(date=> {
        // console.log(date);
        // this.totalpresentList=[];
        this.presentList=[];
        this.absentList=[];
        this.leaveList=[];
        this.otList=[];
        res.forEach(emp => {
          emp.attendance_status.attendance.forEach(empDate => {
          if (date.date == empDate.date && empDate.status =='P') {
              this.presentList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
          if (date.date == empDate.date && empDate.status =='A') {
              this.absentList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
          if (date.date == empDate.date && empDate.status =='L') {
              this.leaveList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
          if (date.ot_date == empDate.ot_date && empDate.ot_status=='OT') {
              this.otList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
        });
        // console.log(this.presentList);
      });
      this.normalDates.forEach((headers:any)=>
      {
        if(headers.status=="Day")
        {
          // presentList
          if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date))
            {
              // console.log("Day",date.date);
              if(headers.status=="Day"&&headers.date==date.date)
              {
              var count = {
                date: date.date,
                countData: this.presentList.length ? this.presentList.length : '0',
                // status:date.status
              };
              this.totalpresentList.push(count);
            }
            }
            // Absent List
            if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date)) {
              if(headers.status=="Day"&&headers.date==date.date)
                {
                  var count = {
                    date: date.date,
                    countData: this.absentList.length ? this.absentList.length : '0'
                  };
                  
                  this.empabsentList.push(count);
                }
            }
            // LeaveList
            if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date))
              {
                if(headers.status=="Day"&&headers.date==date.date)
                  {
                    var count = {
                      date: date.date,
                      countData: this.leaveList.length ? this.leaveList.length : '0'
                    };
                    this.empleaveList.push(count);
                  }  
              }
              // OT List
              if (this.otList.length >= 0 && !this.empotList.some(item => item.otdate === date.ot_date)) {
                if(headers.status=="Day"&&headers.date==date.ot_date)
                {
                  var otcount = {
                    date: date.ot_date,
                    otcountData:'0'
                    // otcountData: this.otList.length
                  };
                  const isDuplicate = this.empotList.some(item => item.date === date.ot_date);
                  if (!isDuplicate) {
                    this.empotList.push(otcount);
                  }
                }
                
              }
        }
        if(headers.status=="OT")
        {
          // Present Ot
            if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date&&item.status==="OT"))
              {
                // console.log("Day",date.date);
                if(headers.status=="OT"&&headers.date==date.date)
                {
                var count1 = {
                  date: date.date,
                  countData:'0',
                  status:'OT'
                };
                this.totalpresentList.push(count1);
                }
              }
          // Absent Ot
          if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date&&item.status==="OT")) {
            if(headers.status=="OT"&&headers.date==date.date)
              {
                var count1 = {
                  date: date.date,
                  countData:'0',
                  status:'OT'
                };
                this.empabsentList.push(count1);
              }
          }
          // Leave Ot
          if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date&&item.status==="OT"))
            {
              if(headers.status=="OT"&&headers.date==date.date)
                {
                  var count1 = {
                    date: date.date,
                    countData:'0',
                    status:'OT'
                  };
                  this.empleaveList.push(count1);
                }
            }
            //Ot List
            if (this.otList.length >= 0 && !this.empotList.some(item => item.otdate === date.ot_date&&item.status==="OT")) {
              if(headers.status=="OT"&&headers.date==date.ot_date)
              {
                var otcount1 = {
                  // date: date.ot_date,
                  // countData:'0',
                  date: date.ot_date,
                  otcountData: this.otList.length,
                  status:'OT'
                };
                // console.log("otcount1",otcount1)
                const isDuplicate = this.empotList.some(item => item.date === date.ot_date&&item.status==="OT");
                if (!isDuplicate) {
                  this.empotList.push(otcount1);
                }
              }
            } 
        }
      })
      console.log("PList",this.totalpresentList);
      console.log("AList",this.empabsentList);
      console.log("LList",this.empleaveList);
      console.log("OTList",this.empotList);
    });
  })
    }
  },
   (error) => {
    // this.loader= false;
    console.log("error");
    if(error.statusCode == 204){
      this.nodata = true;
      console.log("404")
    }
  }
);
  
}
else{
  console.log("secondary user logged here!!");
  this.Gs.attendanceReportsSecondary(this.fromDate, this.toDate,this.userId).subscribe((res: any) => {
    this.employeeData = res;
    this.nodata = false;
    console.log(res)
    if(res.statusCode==204)
    {
       console.log("No Data");
       this.nodata = true;
    }
    else
    {
    console.log(this.employeeData.length);
    this.normalDates=[];
    this.attendanceList=[];
    this.tableDatas=[];
    this.otStatusDates=[];
    res.forEach((headers: any) => {
      this.attendancecounts.push(headers.attendance_status.attendance);
      headers.attendance_status.attendance.forEach((head) => {
        if (head.date && !this.normalDates.some(item => item.date === head.date)) {
          var nDdate = {
            date: head.date,
            status: 'Day'  // Make sure 'status' is spelled correctly if it's used elsewhere
          };
          // Push only if the date doesn't already exist in normalDates
          this.normalDates.push(nDdate);
        }
      });
       console.log("normalDates",this.normalDates);
      // OtStatus
      headers.ot_status.OT_status.forEach((head) => {
  
        if (head.date&&head.status=="OT"&& !this.otStatusDates.some(item => item.date === head.date)) {
          this.otStatusDates.push(head);
        }
      });
    })
    console.log("otStatusDates",this.otStatusDates);
    this.otStatusDates.forEach((dates) => {
      if (dates && !this.normalDates.some(item => item.date === dates.date && item.status === "Day")
      ||dates && !this.normalDates.some(item => item.date === dates.date && item.status === "OT")) {
        this.normalDates.push(dates);
      }
    });
    console.log("normalDates Push",this.normalDates);
      const sortedArray = this.normalDates.sort((a, b) => {
        // Convert date strings to Date objects for comparison
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      
    console.log('SoRT normalDates',this.normalDates);
    this.employeeData.forEach((headers: any) => {

      headers.attendance_status.attendance.forEach((head) => {
        // Push `head.date` to `normalDates` if it exists and is not already in `normalDates`
        if (head.date && !this.normalDates.includes(head.date)) {

          var statuses = {
            date:head.date,
            status:head.status
          }
          this.normalstatuses.push(statuses);
        }
        // Push `head.ot_date` to `otStatusDates` if it exists, is not 'NULL', and is not already in `otStatusDates`
        if (head.ot_date && head.ot_status != "NULL" && !this.otStatusDates.includes(head.ot_date)) {
          this.otStatusDates.push(head.ot_date);
        }
      });
    })
  


    this.attendanceList = [];
    this.employeeData.forEach(element => {
      this.empList=[];
          this.normalDates.forEach((headers:any)=>
            {
               if(headers.status=="Day")
               {
                element.attendance_status.attendance.forEach((empworkStatus:any)=>
                  {
                       if(empworkStatus.date==headers.date)
                       {
                        if(empworkStatus.date)
                        {
                          var empStatus=
                          {
                            status:empworkStatus.status
                          }
                          this.empList.push(empStatus);
                        }
                       }
                  })
               }
               if(headers.status=="OT")
                {
                  element.ot_status.OT_status.forEach((otStatus:any)=>
                   {
                        if(otStatus.date==headers.date)
                        {
                         if(otStatus.status=="OT")
                         {
                           var otempStatus=
                           {
                             status:otStatus.status
                           }
                           this.empList.push(otempStatus);
                         }
                         else{
                          var otempStatus1=
                          {
                            status:"0"
                          }
                          this.empList.push(otempStatus1);
                         }
                        }
                   })
                }
            })
            console.log("empList",this.empList);
      this.tableDatas = [];
      var totalCounts = {
        present:element.attendance_status.totalCounts.present,
        absent: element.attendance_status.totalCounts.absent,
        Leave: element.attendance_status.totalCounts.Leave,
        otcount: element.attendance_status.totalCounts.otcount
      }
      var emp = {
        empid: element.employee_number,
        empname: element.employee_name ? element.employee_name : '-',
        position: element.designation_name,
        attendance: this.tableDatas,
        totalcounts:totalCounts,
        total_worked_hours:element.total_worked_hours,
        empData:this.empList
      }
      // this.attendanceList.push(emp);
      element.attendance_status.attendance.filter((head) => {
        if (head.status   && !this.tableDatas.includes(head.date)) {
          var attendance = {
            date: head.date,
            status: head.status,
           
          }
          this.tableDatas.push(attendance);
        }

        if (head.ot_status   && !this.tableDatas.includes(head.ot_date)) {
          var attendance3 = {
            otdate: head.ot_date ,
            otstatus:  head.ot_status === "NULL" ? '-' : head.ot_status
          }

          this.tableDatas.push(attendance3);
        }
        console.log('tableDatas', this.tableDatas);
      })
      this.attendanceList.push(emp);
    });
    console.log('attendanceList', this.attendanceList);
     
    res.forEach(element => {
      element.attendance_status.attendance.forEach(date=> {
        // console.log(date);
        // this.totalpresentList=[];
        this.presentList=[];
        this.absentList=[];
        this.leaveList=[];
        this.otList=[];
        res.forEach(emp => {
          emp.attendance_status.attendance.forEach(empDate => {
          if (date.date == empDate.date && empDate.status =='P') {
              this.presentList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
          if (date.date == empDate.date && empDate.status =='A') {
              this.absentList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
          if (date.date == empDate.date && empDate.status =='L') {
              this.leaveList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
          if (date.ot_date == empDate.ot_date && empDate.ot_status=='OT') {
              this.otList.push(empDate);  // Push empDate only if it matches, has status 'p', and isn't already present
          }
        });
        // console.log(this.presentList);
      });
      this.normalDates.forEach((headers:any)=>
      {
        if(headers.status=="Day")
        {
          // presentList
          if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date))
            {
              // console.log("Day",date.date);
              if(headers.status=="Day"&&headers.date==date.date)
              {
              var count = {
                date: date.date,
                countData: this.presentList.length ? this.presentList.length : '0',
                // status:date.status
              };
              this.totalpresentList.push(count);
            }
            }
            // Absent List
            if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date)) {
              if(headers.status=="Day"&&headers.date==date.date)
                {
                  var count = {
                    date: date.date,
                    countData: this.absentList.length ? this.absentList.length : '0'
                  };
                  
                  this.empabsentList.push(count);
                }
            }
            // LeaveList
            if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date))
              {
                if(headers.status=="Day"&&headers.date==date.date)
                  {
                    var count = {
                      date: date.date,
                      countData: this.leaveList.length ? this.leaveList.length : '0'
                    };
                    this.empleaveList.push(count);
                  }  
              }
              // OT List
              if (this.otList.length >= 0 && !this.empotList.some(item => item.otdate === date.ot_date)) {
                if(headers.status=="Day"&&headers.date==date.ot_date)
                {
                  var otcount = {
                    date: date.ot_date,
                    otcountData:'0'
                    // otcountData: this.otList.length
                  };
                  const isDuplicate = this.empotList.some(item => item.date === date.ot_date);
                  if (!isDuplicate) {
                    this.empotList.push(otcount);
                  }
                }
                
              }
        }
        if(headers.status=="OT")
        {
          // Present Ot
            if(this.presentList.length>=0 && !this.totalpresentList.some(item => item.date === date.date&&item.status==="OT"))
              {
                // console.log("Day",date.date);
                if(headers.status=="OT"&&headers.date==date.date)
                {
                var count1 = {
                  date: date.date,
                  countData:'0',
                  status:'OT'
                };
                this.totalpresentList.push(count1);
                }
              }
          // Absent Ot
          if (this.absentList.length >= 0 && !this.empabsentList.some(item => item.date === date.date&&item.status==="OT")) {
            if(headers.status=="OT"&&headers.date==date.date)
              {
                var count1 = {
                  date: date.date,
                  countData:'0',
                  status:'OT'
                };
                this.empabsentList.push(count1);
              }
          }
          // Leave Ot
          if(this.leaveList.length >=0 && !this.empleaveList.some(item => item.date === date.date&&item.status==="OT"))
            {
              if(headers.status=="OT"&&headers.date==date.date)
                {
                  var count1 = {
                    date: date.date,
                    countData:'0',
                    status:'OT'
                  };
                  this.empleaveList.push(count1);
                }
            }
            //Ot List
            if (this.otList.length >= 0 && !this.empotList.some(item => item.otdate === date.ot_date&&item.status==="OT")) {
              if(headers.status=="OT"&&headers.date==date.ot_date)
              {
                var otcount1 = {
                  // date: date.ot_date,
                  // countData:'0',
                  date: date.ot_date,
                  otcountData: this.otList.length,
                  status:'OT'
                };
                // console.log("otcount1",otcount1)
                const isDuplicate = this.empotList.some(item => item.date === date.ot_date&&item.status==="OT");
                if (!isDuplicate) {
                  this.empotList.push(otcount1);
                }
              }
            } 
        }
      })
      console.log("PList",this.totalpresentList);
      console.log("AList",this.empabsentList);
      console.log("LList",this.empleaveList);
      console.log("OTList",this.empotList);
    });
  })
    }
  },
   (error) => {
    // this.loader= false;
    console.log("error");
    if(error.statusCode == 204){
      this.nodata = true;
      console.log("404")
    }
  }
);
  
}
    console.log('currentMonthDates', this.currentMonthDates);
  }

  countStatusP(): number {
    let count = 0;
    this.tableDatas.forEach((data) => {
      if (data.status === 'P') {
        count++;
      }
    });
    return count;
  }
  countPresentStatus(status:any): number {
    this.presentCount = 0; // Reset presentCount before calculation
  console.log('status',status);
  console.log('statuslength',status.status.length);

  if (status == 'P') {
    this.presentCount++
  }
  
    return this.presentCount; // Return the total count
  }
  
  


  otStatusCheck(otStatus: any) {
    console.log('otStatus', otStatus);

    if (otStatus.ot_status) {
      return otStatus.ot_status;
    } else {
      return '-';
    }

  }

  getStatusCount(attendance: any[], status: string): number {
    return attendance.filter(d => d.status === status).length;
  }

  calculateOTStatusACount(): number {
    let count = 0;
    console.log('count', count);
    return count;
  }

  generateEmployeeData() {
    this.employeeData = []; // Initialize the array
  }
  // Helper functions to generate random data for the table
  randomTime() {
    const hours = Math.floor(Math.random() * 3) + 8; // Between 08:00 and 10:00 AM
    const minutes = Math.floor(Math.random() * 60);
    return `${this.pad(hours)}:${this.pad(minutes)} AM`;
  }

  randomStatus() {
    const statuses = ['Present', 'Absent', 'On Leave'];
    return statuses[Math.floor(Math.random() * statuses.length)];
  }

  randomHours() {
    const hours = Math.floor(Math.random() * 8) + 1;
    return `${hours} hrs`;
  }

  randomRemarks() {
    const remarks = ['On Time', 'Late Arrival', 'Sick Leave', 'Personal Leave'];
    return remarks[Math.floor(Math.random() * remarks.length)];
  }

  pad(value: number) {
    return value < 10 ? '0' + value : value;
  }

  trackUser(element: any) {
    const dialogRef = this.dialog.open(LiveTrackComponent, {
      width: '750px',
      data: element,
      disableClose: true
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(event: any) {
    // console.log("index",event.pageIndex);
    // console.log("size",event.pageSize);
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }


  convertToDate(dateString: string): Date {
    const dateParts = dateString.split('-');
    return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  }
}
