import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssignClientserviceService {
  locationList: any=[];
  aliasnameStatus:boolean=false;

  constructor() { }

  setLocation(locationDetailsObjects:any)
  {
    this.locationList.push(locationDetailsObjects);
  }
  clear()
  {
    this.locationList=[];
  }

  getLocation()
  {
    return this.locationList;
  }
 
  setStatus(status:any)
  {
    console.log("Set aliasnameStatus",status);
    this.aliasnameStatus=status;
  }
  getStatus()
  {
    console.log("Get aliasnameStatus");
     return this.aliasnameStatus;
  }
}
