import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './Main-Layout/layout/layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './Authenticaton/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserListComponent } from './Components/user/user-list/user-list.component';
import { CreateUserComponent } from './Components/user/create-user/create-user.component';
import { LoadingComponent } from './Components/loading/loading.component';
import { AssignLocationComponent } from './Components/Location/assign-location/assign-location.component';
import { TimePickerComponent } from './Components/time-picker/time-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TestFormComponent } from './Components/test-form/test-form.component';
import { MaterialModule } from './materialstyle/materialstyle.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ViewTrackReportComponent } from './Components/view-track-report/view-track-report.component';
import { LiveTrackComponent } from './Components/live-track/live-track.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import {AgmDrawingModule} from '@agm/drawing'
import { EditUserComponent } from './Components/user/edit-user/edit-user.component';
import { ViewUserComponent } from './Components/Location/view-user/view-user.component';
import { TrackUserListComponent } from './Components/track-user-list/track-user-list.component';
import { ViewTrackUserListComponent } from './Components/view-track-user-list/view-track-user-list.component';
import { SignUpComponent } from './Authenticaton/sign-up/sign-up.component';
import { RegisterUserComponent } from './Authenticaton/register-user/register-user.component';
import { VerifyOtpComponent } from './Authenticaton/verify-otp/verify-otp.component';
import { VerifyEmailComponent } from './Authenticaton/verify-email/verify-email.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CountdownModule } from 'ngx-countdown';
import { OrganziationRegisterComponent } from './Components/Organization/organziation-register/organziation-register.component';
import { OrganziationListComponent } from './Components/Organization/organziation-list/organziation-list.component';
import { EditOrganziationComponent } from './Components/Organization/edit-organziation/edit-organziation.component';
import { ForgetPasswordComponent } from './Authenticaton/forget-password/forget-password.component';
import { UserRegisterComponent } from './Components/Organization/user-register/user-register.component';
import { UploaduserComponent } from './Components/user/uploaduser/uploaduser.component';
import { LiveTrackUserListComponent } from './Components/live-track-user-list/live-track-user-list.component';
import {ManageUserInduvarComponent}  from './Components/Organization/manage-user-induvar/manage-user-induvar.component';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { MessagingService } from './service/messaging.service';
import { LocationComponent } from './Components/Location/location/location.component';
import { AddLocationComponent } from './Components/Location/add-location/add-location.component';
import { UpdateLocationComponent } from './Components/Location/update-location/update-location.component';
import {ManageFreeTrialComponent} from './Components/manage-free-trial/manage-free-trial.component';
import {FreeCreateComponent} from './Components/free-create/free-create.component';
import {TrackComponent} from './Components/track/track.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddressComponent } from './Components/address/address.component';
import  {SettingviewComponent } from './Components/settingview/settingview.component';
import { ToastrModule } from 'ngx-toastr';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { HammerModule } from '@angular/platform-browser';
import {MatDialogModule} from "@angular/material/dialog";
import { UserLocationComponent } from './Components/user-location/user-location.component';
import { FamilyCurrentlocationComponent } from './Components/family-currentlocation/family-currentlocation.component';
import { GeofenceComponent } from './Components/geofence/geofence.component';
import {ManageGeofenceComponent } from './Components/manage-geofence/manage-geofence.component'
import { AgmgeofenaceComponent } from './agmgeofenace/agmgeofenace.component';
import { AssignGeofenceComponent } from './Components/assign-geofence/assign-geofence.component';
import { CreateGeofenceComponent } from './Components/create-geofence/create-geofence.component';
import { LogInoutReportsComponent } from './Components/log-inout-reports/log-inout-reports.component';
import { PreviousRecordReportComponent } from './previous-record-report/previous-record-report.component';
import { OverallLoginLogoutReportComponent } from './overall-login-logout-report/overall-login-logout-report.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AttendanceReportsComponent } from './attendance-reports/attendance-reports.component';
import { ShiftComponent } from './shift/shift.component';
import { ExcelService } from './excel.service';
import { ClientLocationAccessComponent } from './client-location-access/client-location-access.component';
import { ClientLocationComponent } from './client-location/client-location.component';
import { ClientassignComponent } from './clientassign/clientassign.component';
import { CreateclientassignComponent } from './createclientassign/createclientassign.component';
import { OtAssignComponent } from './ot-assign/ot-assign.component';
import { OtAssignReportComponent } from './ot-assign-report/ot-assign-report.component';
import { AssignClientLocationComponent } from './assign-client-location/assign-client-location.component';
import { ViewLocationComponent } from './view-location/view-location.component';
import { CreateshiftComponent } from './createshift/createshift.component';
import { AddressDialogComponent } from './address-dialog/address-dialog.component';
import { ViewlocatioaddressComponent } from './viewlocatioaddress/viewlocatioaddress.component';
import { ViewemployeeComponent } from './viewemployee/viewemployee.component';
import { AliasDialogComponent } from './alias-dialog/alias-dialog.component';
import { ViewAliasComponent } from './view-alias/view-alias.component';
import { SuperAdminHomeComponent } from './super-admin-home/super-admin-home.component';
import { TrackUserReportComponent } from './track-user-report/track-user-report.component';
import { UserviewComponent } from './Components/user/userview/userview.component';
// import {MatTimepickerModule} from '@angular/material/timepicker';
import { MyProfileComponent } from './Components/my-profile/my-profile.component';
import { AssigngeofenceAttendenceComponent } from './assigngeofence-attendence/assigngeofence-attendence.component';
// import {AssignGeofenceComponent } from './Components/assign-geofence/assign-geofence.component'


// import { 
// 	IgxTimePickerModule,
// 	IgxInputGroupModule,
// 	IgxIconModule
//  } from "igniteui-angular";

@NgModule({

  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    SignUpComponent,
    UserListComponent,
    CreateUserComponent,
    LoadingComponent,
    AssignLocationComponent,
    TimePickerComponent,
    TestFormComponent,
    ViewTrackReportComponent,
    LiveTrackComponent,
    RegisterUserComponent,
    NotificationComponent,
    VerifyOtpComponent,
    EditUserComponent,
    ViewUserComponent,
    TrackUserListComponent,
    ViewTrackUserListComponent,
    VerifyEmailComponent,
    OrganziationRegisterComponent,
    OrganziationListComponent,
    EditOrganziationComponent,
    ForgetPasswordComponent,
    UserRegisterComponent,
    UploaduserComponent,
    LiveTrackUserListComponent,
    ManageUserInduvarComponent,
    LocationComponent,
    AddLocationComponent,
    UpdateLocationComponent,
    ManageFreeTrialComponent,
    TrackComponent,
    FreeCreateComponent,
    AddressComponent , 
    SettingviewComponent, 
    UserLocationComponent,
    FamilyCurrentlocationComponent,
    ManageGeofenceComponent, 
    GeofenceComponent, 
    UserLocationComponent, FamilyCurrentlocationComponent, AgmgeofenaceComponent, AssignGeofenceComponent, CreateGeofenceComponent, LogInoutReportsComponent, PreviousRecordReportComponent, OverallLoginLogoutReportComponent, AttendanceReportsComponent,
    ShiftComponent,
    ClientLocationAccessComponent,
    ClientLocationComponent ,
    ClientassignComponent,
    CreateclientassignComponent,
    AssignClientLocationComponent,
    ViewLocationComponent ,
    OtAssignComponent,
    OtAssignReportComponent,
    AssignClientLocationComponent,
    CreateshiftComponent,
    AddressDialogComponent,
    ViewlocatioaddressComponent,
    ViewemployeeComponent,
    AliasDialogComponent,
    ViewAliasComponent,
    SuperAdminHomeComponent,
    TrackUserReportComponent,
    UserviewComponent,
    MyProfileComponent,
    AssigngeofenceAttendenceComponent,
     
  ],
  imports: [
    MatInputModule,
    MatMenuModule,
    BrowserModule,
    MatDialogModule,
	  BrowserAnimationsModule,
	  FormsModule,
	  // IgxTimePickerModule,
	  // IgxInputGroupModule,
	  // IgxIconModule,
    MatFormFieldModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    HammerModule,
    MatListModule,
    MatCheckboxModule,
    // MatTimepickerModule,
    // FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaterialTimepickerModule,
    TextMaskModule,
    HttpClientModule,
    AgmDrawingModule,
    MatPaginatorModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      libraries: ['places,drawing'],
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
       //apiKey: 'AIzaSyCOrz8KelDyhR77xWT6Ifrq9gaRLqC7wzU'
     //  apiKey : 'AIzaSyCiVF3o-IbBTQzyPPIuV74s1U-t0LU4OdQ' 
       //apiKey : 'AIzaSyBi3xLRip62FBMJftDid-OuUpVfsxCJEz4'
       apiKey : 'AIzaSyC6e1oG9ODcguxJcHKl0OYeR-D4K-MIrs8',
       
    }),
    AgmDrawingModule,
    NgxMaskModule.forRoot(),
    CountdownModule,
      /* firebase */
      AngularFireMessagingModule,
      AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireModule.initializeApp(environment.firebase),
      ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      })
  ],
  providers: [MessagingService,  
      ExcelService,
    AsyncPipe,
     DatePipe , { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  exports: 
  [
    AssignLocationComponent,
    MatInputModule,
    MatFormFieldModule
  ],
  
})
export class AppModule { }
