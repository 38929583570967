import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  data: any;
  profileImage: any;

  constructor(public gs :GlobalserviceService,
     private auth: AuthService) { }

  ngOnInit(): void {
    let userdata = this.auth.getLocalStorageData();
    console.log(userdata);

    this.gs.get_userId(userdata.userId).subscribe(emp => {
      console.log(emp);
      this.data=emp;
      console.log(this.data);
      this.profileImage = this.data.attachment.fileViewUrl
      console.log("this.profileImage",this.profileImage);
    })
  }
  back(){
    // this.matdialogRef.close();
  }

}
