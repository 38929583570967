import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './Authenticaton/forget-password/forget-password.component';
import { GuardGuard } from './Authenticaton/guard.guard';
import { LoginComponent } from './Authenticaton/login/login.component';
import { RegisterUserComponent } from './Authenticaton/register-user/register-user.component';
import { SignUpComponent } from './Authenticaton/sign-up/sign-up.component';
import { VerifyEmailComponent } from './Authenticaton/verify-email/verify-email.component';
import { VerifyOtpComponent } from './Authenticaton/verify-otp/verify-otp.component';
import { LiveTrackUserListComponent } from './Components/live-track-user-list/live-track-user-list.component';
import { AssignLocationComponent } from './Components/Location/assign-location/assign-location.component';
import { ManageUserInduvarComponent } from './Components/Organization/manage-user-induvar/manage-user-induvar.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { EditOrganziationComponent } from './Components/Organization/edit-organziation/edit-organziation.component';
import { OrganziationListComponent } from './Components/Organization/organziation-list/organziation-list.component';
import { OrganziationRegisterComponent } from './Components/Organization/organziation-register/organziation-register.component';
import { UserRegisterComponent } from './Components/Organization/user-register/user-register.component';
import { TestFormComponent } from './Components/test-form/test-form.component';
import { TrackUserListComponent } from './Components/track-user-list/track-user-list.component';
import { UserListComponent } from './Components/user/user-list/user-list.component';
import { ViewTrackReportComponent } from './Components/view-track-report/view-track-report.component';
import { LayoutComponent } from './Main-Layout/layout/layout.component';
import { LocationComponent } from './Components/Location/location/location.component';
import { AddLocationComponent } from './Components/Location/add-location/add-location.component';
import { UpdateLocationComponent } from './Components/Location/update-location/update-location.component';
import { ManageFreeTrialComponent } from './Components/manage-free-trial/manage-free-trial.component';
import { TrackComponent } from './Components/track/track.component';
import { SettingviewComponent } from './Components/settingview/settingview.component';
import { ManageGeofenceComponent} from './Components/manage-geofence/manage-geofence.component';
import { GeofenceComponent} from './Components/geofence/geofence.component';
import { AgmgeofenaceComponent } from './agmgeofenace/agmgeofenace.component';
import { AssignGeofenceComponent } from './Components/assign-geofence/assign-geofence.component';
import {CreateGeofenceComponent } from './Components/create-geofence/create-geofence.component'
import { LogInoutReportsComponent } from './Components/log-inout-reports/log-inout-reports.component';
import { PreviousRecordReportComponent } from './previous-record-report/previous-record-report.component';
import { OverallLoginLogoutReportComponent } from './overall-login-logout-report/overall-login-logout-report.component';
import { AttendanceReportsComponent } from './attendance-reports/attendance-reports.component';
import { ShiftComponent } from './shift/shift.component';
import { ClientLocationAccessComponent } from './client-location-access/client-location-access.component';
import { ClientLocationComponent } from './client-location/client-location.component';
import { ClientassignComponent } from './clientassign/clientassign.component';
import { CreateclientassignComponent } from './createclientassign/createclientassign.component';
import { OtAssignReportComponent } from './ot-assign-report/ot-assign-report.component';
import { OtAssignComponent } from './ot-assign/ot-assign.component';
import { AssignClientLocationComponent } from './assign-client-location/assign-client-location.component';
import { CreateshiftComponent } from './createshift/createshift.component';
import { SuperAdminHomeComponent } from './super-admin-home/super-admin-home.component';
import { TrackUserReportComponent } from './track-user-report/track-user-report.component';
import { MyProfileComponent } from './Components/my-profile/my-profile.component';
import { AssigngeofenceAttendenceComponent } from './assigngeofence-attendence/assigngeofence-attendence.component';
const routes: Routes = [
  {
    
    path: '', component: LayoutComponent, canActivate: [GuardGuard], children: [
      { path: '', redirectTo: 'user', pathMatch: 'full' },
      { path: 'user', component: UserListComponent },
      {path:'create-shift',component:CreateshiftComponent},
      { path: 'shift', component: ShiftComponent },
      {path:'client-assign',component:ClientassignComponent},
      {path:'create-client-assign',component:CreateclientassignComponent},
      { path: 'location', component: AssignLocationComponent },
      { path: "form", component: TestFormComponent },
      { path: "track-report/:id", component: ViewTrackReportComponent },
      {path: 'notification', component: NotificationComponent},
      {path: 'track-list', component: TrackUserListComponent},
      {path: 'track-user', component: TrackUserReportComponent},
      // {path: 'org-list', component: OrganziationListComponent},
      // {path: 'add-org', component: OrganziationRegisterComponent},
      // {path: 'edit-org', component: EditOrganziationComponent},
      {path: 'add-user', component: UserRegisterComponent},
      { path: 'user-induvar',component:ManageUserInduvarComponent},
      {path: 'live-track-list', component: LiveTrackUserListComponent},
      {path: 'location-list', component: LocationComponent},
      {path: 'add-location', component: AddLocationComponent},
      {path : 'edit-location/:id' , component : UpdateLocationComponent},
      {path:'dont-track', component:TrackComponent},
      {path:'free-trial',component:ManageFreeTrialComponent} ,
      {path : 'dont-track-detail/:id' , component : SettingviewComponent},
      {path : 'manage-geofence' , component : ManageGeofenceComponent},
      {path : 'geofence' , component : GeofenceComponent},
      {path : 'geofence/:geofenaceId' , component : GeofenceComponent},
      {path : 'assign-geofence' , component : AssignGeofenceComponent},
      {path : 'agmgeofence' , component : AgmgeofenaceComponent},
      {path:'assign',component:CreateGeofenceComponent},
      {path:'loginoutReports',component:LogInoutReportsComponent},
      {path:'previous-records',component:PreviousRecordReportComponent},
      {path:'overall-login-report',component:OverallLoginLogoutReportComponent},
      {path:'geoattendance-report',component:AssigngeofenceAttendenceComponent},
      {path:'attendance-report',component:AttendanceReportsComponent},
      {path:'client-location',component:ClientLocationAccessComponent},
      // {path:'client-loc-access',component:ClientLocationComponent},
      { path: 'client-loc-access/:id', component: ClientLocationComponent },
      {path:'client-loc-access',component:ClientLocationComponent},
      {path:'OT-assign-list',component:OtAssignReportComponent},
      {path:'OT-assign',component:OtAssignComponent},
      {path:'assign-client',component:AssignClientLocationComponent},
      { path:'profile', component:MyProfileComponent}


    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'register', component: RegisterUserComponent },
  { path: 'verify-otp/:id', component: VerifyOtpComponent },
  { path: 'verify-email/:id', component: VerifyEmailComponent },
  {path: 'forget-password', component: ForgetPasswordComponent},
  {path: 'org-list', component: OrganziationListComponent},
  {path: 'add-org', component: OrganziationRegisterComponent},
  {path: 'edit-org/:id', component: EditOrganziationComponent},
  {
    path: 'super-admin-home',
    component: SuperAdminHomeComponent,
    children: [
      {path: '', redirectTo: 'org-list', pathMatch: 'full' },
      {path: 'org-list', component: OrganziationListComponent},
      {path: 'add-org', component: OrganziationRegisterComponent},
      {path: 'edit-org/:id', component: EditOrganziationComponent},
      {path: 'free-trial',component:ManageFreeTrialComponent} ,
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
